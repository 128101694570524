import React, { HTMLAttributes, useCallback, FC } from "react";
import { LocationDescriptor } from "history";
import { useFormContext } from "react-hook-form";

export type FromToRangeUIProps = {
  testLabel?: string;
  registerFrom: `${string}` | `${string}.${string}` | `${string}.${number}`;
  registerTo: `${string}` | `${string}.${string}` | `${string}.${number}`;
  rangeSize?: "xs" | "sm" | "md" | "lg";
  color?:
    | "primary"
    | "secondary"
    | "info"
    | "accent"
    | "success"
    | "warning"
    | "error"
    | "default";
  title?: string;
  max: number;
  min: number;
  step: number;
  unitName?: string;
  to?: LocationDescriptor;
} & HTMLAttributes<HTMLDivElement>;

export const FromToRangeUI: FC<FromToRangeUIProps> = ({
  title = "",
  testLabel = "",
  rangeSize = "md",
  color = "default",
  max,
  min,
  step,
  registerFrom,
  registerTo,
  unitName = "",
  children,
  className,
  ...rest
}) => {
  const { register, watch } = useFormContext();
  const getSize = useCallback(
    () =>
      ({
        xs: "range-xs",
        sm: "range-sm",
        md: "range-md",
        lg: "range-lg",
      }[rangeSize]),
    [rangeSize]
  );

  const [watchedRegisterFrom, watchedRegisterTo] = watch([
    registerFrom,
    registerTo,
  ]);

  const getColor = useCallback(
    () =>
      ({
        primary: "range-primary",
        secondary: "range-secondary",
        info: "range-info",
        accent: "range-accent",
        success: "range-success",
        warning: "range-warning",
        error: "range-error",
        default: "",
      }[color]),
    [color]
  );
  return (
    <div className={`${className} w-full`} {...rest}>
      <input
        data-testid={`${testLabel}-from`}
        type="range"
        min={min}
        max={max}
        step={step}
        className={`range ${getSize()} ${getColor()}`}
        {...register(registerFrom)}
      />
      <label className="label mb-2">
        <span className="label-text-alt">
          <span className="text-xl font-bold">{watchedRegisterFrom}</span>
          {unitName}
        </span>
        <span className="label-text-alt">〜</span>
        <span className="label-text-alt">
          <span className="text-xl font-bold">{watchedRegisterTo}</span>
          {unitName}
        </span>
      </label>
      <input
        data-testid={`${testLabel}-to`}
        type="range"
        min={min}
        max={max}
        step={step}
        className={`range ${getSize()} ${getColor()}`}
        {...register(registerTo)}
      />
    </div>
  );
};
