import { Auth } from "aws-amplify";
import React, { createContext, useCallback, useEffect, useState } from "react";
import {
  CurrentUserDocument,
  CurrentUserFragment,
  IsUserLoggedInDocument,
  useCurrentUserQuery,
} from "../../generated/graphql";
import { client } from "../../lib/apollo-client";
import { SignInInput } from "../../routes/SignInPage";
import { SignUpStatus, useSignUpStatus } from "./useSignUpStatus";

export type SignInHandler = (input: SignInInput) => Promise<void>;
export type SignOutHandler = () => Promise<void>;
export type ClearSignUpStatusHandler = () => void;
export type SignUpHandler = (input: SignUpInput) => Promise<void>;
export type ConfirmSignUpHandler = (input: ConfirmSignUpInput) => Promise<void>;
export type ResendSignUpHandler = () => Promise<void>;

interface IAuthContext {
  currentUser: CurrentUserFragment | null;
  signUpStatus: SignUpStatus;
  signIn: SignInHandler;
  signOut: SignOutHandler;
  signUp: SignUpHandler;
  confirmSignUp: ConfirmSignUpHandler;
  resendSignUp: ResendSignUpHandler;
}

export type SignUpInput = {
  familyName: string;
  givenName: string;
  familyNameKana: string;
  givenNameKana: string;
  email: string;
  password: string;
};

export type ConfirmSignUpInput = {
  email: string;
  authCode: number;
};

type AuthError = {
  code: string;
  message: string;
};

const AuthErrors: AuthError[] = [
  {
    code: "UsernameExistsException",
    message: "入力されたメールアドレスは既に登録されています",
  },
  {
    code: "InvalidPasswordException",
    message: "パスワードが正しくありません",
  },
  {
    code: "InvalidParameterException",
    message: "有効でないパラメータが入力されています",
  },
  { code: "CodeMismatchException", message: "認証コードが正しくありません。" },
  {
    code: "CodeDeliveryFailureException",
    message: "認証コードの送信に失敗しました。",
  },
  {
    code: "UserLambdaValidationException",
    message:
      "システムエラーが発生しました。恐れいりますが管理者にお問いあわせください。",
  },
  {
    code: "UserNotFoundException",
    message: "メールアドレスまたはパスワードが正しくありません。",
  },
  {
    code: "NotAuthorizedException",
    message: "メールアドレスまたはパスワードが正しくありません。",
  },
  {
    code: "InvalidParameterException",
    message: "メールアドレスまたはパスワードが正しくありません。",
  },
];

const AuthContext = createContext<IAuthContext>({
  currentUser: null,
  signUpStatus: { email: "", confirming: false },
  signIn: async (_: SignInInput) => {
    // nothing to do
  },
  signUp: async (_: SignUpInput) => {
    // nothing to do
  },
  confirmSignUp: async (_: ConfirmSignUpInput) => {
    // nothing to do
  },
  resendSignUp: async () => {
    // nothing to do
  },
  signOut: async () => {
    // nothing to do
  },
});

const getAuthErrorMessage = (code: string) => {
  const error = AuthErrors.find((error: AuthError) => error.code === code);
  return error ? error.message : "Unknown Error";
};

// TODO: 直す
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AuthProvider = (props: any) => {
  const [signUpStatus, setSignUpStatus, clearSignUpStatus] = useSignUpStatus();
  const [currentUser, setCurrentUser] = useState<CurrentUserFragment | null>(
    null
  );

  const { data } = useCurrentUserQuery();
  // const [getCurrentUser, { data }] = useCurrentUserLazyQuery();

  useEffect(() => {
    if (data?.me) {
      setCurrentUser(data.me);
      client.writeQuery({
        query: CurrentUserDocument,
        data: {
          me: data.me,
          isLoggedIn: true,
        },
      });
    }
  }, [data]);

  const onSignIn = useCallback(async (input: SignInInput) => {
    try {
      const user = await Auth.signIn(input.email, input.password);
      client.writeQuery({
        query: IsUserLoggedInDocument,
        data: {
          isLoggedIn: !!user,
        },
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      throw new Error(getAuthErrorMessage(error.code));
    }
  }, []);

  const onSignOut = useCallback(async () => {
    await Auth.signOut();
  }, []);

  const onResendSignUp = useCallback(async () => {
    await Auth.resendSignUp(signUpStatus.email);
  }, [signUpStatus]);

  const onSignUp = useCallback(
    async (input: SignUpInput) => {
      try {
        await Auth.signUp({
          username: input.email,
          password: input.password,
          attributes: {
            family_name: input.familyName,
            given_name: input.givenName,
            "custom:family_name_kana": input.familyNameKana,
            "custom:given_name_kana": input.givenNameKana,
          },
        });
        setSignUpStatus({ email: input.email, confirming: true });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.log(error.code);
        throw new Error(getAuthErrorMessage(error.code));
      }
    },
    [setSignUpStatus]
  );

  const onConfirmSignUp = useCallback(
    async (input: ConfirmSignUpInput) => {
      try {
        await Auth.confirmSignUp(input.email, String(input.authCode));
        clearSignUpStatus();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        throw new Error(getAuthErrorMessage(error.code));
      }
    },
    [clearSignUpStatus]
  );

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        signUpStatus,
        signIn: onSignIn,
        signOut: onSignOut,
        signUp: onSignUp,
        confirmSignUp: onConfirmSignUp,
        resendSignUp: onResendSignUp,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
