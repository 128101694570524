import dayjs from "dayjs";
import React, { useCallback, useState } from "react";
import { DeliveryHistoriesItemFragment } from "../../../../generated/graphql";
import { DeliveryHistoriesModal } from "../DeliveryHistoriesModal";
import { DeliveryHistoriesItemUI } from "./DeliveryHistoriesItem";

export type DeliveryHistoriesTableUIProps = {
  deliveryHistories: DeliveryHistoriesItemFragment[];
  onResend: (history: DeliveryHistoriesItemFragment) => void;
};

export const DeliveryHistoriesTableUI: React.VFC<
  DeliveryHistoriesTableUIProps
> = ({ deliveryHistories, onResend }) => {
  const [selectedHistory, setSelectedHistory] =
    useState<DeliveryHistoriesItemFragment | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleSelectHistory = useCallback(
    (history: DeliveryHistoriesItemFragment) => {
      setSelectedHistory(history);
      setIsModalVisible(true);
    },
    []
  );

  const closeModal = useCallback(() => {
    setIsModalVisible(false);
    setSelectedHistory(null);
  }, []);

  const sortedHistories = [...deliveryHistories].sort(
    (a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf()
  );

  return (
    <div className="overflow-x-auto">
      <table className="divide-y divide-gray-200 text-xs w-full">
        <thead className="bg-primary">
          <tr className="text-left font-medium text-white uppercase tracking-wider">
            <th className="px-3 py-2" style={{ width: "12.5%" }}>
              人材Hash
            </th>
            <th className="px-3 py-2" style={{ width: "18.75%" }}>
              件名
            </th>
            <th className="px-3 py-2" style={{ width: "12.5%" }}>
              成功／失敗（成功数／配信数）
            </th>
            <th className="px-3 py-2" style={{ width: "6.67%" }}>
              配信種別
            </th>
            <th className="px-3 py-2" style={{ width: "6.67%" }}>
              配信者
            </th>
            <th className="px-3 py-2" style={{ width: "12.92%" }}>
              配信日時
            </th>
            <th className="px-3 py-2" style={{ width: "6.67%" }}>
              再配信
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {sortedHistories.map(deliveryHistory => (
            <DeliveryHistoriesItemUI
              key={deliveryHistory.id}
              deliveryHistory={deliveryHistory}
              onResend={onResend}
              onSelectHistory={() => handleSelectHistory(deliveryHistory)}
            />
          ))}
        </tbody>
      </table>

      {selectedHistory && (
        <DeliveryHistoriesModal
          visibility={isModalVisible}
          selectedHistory={selectedHistory}
          onResend={onResend}
          onClosed={closeModal}
        />
      )}
    </div>
  );
};
