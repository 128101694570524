import type { FC } from "react";
import React, { useEffect, useState } from "react";
import {
  DeliverySchedulesFragment,
  useDeliverySchedulesPageQuery,
} from "../../../generated/graphql";
import DeliverySchedulesPage from "./DeliverySchedulesPage";

const DeliverySchedulesPageContainer: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { error, data } = useDeliverySchedulesPageQuery({
    onCompleted: async () => {
      setIsLoading(false);
    },
  });

  const [deliverySchedules, setDeliverySchedules] =
    useState<DeliverySchedulesFragment>({
      totalCount: 0,
      nodes: [],
    });

  useEffect(() => {
    let isMounted = true;
    if (isMounted && data) {
      setDeliverySchedules(data.deliverySchedules);
    }
    return () => {
      isMounted = false;
    };
  }, [data]);

  if (error) {
    return <div>{JSON.stringify(error, null, 2)}</div>;
  }
  return (
    <DeliverySchedulesPage
      isLoading={isLoading}
      deliverySchedules={deliverySchedules}
    />
  );
};

export default DeliverySchedulesPageContainer;
