/**
 * Generates a hash for the given subject.
 *
 * @param {string} subject - The subject string to hash.
 * @return {Promise<string>} - The generated hash string.
 */
export const genMessageTagHash = async (): Promise<string> => {
  const hashReplacements: { [key: string]: string } = { "+": "-", "/": "_" };
  const hashSize = 16;
  const randomString = Math.random().toString(36).substring(2, 15);

  return await generateHash(randomString, hashReplacements, hashSize);
};

/**
 * Generates a SHA-256 hash and formats it.
 *
 * @param {string} input - The input string to hash.
 * @param {Object} replacements - The replacements for specific characters.
 * @param {number} hashSize - The size of the final hash string.
 * @return {Promise<string>} - The formatted hash string.
 */
export const generateHash = async (
  input: string,
  replacements: { [key: string]: string },
  hashSize: number
): Promise<string> => {
  const hashBuffer = await crypto.subtle.digest(
    "SHA-256",
    new TextEncoder().encode(input)
  );
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  let base64Hash = btoa(String.fromCharCode(...hashArray));
  Object.entries(replacements).forEach(([oldChar, newChar]) => {
    base64Hash = base64Hash.split(oldChar).join(newChar);
  });
  base64Hash = base64Hash.replace(/=+$/, "");
  if (base64Hash.length > hashSize) {
    base64Hash = base64Hash.substring(0, hashSize);
  } else {
    while (base64Hash.length < hashSize) {
      base64Hash += "0";
    }
  }
  return base64Hash;
};
