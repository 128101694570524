import React, { useCallback, FC } from "react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  SortDirection,
} from "@tanstack/react-table";

type Book = {
  title: string;
  author: string;
};

const books: Book[] = [
  {
    title: "ハリー・ポッターと賢者の石",
    author: "J.K.ローリング",
  },
  {
    title: "こころ",
    author: "夏目漱石",
  },
  {
    title: "ダレンシャン",
    author: "ダレンシャン",
  },
];

const columns: ColumnDef<Book, any>[] = [
  {
    accessorKey: "title",
    header: "タイトル",
  },
  {
    accessorKey: "author",
    header: "著者",
  },
];

export const BasicTable: FC = () => {
  const table = useReactTable<Book>({
    data: books,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  const getSortLabel = useCallback((status: false | SortDirection): string => {
    if (status === false) {
      return "";
    }
    if (status === "asc") {
      return " ▲";
    }
    return " ▼";
  }, []);
  return (
    <div>
      <table className="table table-zebra table-compact w-full">
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id}>
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        className: header.column.getCanSort()
                          ? "cursor-pointer select-none"
                          : "",
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {getSortLabel(header.column.getIsSorted())}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
