import React, { MouseEventHandler, useEffect } from "react";
import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { PageTitle } from "../../../components/PageTitle";
import { DeliveryGroupEditUI } from "../../../components/DeliveryGroupEdit";
import { OptionItem } from "../../../types/common";
import { CreateDeliveryGroupModify } from ".";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "ホーム",
  },
  {
    label: "配信グループ",
  },
  {
    label: "配信グループ作成",
  },
];

type DeliveryGroupNewPageProps = {
  isLoading: boolean;
  createDeliveryGroupModify: CreateDeliveryGroupModify;
  selectItemUsers: OptionItem[];
  selectItemEmployees: OptionItem[];
  onCreateDeliveryGroup: (
    deliveryGroupModify: CreateDeliveryGroupModify
  ) => void;
  onAddedToEmployee?: MouseEventHandler;
};

const DeliveryGroupNewPage: React.VFC<DeliveryGroupNewPageProps> = ({
  isLoading = false,
  createDeliveryGroupModify,
  selectItemUsers,
  selectItemEmployees,
  onCreateDeliveryGroup,
  onAddedToEmployee,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  return (
    <div className="container mx-auto px-4">
      <PageTitle>配信グループ作成</PageTitle>
      <DeliveryGroupEditUI
        isNew={true}
        isLoading={isLoading}
        selectItemUsers={selectItemUsers}
        selectItemEmployees={selectItemEmployees}
        onCreate={onCreateDeliveryGroup}
        onAddedToEmployee={onAddedToEmployee}
      />
    </div>
  );
};

export default DeliveryGroupNewPage;
