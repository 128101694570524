import { HubCapsule } from "@aws-amplify/core";
import { Hub } from "aws-amplify";
import React, { FC, useEffect } from "react";
import {
  Redirect,
  Route,
  RouteProps,
  BrowserRouter as Router,
  Switch,
  useHistory,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./components/Layout/Layout";
import NoHeadLayout from "./components/NoHeadLayout/NoHeadLayout";
import { useIsUserLoggedInQuery } from "./generated/graphql";
import AboutPage from "./routes/AboutPage";
import HomePage from "./routes/HomePage";
import MatterPage from "./routes/MatterPage/MatterPage";
import ProfilePage from "./routes/ProfilePage";
import RedirectPage from "./routes/RedirectPage";
import SignInPage from "./routes/SignInPage";
import SignUpPage from "./routes/SignUpPage";
import CompaniesPage from "./routes/companies/CompaniesPage";
import CompanyNewConfirmPage from "./routes/companies/CompanyNewConfirmPage";
import CompanyNewPage from "./routes/companies/CompanyNewPage";
import DeliveryGroupNewConfirmPage from "./routes/deliveryGroups/DeliveryGroupNewConfirmPage";
import DeliveryGroupNewPage from "./routes/deliveryGroups/DeliveryGroupNewPage";
import DeliveryGroupsPage from "./routes/deliveryGroups/DeliveryGroupsPage";
import DeliveryHistoriesPage from "./routes/deliveryHistories/DeliveryHistoriesPage";
import DeliverySchedulesPage from "./routes/deliverySchedules/DeliverySchedulesPage";
import SearchEmailsPage from "./routes/emails/SearchEmailsPage";
import SendBulkEmailsConfirmPage from "./routes/emails/SendBulkEmailsConfirmPage";
import SendBulkEmailsPage from "./routes/emails/SendBulkEmailsPage";
import EmployeeNewConfirmPage from "./routes/employees/EmployeeNewConfirmPage";
import EmployeeNewPage from "./routes/employees/EmployeeNewPage";
import EmployeesPage from "./routes/employees/EmployeesPage";
import SamplesPage from "./routes/samples/SamplesPage";
import BulkRegistrationPage from "./routes/settings/BulkRegistrationPage";

/**
 * @return {Router} ルーター
 */
function App() {
  return (
    <Router>
      <Switch>
        <Route path="/signup">
          <NoHeadLayout>
            <SignUpPage />
          </NoHeadLayout>
        </Route>
        <Route path="/login">
          <NoHeadLayout>
            <SignInPage />
          </NoHeadLayout>
        </Route>
        <AuthRedirect>
          <Layout>
            <Switch>
              <Route path="/redirect">
                <RedirectPage />
              </Route>
              <Route path="/about">
                <AboutPage />
              </Route>
              <PrivateRoute path="/profile">
                <ProfilePage />
              </PrivateRoute>
              <PrivateRoute path="/matter">
                <MatterPage />
              </PrivateRoute>
              <PrivateRoute path="/emails/search">
                <SearchEmailsPage />
              </PrivateRoute>
              <PrivateRoute path="/employees">
                <EmployeesPage />
              </PrivateRoute>
              <PrivateRoute path="/employee/new/confirm">
                <EmployeeNewConfirmPage />
              </PrivateRoute>
              <PrivateRoute path="/employee/new">
                <EmployeeNewPage />
              </PrivateRoute>
              <PrivateRoute path="/companies">
                <CompaniesPage />
              </PrivateRoute>
              <PrivateRoute path="/company/new/confirm">
                <CompanyNewConfirmPage />
              </PrivateRoute>
              <PrivateRoute path="/company/new">
                <CompanyNewPage />
              </PrivateRoute>
              <PrivateRoute path="/groups">
                <DeliveryGroupsPage />
              </PrivateRoute>
              <PrivateRoute path="/group/new/confirm">
                <DeliveryGroupNewConfirmPage />
              </PrivateRoute>
              <PrivateRoute path="/group/new">
                <DeliveryGroupNewPage />
              </PrivateRoute>
              <PrivateRoute path="/emails/schedules">
                <DeliverySchedulesPage />
              </PrivateRoute>
              <PrivateRoute path="/emails/histories">
                <DeliveryHistoriesPage />
              </PrivateRoute>
              <PrivateRoute path="/emails/bulk/confirm">
                <SendBulkEmailsConfirmPage />
              </PrivateRoute>
              <PrivateRoute path="/emails/bulk">
                <SendBulkEmailsPage />
              </PrivateRoute>
              <PrivateRoute path="/settings/bulk">
                <BulkRegistrationPage />
              </PrivateRoute>
              <PrivateRoute path="/samples">
                <SamplesPage />
              </PrivateRoute>
              {/* <PrivateRoute path="/users">
                <UsersPage />
              </PrivateRoute> */}
              <PrivateRoute path="/">
                <HomePage />
              </PrivateRoute>
            </Switch>
          </Layout>
        </AuthRedirect>
      </Switch>
    </Router>
  );
}

const PrivateRoute: FC<RouteProps> = ({ component, ...rest }) => {
  const { data, error, loading } = useIsUserLoggedInQuery();
  if (error) {
    console.error(error);
    return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }
  if (loading || data == null) {
    return <div>loading...</div>;
  }
  if (!data.isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }
  return <Route {...rest} component={component} />;
};

const AuthRedirect: FC<RouteProps> = ({ children }) => {
  const history = useHistory();

  const redirectToPreviousPageAfterLogin = (data: HubCapsule) => {
    const { payload } = data;
    switch (payload.event) {
      case "customOAuthState":
        history.push(decodeURIComponent(payload.data));
        break;
      case "signIn_failure":
        history.push("/");
        break;
    }
  };
  useEffect(() => {
    Hub.listen("auth", redirectToPreviousPageAfterLogin);
    return () => {
      Hub.remove("auth", redirectToPreviousPageAfterLogin);
    };
  }, []); // eslint-disable-line
  return <>{children}</>;
};

export default App;
