import React from "react";
import { useForm } from "react-hook-form";
import {
  EmployeesItemFragment,
  CreateEmployeeInput,
} from "../../generated/graphql";
import { ValidatableInputUI } from "../ValidatableInput";
import { FormGroupUI } from "../FormGroup";
import { FormGroupLabelUI } from "../FormGroupLabel";
import { ButtonUI } from "../Button";
import { ValidatableCustomSelectUI } from "../ValidatableCustomSelect";
import { ValidatableTextAreaUI } from "../ValidatableTextArea";
import { OptionItem } from "../../types/common";
import { LoadingUI } from "../Loading";
import { ValidatableSwitchUI } from "../ValidatableSwitch";

type EmployeeEditUIProps = {
  isNew: boolean;
  isLoading?: boolean;
  selectedEmployee?: EmployeesItemFragment;
  selectItemCompanies: OptionItem[];
  onSubmit: (employeeInput: CreateEmployeeInput) => void;
  onDelete?: (id: string) => void;
};

export const EmployeeEditUI: React.VFC<EmployeeEditUIProps> = ({
  isNew,
  isLoading = false,
  selectedEmployee,
  selectItemCompanies,
  onSubmit,
  onDelete,
}) => {
  const formOptions = {};
  if (!isNew) {
    // eslint-disable-next-line no-unused-vars
    const { __typename, ...employee } = selectedEmployee!;
    Object.assign(formOptions, { defaultValues: employee });
  } else {
    Object.assign(formOptions, { defaultValues: { disabled: false } });
  }
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<CreateEmployeeInput>(formOptions);
  const watchDisabled = watch("disabled", false);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>会社員名</FormGroupLabelUI>
        <ValidatableInputUI
          register={register("name", {
            required: "会社員名は入力必須項目です。",
          })}
          placeholder="会社員名を入力してください"
          error={errors.name}
          className="w-96"
        />
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>かな</FormGroupLabelUI>
        <ValidatableInputUI
          register={register("kana", {})}
          placeholder="かなを入力してください"
          error={errors.kana}
          className="w-96"
        />
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>メールアドレス</FormGroupLabelUI>
        <ValidatableInputUI
          register={register("email", {
            pattern: {
              value:
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i,
              message: "emailの形式が不正です",
            },
          })}
          placeholder="emailを入力してください"
          error={errors.email}
          className="w-96"
        />
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>所属会社</FormGroupLabelUI>
        {isLoading && <LoadingUI type="Grid" width={20} height={20} />}
        <div className="w-full mb-10">
          <ValidatableCustomSelectUI
            registerName="companyId"
            className="w-6/12"
            disabled={isLoading}
            rules={{
              validate: {
                myRequired: (v: string | undefined) => {
                  return v !== undefined || "必須項目です";
                },
              },
            }}
            defaultValue={
              selectItemCompanies.find(
                (user: OptionItem) => user.value === selectedEmployee?.companyId
              )?.label
            }
            error={errors.companyId}
            options={selectItemCompanies}
            id="hr-ageRange-from"
            control={control}
          />
        </div>
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>説明</FormGroupLabelUI>
        <ValidatableTextAreaUI
          register={register("description", {})}
          placeholder="説明を入力してください"
          error={errors.description}
          rows={8}
          className="w-96 h-48"
        />
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>有効、無効</FormGroupLabelUI>
        <ValidatableSwitchUI
          title="有効 / 無効"
          registerName="disabled"
          control={control}
          rules={{}}
        />
      </FormGroupUI>
      {watchDisabled && (
        <FormGroupUI className="mb-5">
          <FormGroupLabelUI>非活性理由</FormGroupLabelUI>
          <ValidatableTextAreaUI
            register={register("disabledReason", {})}
            placeholder="説明を入力してください"
            error={errors.description}
            rows={8}
            className="w-96 h-48"
          />
        </FormGroupUI>
      )}
      <div className="flex justify-center w-96 mb-20 space-x-5">
        {isNew && (
          <ButtonUI size="xs" to="/employees">
            戻る
          </ButtonUI>
        )}
        <ButtonUI size="xs" type="submit">
          {isNew ? "登録" : "更新"}
        </ButtonUI>
        {!isNew && (
          <ButtonUI
            size="xs"
            color="secondary"
            type="button"
            onClick={() => {
              onDelete && onDelete(selectedEmployee!.id);
            }}
          >
            削除
          </ButtonUI>
        )}
      </div>
    </form>
  );
};
