import React, { useCallback, useEffect, useState } from "react";
import EmployeeNewPage from "./EmployeeNewPage";
import {
  CreateEmployeeInput,
  useSelectItemCompaniesQuery,
  SelectItemCompanyFragment,
} from "../../../generated/graphql";
import { useHistory } from "react-router-dom";
import { OptionItem } from "../EmployeesPage/components/SearchEmployeesForm";

export type CreateEmployeeModify = {
  companyName?: string;
} & CreateEmployeeInput;

const EmployeeNewPageContainer: React.VFC = () => {
  const history = useHistory();
  const [selectItemCompanies, setSelectItemCompanies] = useState<OptionItem[]>(
    []
  );
  const { error, data, loading } = useSelectItemCompaniesQuery();
  useEffect(() => {
    if (data) {
      setSelectItemCompanies(
        data.companies.nodes.map((company: SelectItemCompanyFragment) => ({
          value: company.id,
          label: company.name,
        }))
      );
    }
  }, [data]);
  const onCreateEmployee = useCallback(
    (validatedInput: CreateEmployeeInput) => {
      const modifiedInput: CreateEmployeeModify = {
        ...validatedInput,
        companyName: selectItemCompanies.find(
          (company: OptionItem) => company.value === validatedInput.companyId
        )?.label!,
      };
      history.push("/employee/new/confirm", {
        modifiedInput,
      });
    },
    [history, selectItemCompanies]
  );
  if (error) {
    return <div>{JSON.stringify(error, null, 2)}</div>;
  }
  return (
    <EmployeeNewPage
      isLoading={loading}
      onCreateEmployee={onCreateEmployee}
      selectItemCompanies={selectItemCompanies}
    />
  );
};

export default EmployeeNewPageContainer;
