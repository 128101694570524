import React, { MouseEventHandler, useEffect } from "react";
import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { FormGroupUI } from "../../../components/FormGroup";
import { FormGroupLabelUI } from "../../../components/FormGroupLabel";
import { ButtonUI } from "../../../components/Button";
import { PageTitle } from "../../../components/PageTitle";
import { LoadingUI } from "../../../components/Loading";
import {
  CreateDeliveryGroupModify,
  CreateDeliveryEmployeeModify,
} from "../DeliveryGroupNewPage";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "ホーム",
  },
  {
    label: "配信グループ",
  },
  {
    label: "配信グループ作成",
  },
  {
    label: "確認",
  },
];

type DeliveryGroupNewConfirmPageProps = {
  isLoading: boolean;
  createDeliveryGroupModify: CreateDeliveryGroupModify;
  onCreate: MouseEventHandler<HTMLButtonElement>;
};

const DeliveryGroupNewConfirmPage: React.VFC<
  DeliveryGroupNewConfirmPageProps
> = ({ isLoading, createDeliveryGroupModify, onCreate }) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);
  return isLoading ? (
    <div className="w-full flex justify-center pt-20">
      <LoadingUI type="Grid" />
    </div>
  ) : (
    <div className="container mx-auto px-4">
      <PageTitle>配信グループ作成確認</PageTitle>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>配信グループ名</FormGroupLabelUI>
        {createDeliveryGroupModify.name}
      </FormGroupUI>
      <hr />
      <FormGroupUI className="my-5">
        <FormGroupLabelUI>差出人</FormGroupLabelUI>
        {createDeliveryGroupModify.fromUserName}
      </FormGroupUI>
      <hr />
      <FormGroupUI className="my-5">
        <FormGroupLabelUI>宛先</FormGroupLabelUI>
        <ul>
          {createDeliveryGroupModify.deliveryEmployeesModify.map(
            (employee: CreateDeliveryEmployeeModify, index: number) => (
              <li key={index}>{`${
                employee.delivered ? "配信する" : "配信しない"
              } /
              ${employee.name} / ${
                employee.disabled ? "配信不可" : "配信可能"
              }`}</li>
            )
          )}
        </ul>
      </FormGroupUI>
      <hr />
      <FormGroupUI className="my-5">
        <FormGroupLabelUI>説明</FormGroupLabelUI>
        {createDeliveryGroupModify.description}
      </FormGroupUI>
      <div className="flex justify-center w-96 mb-20 space-x-5">
        <ButtonUI size="xs" to="/group/new">
          戻る
        </ButtonUI>
        <ButtonUI size="xs" onClick={onCreate}>
          登録
        </ButtonUI>
      </div>
    </div>
  );
};

export default DeliveryGroupNewConfirmPage;
