import React, { FC, MouseEventHandler } from "react";
import ReactModal from "react-modal";
import {
  CompaniesItemFragment,
  CreateCompanyInput,
} from "../../../../../generated/graphql";
import { modalStyle } from "./CompanyDetailModalStyle";
import { ReactComponent as ClosedIcon } from "./closed.svg";
import { CompanyEditUI } from "../../../../../components/CompanyEdit";
import { OptionItem } from "../../../../../types/common";

type CompanyDetailModalProps = {
  visibility: boolean;
  selectedItemUsers: OptionItem[];
  selectedCompany?: CompaniesItemFragment;
  onUpdateCompany: (companyInput: CreateCompanyInput) => void;
  onDeleteCompany: (id: string) => void;
  onClosed: MouseEventHandler;
};
ReactModal.setAppElement("#root");

export const CompanyDetailModal: FC<CompanyDetailModalProps> = ({
  visibility,
  selectedItemUsers,
  selectedCompany,
  onClosed,
  onUpdateCompany,
  onDeleteCompany,
}) => {
  return (
    <ReactModal
      style={modalStyle}
      isOpen={visibility}
      onRequestClose={onClosed}
    >
      {selectedCompany && (
        <div className="flex flex-col">
          <div className="flex justify-between bg-primary text-white py-2 px-2.5">
            <div className="flex flex-col">
              <h3 className="text-sm font-bold font-hiragino-sans-w7">
                {selectedCompany.name}
              </h3>
            </div>
            <ClosedIcon onClick={onClosed} className="w-7 h-7" />
          </div>
          <div className="whitespace-pre-line text-xs px-2.5 pt-5 overflow-y-scroll h-email-detail-content">
            <CompanyEditUI
              isNew={false}
              selectedCompany={selectedCompany}
              selectItemUsers={selectedItemUsers}
              onSubmit={onUpdateCompany}
              onDelete={onDeleteCompany}
            />
          </div>
        </div>
      )}
    </ReactModal>
  );
};
