import React, { useEffect } from "react";
import type { FormEventHandler, MouseEventHandler, FC } from "react";
import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { PageTitle } from "../../../components/PageTitle";
import {
  CompaniesFragment,
  CompaniesItemFragment,
  CompanySearchInput,
} from "../../../generated/graphql";
import { CompaniesTableUI } from "./components/CompaniesTable";
import { LoadingUI } from "../../../components/Loading";
import {
  ChangeCompanySearchInput,
  // SearchCompaniesFormUI,
} from "./components/SearchCompaniesForm";
import { ButtonUI } from "../../../components/Button";

type CompaniesPageProps = {
  isLoading: boolean;
  searchInput: CompanySearchInput;
  onChangeSearchInput: ChangeCompanySearchInput;
  companies: CompaniesFragment;
  selectedCompany: CompaniesItemFragment;
  onSelectCompany: (id: string) => void;
  onSearch: FormEventHandler;
  onClearFilter: MouseEventHandler;
};

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "ホーム",
  },
  {
    label: "会社",
  },
];

const CompaniesPage: FC<CompaniesPageProps> = ({
  isLoading,
  searchInput,
  onChangeSearchInput,
  companies,
  selectedCompany,
  onSelectCompany,
  onSearch,
  onClearFilter,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  return (
    <div>
      <PageTitle>会社一覧</PageTitle>
      <div className="flex justify-end mb-3">
        <ButtonUI size="xs" to="/company/new">
          会社作成
        </ButtonUI>
      </div>
      {/* <SearchCompaniesFormUI
        searchInput={searchInput}
        onSearch={onSearch}
        onChangeSearchInput={onChangeSearchInput}
        onClearFilter={onClearFilter}
      ></SearchCompaniesFormUI> */}

      {isLoading ? (
        <div className="w-full flex justify-center pt-20">
          <LoadingUI type="Grid" />
        </div>
      ) : (
        <div className="flex flex-col">
          <p className="text-sm tracking-widest mb-2.5">
            全<span className="font-bold text-lg">{companies.totalCount}</span>
            件中
            <span className="font-bold text-lg">{companies.nodes.length}</span>
            件表示
          </p>
          <div className="flex flex-row space-x-2">
            <div className="w-full">
              <div className="shadow overflow-hidden border-b border-gray-200 rounded-md overflow-y-scroll h-screen">
                <CompaniesTableUI
                  companies={companies.nodes}
                  selectedCompany={selectedCompany}
                  onSelectCompany={onSelectCompany}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default CompaniesPage;
