import React from "react";
import { Link } from "react-router-dom";

const AboutPage = () => (
  <>
    <h1>About</h1>
    <p>This is the about page</p>
    <p>
      <Link to="/">Go home</Link>
    </p>
  </>
);

export default AboutPage;
