import React, { MouseEventHandler, FC } from "react";
import ReactModal from "react-modal";
import {
  EmployeesItemFragment,
  CreateEmployeeInput,
} from "../../../../../generated/graphql";
import { modalStyle } from "./EmployeeDetailModalStyle";
import { ReactComponent as ClosedIcon } from "./closed.svg";
import { EmployeeEditUI } from "../../../../../components/EmployeeEdit";
import { OptionItem } from "../../../../../types/common";

type EmployeeDetailModalProps = {
  visibility: boolean;
  selectedItemCompanies: OptionItem[];
  selectedEmployee?: EmployeesItemFragment;
  onUpdateEmployee: (employeeInput: CreateEmployeeInput) => void;
  onDeleteEmployee: (id: string) => void;
  onClosed: MouseEventHandler;
};
ReactModal.setAppElement("#root");

export const EmployeeDetailModal: FC<EmployeeDetailModalProps> = ({
  visibility,
  selectedItemCompanies,
  selectedEmployee,
  onClosed,
  onUpdateEmployee,
  onDeleteEmployee,
}) => {
  return (
    <ReactModal
      style={modalStyle}
      isOpen={visibility}
      onRequestClose={onClosed}
    >
      {selectedEmployee && (
        <div className="flex flex-col">
          <div className="flex justify-between bg-primary text-white py-2 px-2.5">
            <div className="flex flex-col">
              <h3 className="text-sm font-bold font-hiragino-sans-w7">
                {selectedEmployee.name}
              </h3>
            </div>
            <ClosedIcon onClick={onClosed} className="w-7 h-7" />
          </div>
          <div className="whitespace-pre-line text-xs px-2.5 pt-5 overflow-y-scroll h-email-detail-content">
            <EmployeeEditUI
              isNew={false}
              selectedEmployee={selectedEmployee}
              selectItemCompanies={selectedItemCompanies}
              onSubmit={onUpdateEmployee}
              onDelete={onDeleteEmployee}
            />
          </div>
        </div>
      )}
    </ReactModal>
  );
};
