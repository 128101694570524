import React, { MouseEventHandler, FC } from "react";
import ReactModal from "react-modal";
import { EmailFragment } from "../../../../../generated/graphql";
import { modalStyle } from "./EmailDetailModalModalStyle";
import { ReactComponent as ClosedIcon } from "./closed.svg";
import { FreeWordHighlighter } from "../FreeWordHighlighter";

type EmailDetailModalProps = {
  visibility: boolean;
  selectedEmail: EmailFragment;
  onClosed: MouseEventHandler;
  freeWords: string[];
};
ReactModal.setAppElement("#root");

export const EmailDetailModal: FC<EmailDetailModalProps> = ({
  visibility,
  selectedEmail,
  onClosed,
  freeWords,
}) => {
  return (
    <ReactModal
      style={modalStyle}
      isOpen={visibility}
      onRequestClose={onClosed}
    >
      <div className="flex flex-col">
        <div className="flex justify-between bg-primary text-white py-2 px-2.5">
          <div className="flex flex-col">
            <h3 className="text-sm font-bold font-hiragino-sans-w7">
              {selectedEmail.subject}
            </h3>
            <p className="text-nano mt-1 ml-2.5">
              from: {selectedEmail.from.name}({selectedEmail.from.address})
              date: {`${selectedEmail.date}`}
            </p>
          </div>
          <ClosedIcon onClick={onClosed} className="w-7 h-7" />
        </div>
        <div className="whitespace-pre-line text-xs px-2.5 pt-5 overflow-y-scroll h-email-detail-content">
          <FreeWordHighlighter
            type="Modal"
            freeWords={freeWords}
            textToHighlight={selectedEmail.content}
          />
        </div>
      </div>
    </ReactModal>
  );
};
