import dayjs from "dayjs";
import "dayjs/locale/ja";
import React, { FC, useCallback, useState } from "react";
import { ButtonUI } from "../../../../components/Button";
import { emailToNameMap } from "../../../../constants/emailSenders";
import { DeliveryHistoriesItemFragment } from "../../../../generated/graphql";
import { ReactComponent as DocumentDuplicateIcon } from "./document-duplicate.svg";

dayjs.locale("ja");

export type DeliveryHistoriesItemUIProps = {
  deliveryHistory: DeliveryHistoriesItemFragment;
  onResend: (history: DeliveryHistoriesItemFragment) => void;
  onSelectHistory: (id: string) => void;
};

export const DeliveryHistoriesItemUI: FC<DeliveryHistoriesItemUIProps> = ({
  deliveryHistory,
  onResend,
  onSelectHistory,
}) => {
  const [copyMessage, setCopyMessage] = useState("");
  const {
    hrHash,
    subject,
    bulkEmailsTotal,
    bulkEmailsSuccessTotal,
    from,
    deliveryType,
    createdAt,
  } = deliveryHistory;

  const onCopy = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();
      try {
        await navigator.clipboard.writeText(hrHash);
        setCopyMessage("Copied!");
        setTimeout(() => setCopyMessage(""), 1000);
      } catch (err) {
        console.error("Failed to copy text: ", err);
      }
    },
    [hrHash]
  );

  return (
    <tr
      className="cursor-pointer hover:bg-blue-100 overflow-visible"
      onClick={() => onSelectHistory(deliveryHistory.id)}
    >
      <td
        className="px-3 py-2 text-ellipsis whitespace-nowrap overflow-visible"
        style={{ width: "12.5%", maxWidth: "150px" }}
      >
        <div className="flex items-center">
          <span className="mr-2">{hrHash}</span>
          <button
            onClick={onCopy}
            className="p-1 rounded transition-colors duration-200 ease-in-out tooltip hover:opacity-70"
            {...(copyMessage ? { "data-tip": copyMessage } : {})}
          >
            <DocumentDuplicateIcon className="w-5 h-5" />
          </button>
        </div>
      </td>
      <td
        className="px-3 py-2 text-ellipsis whitespace-nowrap overflow-hidden"
        style={{ width: "18.75%", maxWidth: "225px" }}
      >
        {subject}
      </td>
      <td
        className="px-3 py-2 text-ellipsis whitespace-nowrap overflow-hidden"
        style={{ width: "12.5%", maxWidth: "150px" }}
      >
        {bulkEmailsTotal === bulkEmailsSuccessTotal ? "成功" : "失敗"}（
        {bulkEmailsSuccessTotal}／{bulkEmailsTotal}）
      </td>
      <td
        className="px-3 py-2 text-ellipsis whitespace-nowrap overflow-hidden"
        style={{ width: "6.67%", maxWidth: "67px" }}
      >
        {deliveryType}
      </td>
      <td
        className="px-3 py-2 text-ellipsis whitespace-nowrap overflow-hidden"
        style={{ width: "6.67%", maxWidth: "67px" }}
      >
        {emailToNameMap[from] || from}
      </td>
      <td
        className="px-3 py-2 text-ellipsis whitespace-nowrap overflow-hidden"
        style={{ width: "12.92%", maxWidth: "155px" }}
      >
        {dayjs(createdAt).format("YYYY/MM/DD (ddd) HH:mm")}
      </td>
      <td className="px-3 py-2" style={{ width: "6.67%", maxWidth: "67px" }}>
        <ButtonUI
          size="sm"
          type="button"
          color="success"
          onClick={e => {
            e.stopPropagation();
            onResend(deliveryHistory);
          }}
        >
          再配信
        </ButtonUI>
      </td>
    </tr>
  );
};
