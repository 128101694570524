import React, { FC, MouseEventHandler } from "react";
import ReactModal from "react-modal";
import { modalStyle } from "./DeliveryGroupDetailModalStyle";
import { ReactComponent as ClosedIcon } from "./closed.svg";
import { DeliveryGroupEditUI } from "../../../../../components/DeliveryGroupEdit";
import { OptionItem } from "../../../../../types/common";
import { UpdateDeliveryGroupModify } from "../..";

type DeliveryGroupDetailModalProps = {
  visibility: boolean;
  selectItemUsers: OptionItem[];
  selectItemEmployees: OptionItem[];
  selectedDeliveryGroup?: UpdateDeliveryGroupModify;
  onUpdateDeliveryGroup: (
    deliveryGroupModify: UpdateDeliveryGroupModify
  ) => void;
  onDeleteDeliveryGroup: (id: string) => void;
  onClosed: MouseEventHandler;
};
ReactModal.setAppElement("#root");

export const DeliveryGroupDetailModal: FC<DeliveryGroupDetailModalProps> = ({
  visibility,
  selectItemUsers,
  selectItemEmployees,
  selectedDeliveryGroup,
  onClosed,
  onUpdateDeliveryGroup,
  onDeleteDeliveryGroup,
}) => {
  return (
    <ReactModal
      style={modalStyle}
      isOpen={visibility}
      onRequestClose={onClosed}
    >
      {selectedDeliveryGroup && (
        <div className="flex flex-col">
          <div className="flex justify-between bg-primary text-white py-2 px-2.5">
            <div className="flex flex-col">
              <h3 className="text-sm font-bold font-hiragino-sans-w7">
                {selectedDeliveryGroup.name}
              </h3>
            </div>
            <ClosedIcon onClick={onClosed} className="w-7 h-7" />
          </div>
          <div className="whitespace-pre-line text-xs px-2.5 pt-5 overflow-y-scroll h-email-detail-content">
            <DeliveryGroupEditUI
              isNew={false}
              selectedDeliveryGroup={selectedDeliveryGroup}
              selectItemUsers={selectItemUsers}
              selectItemEmployees={selectItemEmployees}
              onUpdate={onUpdateDeliveryGroup}
              onDelete={onDeleteDeliveryGroup}
            />
          </div>
        </div>
      )}
    </ReactModal>
  );
};
