import dayjs from "dayjs";
import "dayjs/locale/ja";
import React, { FC } from "react";
import { emailToNameMap } from "../../../../constants/emailSenders";
import { DeliverySchedulesItemFragment } from "../../../../generated/graphql";

dayjs.locale("ja");

export type DeliverySchedulesItemUIProps = {
  deliverySchedule: DeliverySchedulesItemFragment;
  onSelectSchedule: (id: string) => void;
};

export const DeliverySchedulesItemUI: FC<DeliverySchedulesItemUIProps> = ({
  deliverySchedule,
  onSelectSchedule,
}) => {
  const { hrHash, subject, from, deliveryType, createdAt, scheduledAt } =
    deliverySchedule;

  return (
    <tr
      className="cursor-pointer hover:bg-blue-100 overflow-visible"
      onClick={() => onSelectSchedule(deliverySchedule.id)}
    >
      <td
        className="px-3 py-2 text-ellipsis whitespace-nowrap overflow-hidden"
        style={{ width: "12.92%", maxWidth: "155px" }}
      >
        {dayjs(scheduledAt).format("YYYY/MM/DD (ddd) HH:mm")}予定
      </td>
      <td
        className="px-3 py-2 text-ellipsis whitespace-nowrap overflow-visible"
        style={{ width: "12.5%", maxWidth: "150px" }}
      >
        <div className="flex items-center">{hrHash}</div>
      </td>
      <td
        className="px-3 py-2 text-ellipsis whitespace-nowrap overflow-hidden"
        style={{ width: "18.75%", maxWidth: "225px" }}
      >
        {subject}
      </td>
      <td
        className="px-3 py-2 text-ellipsis whitespace-nowrap overflow-hidden"
        style={{ width: "6.67%", maxWidth: "67px" }}
      >
        {deliveryType}
      </td>
      <td
        className="px-3 py-2 text-ellipsis whitespace-nowrap overflow-hidden"
        style={{ width: "6.67%", maxWidth: "67px" }}
      >
        {emailToNameMap[from] || from}
      </td>
      <td
        className="px-3 py-2 text-ellipsis whitespace-nowrap overflow-hidden"
        style={{ width: "12.92%", maxWidth: "155px" }}
      >
        {dayjs(createdAt).format("YYYY/MM/DD (ddd) HH:mm")}
      </td>
    </tr>
  );
};
