import React, { FC } from "react";

type SearchEmailStatsProps = {
  totalCount: number;
  displayCount: number;
  invisibleCount: number;
  favoriteCount: number;
};

export const SearchEmailStats: FC<SearchEmailStatsProps> = ({
  totalCount,
  displayCount,
  invisibleCount,
  favoriteCount,
}) => {
  return (
    <div className="flex">
      <div className="stats stats-vertical md:stats-horizontal">
        <div className="stat place-items-center gap-0 px-10 hover:opacity-70">
          <div className="text-2xl leading-6 font-extrabold col-start-1 whitespace-no-wrap tabular-nums">
            {totalCount}
          </div>
          <div className="stat-desc">総件数</div>
        </div>
        <div className="stat place-items-center gap-0 px-10 hover:opacity-70">
          <div className="text-2xl leading-6 font-extrabold col-start-1 whitespace-no-wrap tabular-nums">
            {displayCount}
          </div>
          <div className="stat-desc">表示件数</div>
        </div>
        <div className="stat place-items-center gap-0 px-10 hover:opacity-70">
          <div className="text-2xl leading-6 font-extrabold col-start-1 whitespace-no-wrap tabular-nums">
            {invisibleCount}
          </div>
          <div className="stat-desc">非表示件数</div>
        </div>
        <div className="stat place-items-center gap-0 px-10 hover:opacity-70">
          <div className="text-2xl leading-6 font-extrabold col-start-1 whitespace-no-wrap tabular-nums">
            {favoriteCount}
          </div>
          <div className="stat-desc">お気に入り件数</div>
        </div>
      </div>
    </div>
  );
};
