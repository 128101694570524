import React, { useCallback, useEffect, useState } from "react";
import CompanyNewPage from "./CompanyNewPage";
import {
  CreateCompanyInput,
  useSelectItemUsersQuery,
  SelectItemUserFragment,
} from "../../../generated/graphql";
import { useHistory } from "react-router-dom";
import { OptionItem } from "../CompaniesPage/components/SearchCompaniesForm";

export type CreateCompanyModify = {
  connecterName?: string;
} & CreateCompanyInput;

const CompanyNewPageContainer: React.VFC = () => {
  const history = useHistory();
  const [selectItemUsers, setSelectItemUsers] = useState<OptionItem[]>([]);
  const { error, data, loading } = useSelectItemUsersQuery();
  useEffect(() => {
    if (data) {
      setSelectItemUsers(
        data.users.nodes.map((user: SelectItemUserFragment) => ({
          value: user.id,
          label: `${user.userId} (${user.familyName} ${user.givenName})`,
        }))
      );
    }
  }, [data]);
  const onCreateCompany = useCallback(
    (validatedInput: CreateCompanyInput) => {
      const modifiedInput: CreateCompanyModify = {
        ...validatedInput,
        connecterName: selectItemUsers.find(
          (user: OptionItem) => user.value === validatedInput.connecterId
        )?.label!,
      };
      history.push("/company/new/confirm", {
        modifiedInput,
      });
    },
    [history, selectItemUsers]
  );
  if (error) {
    return <div>{JSON.stringify(error, null, 2)}</div>;
  }
  return (
    <CompanyNewPage
      isLoading={loading}
      onCreateCompany={onCreateCompany}
      selectItemUsers={selectItemUsers}
    />
  );
};

export default CompanyNewPageContainer;
