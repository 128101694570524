import React, {
  useCallback,
  MouseEventHandler,
  ChangeEvent,
  MouseEvent,
  useState,
} from "react";
import {
  CreateCompanyWithEmployeeInput,
  useBulkCreateCompaniesWithEmployeesMutation,
  useBulkAllDeleteCompaniesMutation,
} from "../../../generated/graphql";
import BulkRegistrationPage from "./BulkRegistrationPage";
import { toastPromise } from "../../../utils/toastUtils";
import Papa, { ParseResult } from "papaparse";
import dayjs from "dayjs";

type BulkCSVInput = {
  status: string;
  employeeName: string;
  name: string;
  employeeEmail: string;
  connecterName: string;
  attributes: string;
  createdDate: string;
};

const BulkRegistrationPageContainer: React.VFC = () => {
  const [bulkCreateCompanyWithEmployee] =
    useBulkCreateCompaniesWithEmployeesMutation({
      onCompleted: () => {
        // do nothings
      },
    });

  const [bulkDeleteCompaniesAndEmployees] = useBulkAllDeleteCompaniesMutation({
    onCompleted: async () => {
      const CHUNK_NUM = 100;
      let count = 0;
      for (const inputs of chunk(createCompanyWithEmployeeInput, CHUNK_NUM)) {
        count += CHUNK_NUM;
        const statusMessage = `(${
          createCompanyWithEmployeeInput.length >= count
            ? count
            : createCompanyWithEmployeeInput.length
        } / ${createCompanyWithEmployeeInput.length}件目)`;
        await toastPromise(
          bulkCreateCompanyWithEmployee({
            variables: {
              inputs,
            },
          }),
          {
            pending: `処理中${statusMessage}`,
            success: `会社と従業員の登録に成功しました${statusMessage}`,
            error: `会社と従業員の登録に失敗しました${statusMessage}`,
          }
        );
      }
    },
  });

  const [createCompanyWithEmployeeInput, setCreateCompanyWithEmployeeInput] =
    useState<CreateCompanyWithEmployeeInput[]>([]);

  const onChangeFileInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        return;
      }
      Papa.parse<BulkCSVInput>(event.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: (results: ParseResult<BulkCSVInput>) => {
          const companiesWithEmployees: CreateCompanyWithEmployeeInput[] =
            results.data.map((result: BulkCSVInput) => {
              const connecterId =
                result.connecterName === "大澤"
                  ? "mimimimi-42bf-4f58-9a94-e3b6dee6dd25"
                  : "mamamama-42bf-4f58-9a94-e3b6dee6dd25";
              const disabled = result.status !== "配信中";
              const createCompanyWithEmployeeInput: CreateCompanyWithEmployeeInput =
                {
                  name: result.name,
                  connecterId,
                  employeeName: result.employeeName,
                  employeeEmail: result.employeeEmail,
                  createdAt: dayjs(result.createdDate).isValid()
                    ? dayjs(result.createdDate).toDate()
                    : dayjs().toDate(),
                  disabled,
                };
              return createCompanyWithEmployeeInput;
            });
          setCreateCompanyWithEmployeeInput(companiesWithEmployees);
          // setCreateCompanyWithEmployeeInput([companiesWithEmployees[0], companiesWithEmployees[1], companiesWithEmployees[2]]);
        },
      });
    },
    []
  );

  const chunk = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (arrayData: any[], chunkSize: number) =>
      Array.from(
        { length: Math.ceil(arrayData.length / chunkSize) },
        (v, index: number) =>
          arrayData.slice(index * chunkSize, index * chunkSize + chunkSize)
      ),
    []
  );

  const onUploadFile: MouseEventHandler<HTMLButtonElement> = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      try {
        event.preventDefault();
        await toastPromise(bulkDeleteCompaniesAndEmployees(), {
          pending: "既存データを削除中",
          success: "既存データの削除に成功しました",
          error: "既存データの削除に失敗しました",
        });
      } catch (error) {
        console.log(error);
      }
    },
    [bulkDeleteCompaniesAndEmployees]
  );

  return (
    <BulkRegistrationPage
      createCompanyWithEmployeeInput={createCompanyWithEmployeeInput}
      onUploadFile={onUploadFile}
      onChangeFileInput={onChangeFileInput}
    />
  );
};

export default BulkRegistrationPageContainer;
