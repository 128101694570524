import React, {
  useState,
  useCallback,
  FormEventHandler,
  useEffect,
  MouseEventHandler,
} from "react";
import EmployeesPage from "./EmployeesPage";
import {
  EmployeeSearchInput,
  useEmployeesPageQuery,
  EmployeesFragment,
  EmployeesItemFragment,
  CreateEmployeeInput,
  useUpdateEmployeeMutation,
  UpdateEmployeeInput,
  useDeleteEmployeeMutation,
  SelectItemCompanyFragment,
} from "../../../generated/graphql";
import { ChangeEmployeeSearchInput } from "./components/SearchEmployeesForm";
import { EmployeeDetailModal } from "./components/EmployeeDetailModal";
import { OptionItem } from "../../../types/common";

const EmployeesPageContainer: React.VFC = () => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectItemCompanies, setSelectItemCompanies] = useState<OptionItem[]>(
    []
  );
  const [searchInput, setSearchInput] = useState<EmployeeSearchInput>();
  const { error, data, refetch } = useEmployeesPageQuery({
    onCompleted: async () => {
      setIsLoading(false);
    },
    notifyOnNetworkStatusChange: true,
  });
  const [selectedEmployee, setSelectedEmployee] =
    useState<EmployeesItemFragment>();

  const [employees, setEmployees] = useState<EmployeesFragment>({
    totalCount: 0,
    nodes: [],
  });
  const [updateEmployee, { error: errorMutation }] = useUpdateEmployeeMutation({
    onCompleted: async () => {
      await refetch({
        filter: searchInput,
        offset: 0,
        limit: 50,
      });
      setIsLoading(false);
    },
  });

  const [deleteEmployee] = useDeleteEmployeeMutation({
    onCompleted: async () => {
      await refetch({
        filter: searchInput,
        offset: 0,
        limit: 50,
      });
      setIsLoading(false);
    },
  });

  useEffect(() => {
    let isMounted = true;
    if (isMounted && data) {
      setEmployees(data.employees);
      setSelectItemCompanies(
        data.companies.nodes.map((company: SelectItemCompanyFragment) => ({
          value: company.id,
          label: company.name,
        }))
      );
    }
    return () => {
      isMounted = false;
    };
  }, [data]);
  const onSelectEmployee = useCallback(
    (id: string) => {
      const employee: EmployeesItemFragment = employees.nodes.find(
        (employee: EmployeesItemFragment) => employee.id === id
      )!;
      setSelectedEmployee(employee);
      setModalVisibility(true);
    },
    [employees.nodes]
  );
  const onChangeSearchInput: ChangeEmployeeSearchInput = useCallback(
    (newSearchInput: EmployeeSearchInput) => {
      setSearchInput(newSearchInput);
    },
    []
  );
  const onSearch: FormEventHandler = useCallback(
    async event => {
      event.preventDefault();
      setIsLoading(true);
      await refetch({
        filter: searchInput,
        offset: 0,
        limit: 50,
      });
    },
    [searchInput, refetch]
  );

  const onClearFilter: MouseEventHandler = useCallback(
    async event => {
      event.preventDefault();
      setSearchInput({
        ...searchInput,
      });
    },
    [searchInput]
  );

  const onClosedModal: MouseEventHandler = useCallback(async event => {
    event.preventDefault();
    setModalVisibility(false);
  }, []);

  const onUpdateEmployee = useCallback(
    async (validatedInput: CreateEmployeeInput) => {
      if (!selectedEmployee?.id) {
        return;
      }
      setModalVisibility(false);
      setIsLoading(true);
      const input: UpdateEmployeeInput = {
        ...validatedInput,
        id: selectedEmployee!.id,
      };
      await updateEmployee({
        variables: {
          input,
        },
      });
    },
    [selectedEmployee, updateEmployee]
  );

  const onDeleteEmployee = useCallback(
    async (id: string) => {
      setModalVisibility(false);
      setIsLoading(true);
      await deleteEmployee({
        variables: {
          id,
        },
      });
    },
    [deleteEmployee]
  );

  if (error) {
    return <div>{JSON.stringify(error, null, 2)}</div>;
  }
  if (errorMutation) {
    return <div>{JSON.stringify(errorMutation, null, 2)}</div>;
  }
  return (
    <>
      <EmployeesPage
        isLoading={isLoading}
        searchInput={searchInput!}
        selectedEmployee={selectedEmployee!}
        employees={employees}
        onSelectEmployee={onSelectEmployee}
        onChangeSearchInput={onChangeSearchInput}
        onSearch={onSearch}
        onClearFilter={onClearFilter}
      />
      <EmployeeDetailModal
        visibility={modalVisibility}
        selectedItemCompanies={selectItemCompanies}
        selectedEmployee={selectedEmployee}
        onClosed={onClosedModal}
        onUpdateEmployee={onUpdateEmployee}
        onDeleteEmployee={onDeleteEmployee}
      />
    </>
  );
};

export default EmployeesPageContainer;
