import React, { ChangeEvent, InputHTMLAttributes, useState, FC } from "react";
import styles from "./ValidatableCheckbox.module.scss";
import { Controller, Control } from "react-hook-form";

export type ValidatableCheckboxUIProps = {
  type?: "s" | "m";
  registerName: string;
  // TODO: 直す
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, object>;
  rules: object;
  defaultChecked?: boolean;
  onCheckChange?: (checked: boolean, value: string) => void;
} & InputHTMLAttributes<HTMLInputElement>;

export const ValidatableCheckboxUI: FC<ValidatableCheckboxUIProps> = ({
  className,
  registerName,
  control,
  type = "s",
  children,
  id,
  name,
  rules,
  defaultChecked = false,
  onCheckChange,
  ...rest
}) => {
  const boxSize = type === "s" ? "h-4 w-4" : "h-7 w-7";
  const [checked, setChecked] = useState(defaultChecked);

  return (
    <Controller
      name={registerName}
      control={control}
      rules={rules}
      render={({ field }) => (
        <div className={`flex items-center ${className}`}>
          <input
            {...field}
            className={`appearance-none bg-white border mr-1.5 border-dark-gray ${boxSize} rounded ${styles.check} ${className}`}
            type="checkbox"
            id={id}
            name={name}
            checked={checked}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setChecked(event.target.checked);
              return field.onChange(event.target.checked);
            }}
            {...rest}
          />
          <label className="text-xs pt-2" htmlFor={id}>
            {children}
          </label>
        </div>
      )}
    />
  );
};
