import { yupResolver } from "@hookform/resolvers/yup";
import React, { FC, useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { ButtonUI } from "../../../../components/Button";
import { EmailSearchInput } from "../../../../generated/graphql";
import { HRTypeFormUI } from "./HRTypeForm/HRTypeForm";
import { MatterTypeFormUI } from "./MatterTypeForm/MatterTypeForm";

export type ChangeIsMatterHandler = (isMatter: boolean) => void;

export type SearchEmailsFormUIProps = {
  isLoading: boolean;
  currentSearchInput: EmailSearchInput;
  isMatter: boolean;
  onSearch: SubmitHandler<EmailSearchInput>;
  onClearFilter: () => void;
  onChangeIsMatter: ChangeIsMatterHandler;
};

export const SearchEmailsFormUI: FC<SearchEmailsFormUIProps> = ({
  isLoading,
  currentSearchInput,
  isMatter,
  onSearch,
  onClearFilter,
  onChangeIsMatter,
}) => {
  const schema = yup
    .object()
    .shape({
      recruitmentType: yup.string(),
      hrFilter: yup.object().shape({
        genderType: yup.string().nullable(),
        ageRange: yup.object().shape({
          from: yup.number(),
          to: yup.number(),
        }),
        rewardRange: yup.object().shape({
          from: yup.number(),
          to: yup.number(),
        }),
        hrTypes: yup.object().shape({
          freerance: yup.boolean(),
          contractEmployee: yup.boolean(),
          fullTimeEmployee: yup.boolean(),
        }),
        immediately: yup.boolean(),
        needRemote: yup.boolean(),
        onlyJapanese: yup.boolean(),
      }),
      matterFilter: yup.object().shape({
        rewardRange: yup.object().shape({
          from: yup.number(),
          to: yup.number(),
        }),
        distanceFromEnd: yup.number(),
        oneOpportunity: yup.boolean(),
        otherCountriesEnabled: yup.boolean(),
        onlyEnableFreelance: yup.boolean(),
        enableRemote: yup.boolean(),
      }),
      limitedByDay: yup.number(),
      orderType: yup.string(),
      feewords: yup.array().of(yup.string()),
    })
    .required();

  const methods = useForm<EmailSearchInput>({
    resolver: yupResolver(schema),
    defaultValues: currentSearchInput,
  });

  useEffect(() => {
    methods.reset(currentSearchInput);
  }, [currentSearchInput, methods]);
  return (
    <div className="text-base-content glass xl:rounded-box p-2">
      <div className="col-span-3 row-span-3 mx-2 flex w-72 flex-shrink-0 flex-col xl:mx-0 xl:w-full svelte-1n6ue57">
        <div className="bg-opacity-100">
          <div className="tabs w-full flex-grow-0">
            <button
              data-testid="tab-matter"
              className={`tab tab-lifted tab-border-none tab-lg flex-1 ${
                isMatter ? "tab-active" : ""
              }`}
              onClick={() => {
                onChangeIsMatter(true);
              }}
            >
              案件
            </button>
            <button
              data-testid="tab-human-resource"
              className={`tab tab-lifted tab-border-none tab-lg flex-1 ${
                isMatter ? "" : "tab-active"
              }`}
              onClick={() => {
                onChangeIsMatter(false);
              }}
            >
              人材
            </button>
          </div>
        </div>
        {/* 案件 */}
        <div className="bg-base-100 grid w-full flex-grow gap-3 rounded-xl rounded-tl-none p-6 shadow-xl">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSearch)}>
              {isMatter ? <MatterTypeFormUI /> : <HRTypeFormUI />}
              <div className="flex justify-center space-x-5 mt-5">
                <ButtonUI
                  data-testid="search-btn"
                  type="submit"
                  className="w-40"
                  disabled={isLoading}
                >
                  検索
                </ButtonUI>
                <ButtonUI
                  type="button"
                  isOutline={true}
                  className="w-40"
                  disabled={isLoading}
                  onClick={() => {
                    onClearFilter();
                    methods.reset();
                  }}
                >
                  クリア
                </ButtonUI>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};
