import React, { HTMLAttributes, useCallback, FC } from "react";
import { LocationDescriptor } from "history";
import { useFormContext } from "react-hook-form";

export type RangeUIProps = {
  registerName: `${string}` | `${string}.${string}` | `${string}.${number}`;
  size?: "xs" | "sm" | "md" | "lg";
  color?:
    | "primary"
    | "secondary"
    | "info"
    | "accent"
    | "success"
    | "warning"
    | "error";
  title?: string;
  max: number;
  min: number;
  step: number;
  unitName?: string;
  to?: LocationDescriptor;
} & HTMLAttributes<HTMLDivElement>;

export const RangeUI: FC<RangeUIProps> = ({
  title = "",
  size = "md",
  color = "",
  max,
  min,
  step,
  registerName,
  unitName = "",
  children,
  className,
  ...rest
}) => {
  const { register } = useFormContext();
  const getSize = useCallback(() => `range-${size}`, [size]);

  const getColor = useCallback(
    () => (color !== "" ? `range-${color}` : ""),
    [color]
  );
  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        className={`range ${getSize()} ${getColor()}`}
        {...register(registerName)}
        {...rest}
      />
      <div className="w-full flex justify-between text-xs px-2">
        {[...Array(max - min + 1).keys()]
          .filter((num: number) => num % step === 0)
          .map((num: number) => (
            <span>
              {num + min}
              {unitName ? unitName : ""}
            </span>
          ))}
      </div>
    </>
  );
};
