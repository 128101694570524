import React, { FC } from "react";
import { GenderType, EmailSearchInput } from "../../../../../generated/graphql";
import { FormGroupUI } from "../../../../../components/FormGroup";
import { FormGroupLabelUI } from "../../../../../components/FormGroupLabel";
import {
  limitedDaysOptions,
  hrOrderTypeOptions,
  INITIAL_HR_FILTER,
} from "../../../../../constants/searchFiilter";
import { ReactSelectUI } from "../../../../../components/rhfs/ReactSelect";
import { FromToRangeUI } from "../../../../../components/rhfs/FromToRange";
import { ReactCreatableMultiSelectUI } from "../../../../../components/rhfs/ReactCreatableMultiSelect";
import { ToggleUI } from "../../../../../components/rhfs/Toggle";
import { RadioUI } from "../../../../../components/rhfs/Radio";
import { OptionItem } from "../../../../../types/common";

export type ChangeEmailSearchInput = (searchInput: EmailSearchInput) => void;
export type ChangeIsMatterHandler = (isMatter: boolean) => void;

const genders: OptionItem[] = [
  {
    label: "男性",
    value: GenderType.Male,
  },
  {
    label: "女性",
    value: GenderType.Female,
  },
];

export const HRTypeFormUI: FC = () => {
  return (
    <div className="flex flex-row w-full">
      {/* 左側 */}
      <div className="flex flex-col grow">
        <FormGroupUI className="mb-10">
          <FormGroupLabelUI>期間</FormGroupLabelUI>
          <ReactSelectUI
            registerName="limitedByDay"
            className="w-40"
            options={limitedDaysOptions}
            isSetDefault={true}
          />
        </FormGroupUI>
        {/* 単価 */}
        <FormGroupUI className="mb-5">
          <FormGroupLabelUI>単価</FormGroupLabelUI>
          <FromToRangeUI
            testLabel="hr-reward"
            registerFrom="hrFilter.rewardRange.from"
            registerTo="hrFilter.rewardRange.to"
            min={INITIAL_HR_FILTER.rewardRange!.from!}
            max={INITIAL_HR_FILTER.rewardRange!.to!}
            step={5}
            rangeSize="xs"
            unitName="万円"
          />
        </FormGroupUI>
        {/* 男性 OR 女性 */}
        <FormGroupUI className="mb-5">
          <FormGroupLabelUI>性別</FormGroupLabelUI>
          <RadioUI
            registerName="hrFilter.genderType"
            radioType="gender"
            options={genders}
          />
        </FormGroupUI>
      </div>
      <div className="divider divider-horizontal"></div>
      {/* 中央 */}
      <div className="flex flex-col grow">
        <FormGroupUI className="mb-10">
          <FormGroupLabelUI>並び順</FormGroupLabelUI>
          <ReactSelectUI
            registerName="orderType"
            className="w-40"
            options={hrOrderTypeOptions}
            isSetDefault={true}
          />
        </FormGroupUI>
        {/* 年齢 */}
        <FormGroupUI className="mb-5">
          <FormGroupLabelUI>年齢</FormGroupLabelUI>
          <FromToRangeUI
            testLabel="hr-age"
            registerFrom="hrFilter.ageRange.from"
            registerTo="hrFilter.ageRange.to"
            min={INITIAL_HR_FILTER.ageRange!.from!}
            max={INITIAL_HR_FILTER.ageRange!.to!}
            step={1}
            rangeSize="xs"
            unitName="歳"
          />
        </FormGroupUI>
        <FormGroupUI className="mb-5">
          <FormGroupLabelUI>フリーワード</FormGroupLabelUI>
          <ReactCreatableMultiSelectUI
            registerName="freeWords"
            className="w-full max-w-xl"
          />
        </FormGroupUI>
      </div>
      <div className="divider divider-horizontal"></div>
      {/* 右側 */}
      <div className="flex flex-col grow-0 w-60 space-y-2.5">
        <ToggleUI
          color="primary"
          label="正社員"
          registerName="hrFilter.hrTypes.fullTimeEmployee"
        />
        <ToggleUI
          color="primary"
          label="フリーランス"
          registerName="hrFilter.hrTypes.freerance"
        />
        <ToggleUI
          color="primary"
          label="契約社員"
          registerName="hrFilter.hrTypes.contractEmployee"
        />
        <ToggleUI
          color="primary"
          label="直属のみ"
          registerName="hrFilter.immediately"
        />
        <ToggleUI
          color="primary"
          label="リモート希望"
          registerName="hrFilter.needRemote"
        />
        <ToggleUI
          color="primary"
          label="日本籍のみ"
          registerName="hrFilter.onlyJapanese"
        />
      </div>
    </div>
  );
};
