/**
 * ローカルストレージは基本的に利用したくないが、
 * 個々のユーザーが特定のメールを気軽に非表示、表示またはお気に入り、お気に入り解除できるようにするために、DB等工数を抑えるために使用する。
 */
export default class LocalStorageEmails {
  private storageKey: string;
  /**
   * @param {String} storageKey
   */
  constructor(storageKey: string) {
    this.storageKey = storageKey;
  }
  /**
   * メールIDをローカルストレージに追加
   * @param {String} emailId
   */
  appendEmailId(emailId: string): void {
    const existEmails = this.getEmailIds();
    const newEmails = [emailId, ...existEmails];
    localStorage.setItem(this.storageKey, JSON.stringify(newEmails));
  }
  /**
   * メールID一覧を取得する
   * @return {String[]} メールID一覧
   */
  getEmailIds(): string[] {
    const existEmails = localStorage.getItem(this.storageKey);
    return existEmails ? JSON.parse(existEmails) : [];
  }
  /**
   * 指定のメールIDがローカルストレージにセットされているかどうか
   * @param {String} emailId
   * @return {Boolean} メールIDが既に存在するかどうか
   */
  existEmailId(emailId: string): boolean {
    const existEmails = this.getEmailIds();
    return existEmails.includes(emailId);
  }
  /**
   * 指定したメールIDを削除する
   * @param {String} emailId
   */
  removeEmailId(emailId: string): void {
    const existEmails = this.getEmailIds();
    const setEmails = existEmails.filter((id: string) => id !== emailId);
    localStorage.setItem(this.storageKey, JSON.stringify(setEmails));
  }
  /**
   * ローカルストレージにセットされたメールID一覧をリセットする
   */
  resetEmailIds(): void {
    localStorage.removeItem(this.storageKey);
  }
}
