import React, { HTMLAttributes, FC } from "react";
import { useBreadcrumb } from "./context";

export type Breadcrumb = {
  label: string;
};

export type BreadcrumbProps = HTMLAttributes<HTMLElement>;

export const BreadcrumbUI: FC<BreadcrumbProps> = () => {
  const context = useBreadcrumb();
  return (
    <div className="text-sm breadcrumbs">
      <ul>
        {context.breadcrumbItems.map((v, index) => {
          return <li key={index}>{v.label}</li>;
        })}
      </ul>
    </div>
  );
};
