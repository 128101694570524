import React, { useCallback, useContext, useEffect, useRef } from "react";
import type { FC, FocusEventHandler, FocusEvent } from "react";
import { DropdownMenuItemUI } from "./DropdownMenuItem";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../hooks/authProvider/authProvider";

export type DropdownMenuUIProps = {
  visibility: boolean;
  onHidden: () => void;
};

export const DropdownMenuUI: FC<DropdownMenuUIProps> = ({
  visibility,
  onHidden,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { signOut } = useContext(AuthContext);
  const history = useHistory();

  const onBlurHandler: FocusEventHandler<HTMLDivElement> = useCallback(
    (event: FocusEvent) => {
      if (!event.currentTarget.contains(event.relatedTarget as Node)) {
        onHidden();
      }
    },
    [onHidden]
  );

  useEffect(() => {
    visibility && ref.current && ref.current.focus();
  }, [visibility]);
  const logout = async () => {
    await signOut();
    history.push("/login");
  };
  if (!visibility) {
    return <></>;
  }
  return (
    <div
      ref={ref}
      className="absolute top-16 right-0 w-36 text-xs border border-blue focus:outline-none"
      tabIndex={1}
      onBlur={onBlurHandler}
    >
      <ul>
        {/* <DropdownMenuItemUI to={"/profile"} onClick={onClickItemHandler}>
          プロフィール設定
        </DropdownMenuItemUI> */}
        <DropdownMenuItemUI onClick={logout}>ログアウト</DropdownMenuItemUI>
      </ul>
    </div>
  );
};
