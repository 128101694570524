import React, { FC, MouseEventHandler } from "react";
import { ContextMenuStatus } from "../..";

type ContextMenuUIProps = {
  status: ContextMenuStatus;
  onSelectedMenu: MouseEventHandler;
  onClosed: MouseEventHandler;
};

export const ContextMenuUI: FC<ContextMenuUIProps> = ({
  status,
  onClosed,
  onSelectedMenu,
}) => {
  const offsetX = 0;
  const offsetY = 0;
  return status.visible === false ? (
    <></>
  ) : (
    <div
      className="absolute z-40 top-0 left-0 w-full h-full"
      onClick={onClosed}
    >
      <ul
        className="relative w-24 h-auto z-50 flex justify-items-start py-2 px-2 text-sm rounded-md border border-primary bg-white hover:bg-yellow-200 shadow-lg"
        style={{
          top: status.clientY + offsetY,
          left: status.clientX + offsetX,
        }}
      >
        <li onClick={onSelectedMenu}>非表示</li>
      </ul>
    </div>
  );
};
