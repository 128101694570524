import React, { useState, useCallback, MouseEventHandler } from "react";
import {
  CreateDeliveryGroupInput,
  useCreateDeliveryGroupMutation,
} from "../../../generated/graphql";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import DeliveryGroupNewConfirmPage from "./DeliveryGroupNewConfirmPage";
import {
  CreateDeliveryGroupModify,
  CreateDeliveryEmployeeModify,
} from "../DeliveryGroupNewPage";

const DeliveryGroupNewConfirmPageContainer: React.VFC = () => {
  const { modifiedInput } = useLocation<{
    modifiedInput: CreateDeliveryGroupModify;
  }>().state;
  const [createDeliveryGroupModify] =
    useState<CreateDeliveryGroupModify>(modifiedInput);
  const history = useHistory();
  const [createDeliveryGroup, { loading, error }] =
    useCreateDeliveryGroupMutation({
      onCompleted: () => {
        // do nothings
      },
    });

  const onCreateDeliveryGroup: MouseEventHandler = useCallback(
    async event => {
      event.preventDefault();
      const input: CreateDeliveryGroupInput = {
        name: modifiedInput.name,
        description: modifiedInput.description,
        fromUserId: modifiedInput.fromUserId,
        toEmployees: modifiedInput.deliveryEmployeesModify.map(
          (employee: CreateDeliveryEmployeeModify) => ({
            employeeId: employee.employeeId,
            delivered: employee.delivered,
            disabled: employee.disabled,
          })
        ),
      };
      await createDeliveryGroup({
        variables: {
          input,
        },
      });
      history.push("/groups");
    },
    [history, modifiedInput, createDeliveryGroup]
  );

  if (error) {
    return <>{`Submission error! ${error.message}`}</>;
  }

  return (
    <DeliveryGroupNewConfirmPage
      isLoading={loading}
      createDeliveryGroupModify={createDeliveryGroupModify}
      onCreate={onCreateDeliveryGroup}
    />
  );
};

export default DeliveryGroupNewConfirmPageContainer;
