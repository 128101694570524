import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Auth } from "aws-amplify";
import { IsUserLoggedInDocument } from "../generated/graphql";

const ENDPOINT = process.env.REACT_APP_PUBLIC_GRAPHQL_ENDPOINT;

const httpLink = new HttpLink({
  uri: ENDPOINT,
});

const authLink = setContext(async (_, { headers }) => {
  try {
    const user = await Auth.currentSession();
    const token = user.getAccessToken().getJwtToken();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  } catch (e) {
    return headers;
  }
});

const cache = new InMemoryCache();

/**
 * createApolloClient
 * @return {ApolloClient} ApolloClient
 */
function createApolloClient() {
  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
  });
}

export const client = createApolloClient();

Auth.currentAuthenticatedUser()
  .then(user => {
    client.writeQuery({
      query: IsUserLoggedInDocument,
      data: {
        isLoggedIn: !!user,
      },
    });
  })
  .catch(() => {
    client.writeQuery({
      query: IsUserLoggedInDocument,
      data: {
        isLoggedIn: false,
      },
    });
  });
