import React, { forwardRef, TextareaHTMLAttributes, useMemo } from "react";
import { useFormContext } from "react-hook-form";

type InputSize = "xs" | "sm" | "md" | "lg" | "xl";

export type TextareaUIProps = {
  registerName: `${string}` | `${string}.${string}` | `${string}.${number}`;
  validatable?: boolean;
  label?: string;
  inputSize?: InputSize;
  color?:
    | "primary"
    | "secondary"
    | "info"
    | "accent"
    | "success"
    | "warning"
    | "error"
    | "default";
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

export const TextareaUI = forwardRef<HTMLTextAreaElement, TextareaUIProps>(
  (
    {
      className = "",
      registerName,
      label = "",
      inputSize = "md",
      color = "default",
      children,
      ...rest
    },
    ref
  ) => {
    const {
      register,
      formState: { errors },
    } = useFormContext();
    const error = errors[registerName];

    const getColor = useMemo(
      () =>
        ({
          primary: "textarea-primary",
          secondary: "textarea-secondary",
          info: "textarea-info",
          accent: "textarea-accent",
          success: "textarea-success",
          warning: "textarea-warning",
          error: "textarea-error",
          default: "",
        }[color]),
      [color]
    );

    const getSize = useMemo(
      () =>
        ({
          xs: "textarea-xs",
          sm: "textarea-sm",
          md: "textarea-md",
          lg: "textarea-lg",
          xl: "textarea-xl",
        }[inputSize]),
      [inputSize]
    );

    return (
      <div className="form-control w-full">
        {label && (
          <label className="label">
            <span className="label-text">{label}</span>
          </label>
        )}
        <textarea
          {...register(registerName)}
          ref={ref}
          placeholder="Type here"
          className={`textarea textarea-bordered w-full ${className} ${getSize} ${
            error ? "textarea-error" : getColor
          }`}
          {...rest}
        />
        {error && (
          <label className="label">
            <span className="label-text-alt text-error">
              {`${error.message || ""}`}
            </span>
          </label>
        )}
      </div>
    );
  }
);
