import React, { FC } from "react";
import { EmailSearchInput } from "../../../../../generated/graphql";
import { FormGroupUI } from "../../../../../components/FormGroup";
import { FormGroupLabelUI } from "../../../../../components/FormGroupLabel";
import {
  INITIAL_MATTER_FILTER,
  limitedDaysOptions,
  matterOrderTypeOptions,
} from "../../../../../constants/searchFiilter";
import { FromToRangeUI } from "../../../../../components/rhfs/FromToRange";
import { ToggleUI } from "../../../../../components/rhfs/Toggle";
import { ReactSelectUI } from "../../../../../components/rhfs/ReactSelect";
import { ReactCreatableMultiSelectUI } from "../../../../../components/rhfs/ReactCreatableMultiSelect";

export type ChangeEmailSearchInput = (searchInput: EmailSearchInput) => void;
export type ChangeIsMatterHandler = (isMatter: boolean) => void;

export const MatterTypeFormUI: FC = () => {
  return (
    <div className="flex flex-row w-full">
      {/* 左側 */}
      <div className="flex flex-col grow">
        <FormGroupUI className="mb-10">
          <FormGroupLabelUI>期間</FormGroupLabelUI>
          <ReactSelectUI
            registerName="limitedByDay"
            className="w-40"
            options={limitedDaysOptions}
            isSetDefault={true}
          />
        </FormGroupUI>
        {/* 単価 */}
        <FormGroupUI className="mb-5">
          <FormGroupLabelUI>単価</FormGroupLabelUI>
          <FromToRangeUI
            testLabel="matter-reward"
            registerFrom="matterFilter.rewardRange.from"
            registerTo="matterFilter.rewardRange.to"
            min={INITIAL_MATTER_FILTER.rewardRange!.from!}
            max={INITIAL_MATTER_FILTER.rewardRange!.to!}
            step={5}
            unitName="万円"
            rangeSize="xs"
          />
        </FormGroupUI>
      </div>
      <div className="divider divider-horizontal"></div>
      {/* 中央 */}
      <div className="flex flex-col grow">
        <FormGroupUI className="mb-10">
          <FormGroupLabelUI>並び順</FormGroupLabelUI>
          <ReactSelectUI
            registerName="orderType"
            className="w-40"
            options={matterOrderTypeOptions}
            isSetDefault={true}
          />
        </FormGroupUI>
        <FormGroupUI className="mb-5">
          <FormGroupLabelUI>フリーワード</FormGroupLabelUI>
          <ReactCreatableMultiSelectUI
            registerName="freeWords"
            className="w-full max-w-xs"
          />
        </FormGroupUI>
      </div>
      <div className="divider divider-horizontal"></div>
      <div className="flex flex-col grow-0 w-60 space-y-2.5">
        <ToggleUI
          color="primary"
          label="面談1回"
          registerName="matterFilter.oneOpportunity"
        />
        <ToggleUI
          color="primary"
          label="外国籍OK"
          registerName="matterFilter.otherCountriesEnabled"
        />
        <ToggleUI
          color="primary"
          label="個人事業主不可を除く"
          registerName="matterFilter.onlyEnableFreelance"
        />
        <ToggleUI
          color="primary"
          label="テレワークOK"
          registerName="matterFilter.enableRemote"
        />
      </div>
    </div>
  );
};
