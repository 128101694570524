import React, { useState, useCallback, MouseEventHandler } from "react";

import {
  CreateCompanyInput,
  useCreateCompanyMutation,
} from "../../../generated/graphql";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CompanyNewConfirmPage from "./CompanyNewConfirmPage";
import { CreateCompanyModify } from "../CompanyNewPage";
import { toInteger } from "../../../utils/textUtils";
import { toastPromise } from "../../../utils/toastUtils";

const CompanyNewConfirmPageContainer: React.VFC = () => {
  const { modifiedInput } = useLocation<{
    modifiedInput: CreateCompanyModify;
  }>().state;
  const [createCompanyModify] = useState<CreateCompanyModify>(modifiedInput);
  const history = useHistory();
  const [createCompany, { loading, error }] = useCreateCompanyMutation({
    onCompleted: () => {
      // do nothings
    },
  });

  const onCreateCompany: MouseEventHandler = useCallback(
    async event => {
      event.preventDefault();
      delete modifiedInput.connecterName;
      const input: CreateCompanyInput = {
        ...modifiedInput,
        yearOfEstablishment: toInteger(modifiedInput.yearOfEstablishment!),
        capital: toInteger(modifiedInput.capital!),
        numberOfEmployees: toInteger(modifiedInput.numberOfEmployees!),
        numberOfEngineers: toInteger(modifiedInput.numberOfEngineers!),
        numberOfFreelancers: toInteger(modifiedInput.numberOfFreelancers!),
        numberOfBusinessPartners: toInteger(
          modifiedInput.numberOfBusinessPartners!
        ),
        numberOfBPEngineers: toInteger(modifiedInput.numberOfBPEngineers!),
        numberOfSales: toInteger(modifiedInput.numberOfSales!),
        connecterId: modifiedInput.connecterId,
      };
      await toastPromise(
        createCompany({
          variables: {
            input,
          },
        }),
        {
          pending: "処理中...",
          success: "会社の登録に成功しました",
          error: "会社の登録に失敗しました",
        }
      );
      history.push("/companies");
    },
    [history, modifiedInput, createCompany]
  );

  if (error) {
    return <>{`Submission error! ${error.message}`}</>;
  }

  return (
    <CompanyNewConfirmPage
      isLoading={loading}
      createCompanyModify={createCompanyModify}
      onCreate={onCreateCompany}
    />
  );
};

export default CompanyNewConfirmPageContainer;
