import React, { InputHTMLAttributes, FC } from "react";
import { Controller, Control } from "react-hook-form";
import { Switch } from "@headlessui/react";

export type ValidatableSwitchUIProps = {
  title: string;
  registerName: string;
  // TODO: 直す
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, object>;
  rules: object;
  defaultChecked?: boolean;
  onCheckChange?: (checked: boolean, value: string) => void;
} & InputHTMLAttributes<HTMLInputElement>;

export const ValidatableSwitchUI: FC<ValidatableSwitchUIProps> = ({
  title,
  registerName,
  control,
  rules,
  defaultChecked = false,
}) => {
  return (
    <Controller
      name={registerName}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Switch
          checked={field.value}
          defaultChecked={defaultChecked}
          className={`${
            field.value ? "bg-blue-600" : "bg-gray-200"
          } relative inline-flex h-6 w-11 items-center rounded-full`}
          {...field}
        >
          <span className="sr-only">{title}</span>
          <span
            className={`${
              field.value ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-white`}
          />
        </Switch>
      )}
    />
  );
};
