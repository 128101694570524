import { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";
import React, { FC, useCallback } from "react";
import { EmailWithFavorite, SelectContextMenuHandler } from "../..";
import { BadgeUI } from "../../../../../components/Badge";
import { EmailReactTable } from "../../../../../components/EmailReactTable";
import { HYPHEN } from "../../../../../constants/searchTable";
import { formatToManYen, omitText } from "../../../../../utils/textUtils";
import { FreeWordHighlighter } from "../FreeWordHighlighter";
import { ReactComponent as StarIcon } from "../star.svg";

export type MatterEmailsTableUIProps = {
  emails: EmailWithFavorite[];
  onSelectEmail: (id: string) => void;
  onToggleFavoriteEmail: (emailId: string, favorite: boolean) => void;
  onSelectContextMenu: SelectContextMenuHandler;
  freeWords: string[];
};

export type MatterRow = {
  star: JSX.Element;
  from: JSX.Element;
  dateTime: string;
  station: JSX.Element;
  isRemoteEnabled: JSX.Element;
  period: JSX.Element;
  reward: string;
  workingTimeWidth: JSX.Element;
  otherCountriesEnabled: JSX.Element;
  age: string;
  numberOfInterviews: string;
  commercialDistributionLimitation: JSX.Element;
  endOrPrimeContractorDirect: JSX.Element;
};

export const MatterEmailsTableUI: FC<MatterEmailsTableUIProps> = ({
  emails,
  onSelectEmail,
  onSelectContextMenu,
  onToggleFavoriteEmail,
  freeWords,
}) => {
  const columns: ColumnDef<MatterRow, any>[] = [
    {
      header: "emailId",
      accessorKey: "emailId",
    },
    {
      accessorKey: "star",
      header: "☆",
      cell: props => props.getValue(),
    },
    {
      accessorKey: "from",
      header: "From",
      cell: props => props.getValue(),
    },
    {
      accessorKey: "dateTime",
      header: "日付",
    },
    {
      accessorKey: "station",
      header: "現場駅",
      cell: props => props.getValue(),
    },
    {
      accessorKey: "isRemoteEnabled",
      header: "リモート",
      cell: props => props.getValue(),
    },
    {
      accessorKey: "period",
      header: "期間",
      cell: props => props.getValue(),
    },
    {
      accessorKey: "reward",
      header: "金額",
    },
    {
      accessorKey: "workingTimeWidth",
      header: "精算幅、固定",
      cell: props => props.getValue(),
    },
    {
      accessorKey: "otherCountriesEnabled",
      header: "外国籍ＯＫ",
      cell: props => props.getValue(),
    },
    {
      accessorKey: "age",
      header: "年齢制限",
    },
    {
      accessorKey: "numberOfInterviews",
      header: "面談回数",
    },
    {
      accessorKey: "commercialDistributionLimitation",
      header: "商流制限",
      cell: props => props.getValue(),
    },
    {
      accessorKey: "endOrPrimeContractorDirect",
      header: "元請直/エンド直",
      cell: props => props.getValue(),
    },
  ];

  const data: MatterRow[] = emails.map((email: EmailWithFavorite) => ({
    emailId: email.id,
    // お気に入りボタン
    star: (
      <StarIcon
        className={`${
          email.favorite ? "text-yellow-300" : "text-gray-200"
        } w-5 h-5`}
        onClick={e => {
          onToggleFavoriteEmail(email.id, !email.favorite);
          e.stopPropagation();
        }}
      />
    ),
    // 差し出し元
    from: (
      <div className="flex flex-col text-nano">
        <span>{omitText(email.from.name, 12)}</span>
        <span>{omitText(email.from.address, 28)}</span>
      </div>
    ),
    // 日付
    dateTime:
      dayjs().diff(email.date, "day") === 0
        ? dayjs(email.date).format("HH:mm")
        : dayjs(email.date).format("MM/DD HH:mm"),
    // 現場駅
    station: (
      <FreeWordHighlighter
        type="Table"
        freeWords={freeWords}
        textToHighlight={omitText(email?.matterColumn?.station)}
      />
    ),
    // リモート
    isRemoteEnabled: email?.matterColumn?.isRemoteEnabled ? (
      <BadgeUI color="success" className="text-nano" isOutline={true}>
        可
      </BadgeUI>
    ) : (
      <BadgeUI color="error" className="text-nano" isOutline={true}>
        不可
      </BadgeUI>
    ),
    // 期間
    period: (
      <FreeWordHighlighter
        type="Table"
        freeWords={freeWords}
        textToHighlight={omitText(email?.matterColumn?.period)}
      />
    ),
    // 金額
    reward: omitText(email?.matterColumn?.reward),
    // 精算幅、固定
    workingTimeWidth: (
      <FreeWordHighlighter
        type="Table"
        freeWords={freeWords}
        textToHighlight={omitText(email?.matterColumn?.workingTimeWidth)}
      />
    ),
    // 外国籍ＯＫ
    otherCountriesEnabled: email?.matterColumn?.otherCountriesEnabled ? (
      <BadgeUI color="success" className="text-nano" isOutline={true}>
        可
      </BadgeUI>
    ) : (
      <BadgeUI color="error" className="text-nano" isOutline={true}>
        不可
      </BadgeUI>
    ),
    // 年齢
    age: omitText(email?.hrColumn?.age),
    // 面談回数
    numberOfInterviews: omitText(email?.matterColumn?.numberOfInterviews),
    // 商流制限
    commercialDistributionLimitation: (
      <FreeWordHighlighter
        type="Table"
        freeWords={freeWords}
        textToHighlight={omitText(
          email?.matterColumn?.commercialDistributionLimitation
        )}
      />
    ),
    // 元請直/エンド直
    endOrPrimeContractorDirect: (
      <FreeWordHighlighter
        type="Table"
        freeWords={freeWords}
        textToHighlight={omitText(
          email?.matterColumn?.endOrPrimeContractorDirect
        )}
      />
    ),
  }));

  const endOrPrimeContractorDirectText = useCallback(
    (endOrPrimeContractorDirect: string | null) => {
      if (!endOrPrimeContractorDirect) {
        return HYPHEN;
      }
      if (endOrPrimeContractorDirect === "direct") {
        return "エンド直";
      } else if (endOrPrimeContractorDirect === "others") {
        return "元請け以下";
      }
      return HYPHEN;
    },
    []
  );

  const numberOfInterviewsText = useCallback(
    (numberOfInterviews: string | null) => {
      if (!numberOfInterviews) {
        return HYPHEN;
      }
      if (isNaN(Number(numberOfInterviews))) {
        return HYPHEN;
      }
      return `${numberOfInterviews}回`;
    },
    []
  );
  // version
  const newData: MatterRow[] = emails.map((email: EmailWithFavorite) => ({
    emailId: email.id,
    // お気に入りボタン
    star: (
      <StarIcon
        className={`${
          email.favorite ? "text-yellow-300" : "text-gray-200"
        } w-5 h-5`}
        onClick={e => {
          onToggleFavoriteEmail(email.id, !email.favorite);
          e.stopPropagation();
        }}
      />
    ),
    // 差し出し元
    from: (
      <div className="flex flex-col text-nano">
        <span>{omitText(email.from.name, 12)}</span>
        <span>{omitText(email.from.address, 28)}</span>
      </div>
    ),
    // 日付
    dateTime:
      dayjs().diff(email.date, "day") === 0
        ? dayjs(email.date).format("HH:mm")
        : dayjs(email.date).format("MM/DD HH:mm"),
    // 現場駅
    station: (
      <FreeWordHighlighter
        type="Table"
        freeWords={freeWords}
        textToHighlight={omitText(email?.matterColumn?.station)}
      />
    ),
    // リモート
    isRemoteEnabled: email?.matterColumn?.isRemoteEnabled ? (
      <BadgeUI color="success" className="text-nano" isOutline={true}>
        可
      </BadgeUI>
    ) : (
      <BadgeUI color="error" className="text-nano" isOutline={true}>
        不可
      </BadgeUI>
    ),
    // 期間
    period: (
      <FreeWordHighlighter
        type="Table"
        freeWords={freeWords}
        textToHighlight={omitText(email?.matterColumn?.period)}
      />
    ),
    // 金額
    reward: formatToManYen(email?.matterColumn?.reward),
    // 精算幅、固定
    workingTimeWidth: (
      <FreeWordHighlighter
        type="Table"
        freeWords={freeWords}
        textToHighlight={omitText(email?.matterColumn?.workingTimeWidth)}
      />
    ),
    // 外国籍ＯＫ
    otherCountriesEnabled: email?.matterColumn?.otherCountriesEnabled ? (
      <BadgeUI color="success" className="text-nano" isOutline={true}>
        可
      </BadgeUI>
    ) : (
      <BadgeUI color="error" className="text-nano" isOutline={true}>
        不可
      </BadgeUI>
    ),
    // 年齢
    age: email?.hrColumn?.age ? `${email?.hrColumn?.age}歳` : HYPHEN,
    // 面談回数
    numberOfInterviews: numberOfInterviewsText(
      email?.matterColumn?.numberOfInterviews || null
    ),
    // 商流制限
    commercialDistributionLimitation: (
      <FreeWordHighlighter
        type="Table"
        freeWords={freeWords}
        textToHighlight={omitText(
          email?.matterColumn?.commercialDistributionLimitation
        )}
      />
    ),
    // 元請直/エンド直
    endOrPrimeContractorDirect: (
      <FreeWordHighlighter
        type="Table"
        freeWords={freeWords}
        textToHighlight={omitText(
          endOrPrimeContractorDirectText(
            email?.matterColumn?.endOrPrimeContractorDirect || null
          )
        )}
      />
    ),
  }));
  return (
    <EmailReactTable
      data={process.env.REACT_APP_ENV === "prd" ? data : newData}
      columns={columns}
      onSelectEmail={onSelectEmail}
      onSelectContextMenu={onSelectContextMenu}
    />
  );
};
