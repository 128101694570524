import React, { useEffect } from "react";
import { CreateCompanyInput } from "../../../generated/graphql";
import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { PageTitle } from "../../../components/PageTitle";
import { CompanyEditUI } from "../../../components/CompanyEdit";
import { OptionItem } from "../../../types/common";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "ホーム",
  },
  {
    label: "会社",
  },
  {
    label: "会社作成",
  },
];

type CompanyNewPageProps = {
  isLoading: boolean;
  selectItemUsers: OptionItem[];
  onCreateCompany: (companyInput: CreateCompanyInput) => void;
};

const CompanyNewPage: React.VFC<CompanyNewPageProps> = ({
  isLoading = false,
  selectItemUsers,
  onCreateCompany,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  return (
    <div className="container mx-auto px-4">
      <PageTitle>会社作成</PageTitle>
      <CompanyEditUI
        isNew={true}
        isLoading={isLoading}
        selectItemUsers={selectItemUsers}
        onSubmit={onCreateCompany}
      />
    </div>
  );
};

export default CompanyNewPage;
