import React, { FC, InputHTMLAttributes } from "react";
import { FieldError, UseFormRegisterReturn } from "react-hook-form";

export type ValidatableInputUIProps = {
  register: UseFormRegisterReturn;
  error?: FieldError;
} & InputHTMLAttributes<HTMLInputElement>;

export const ValidatableInputUI: FC<ValidatableInputUIProps> = ({
  className = "",
  register,
  error,
  children,
  ...rest
}) => {
  return (
    <div className="flex flex-col">
      <input
        className={`${className} h-10 leading-5 pl-4 tracking-wider border rounded`}
        {...register}
        {...rest}
      />
      {error && <span className="text-error text-xs">{error.message}</span>}
    </div>
  );
};
