import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from "react";

const ProfilePage = () => {

  const [familyName, setFamilyName] = useState("");
  const [givenName, setGivenName] = useState("");
  const [familyNameKana, setFamilyNameKana] = useState("");
  const [givenNameKana, setGivenNameKana] = useState("");
  const [errormessage, setErrorMessage] = useState(false);

  const setUserAttributes = async () => {
    const user = await Auth.currentAuthenticatedUser();
    setFamilyName(user.attributes["custom:family_name"]);
    setGivenName(user.attributes["custom:given_name"]);
    setFamilyNameKana(user.attributes["custom:familyNameKana"]);
    setGivenNameKana(user.attributes["custom:givenNameKana"]);
  };

  useEffect(() => {
    (async () => {
      await setUserAttributes();
    })();
  }, []);

  const onSubmitUpdateUserAttributes = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (familyName === "" || givenName === "" || familyNameKana === "" || givenNameKana === "") {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
      alert("保存しました。");
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        "custom:family_name": familyName,
        "custom:given_name": givenName,
        "custom:familyNameKana": familyNameKana,
        "custom:givenNameKana": givenNameKana
      });
    }
  }

  return (
    <div className="w-1/3 m-auto py-20">
      <h1 className="text-center text-6xl mb-14">プロフィール設定</h1>
      <form onSubmit={onSubmitUpdateUserAttributes}>
        <h2 className="mb-2 ml-2 text-xl">氏名</h2>
        <input value={familyName} onChange={(e) => (setFamilyName(e.target.value))} type="text" placeholder="姓" className="border w-1/3 h-12 rounded-xl border-gray-300 mb-6 pl-2.5 outline-none text-lg mr-20"/>
        <input value={givenName} onChange={(e) => (setGivenName(e.target.value))} type="text" placeholder="名" className="border w-1/3 h-12 rounded-xl border-gray-300 mb-6 pl-2.5 outline-none text-lg"/>
        <h2 className="mb-2 ml-2 text-xl">ふりがな</h2>
        <input value={familyNameKana} onChange={(e) => {(setFamilyNameKana(e.target.value))}} type="text" placeholder="せい" className="border w-1/3 h-12 rounded-xl border-gray-300 mb-5 pl-2.5 outline-none text-lg mr-20"/>
        <input value={givenNameKana} onChange={(e) => {(setGivenNameKana(e.target.value))}} type="text" placeholder="めい" className="border w-1/3 h-12 rounded-xl border-gray-300 mb-5 pl-2.5 outline-none text-lg"/>
        <p className={`text-sm text-red-500 w-full text-right pr-4 mb-8 ${errormessage === true ? "visible" : "invisible"}`}>氏名またはふりがなを入力してください。</p>
        <h2 className="mb-2 ml-2 text-xl">メールアドレス</h2>
        <p className="ml-2">shanbenjunjie@gmail.com</p>
        <button className="border border-gray-600 h-10 w-32 bg-gray-200 rounded-xl mt-14 ml-80 focus:outline-none hover:opacity-70">保存</button>
      </form>
    </div>
  )
}
export default ProfilePage;