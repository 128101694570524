import React, { MouseEventHandler, useEffect } from "react";
import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { FormGroupUI } from "../../../components/FormGroup";
import { FormGroupLabelUI } from "../../../components/FormGroupLabel";
import { ButtonUI } from "../../../components/Button";
import { LoadingUI } from "../../../components/Loading";
import { PageTitle } from "../../../components/PageTitle";
import { CreateCompanyModify } from "../CompanyNewPage";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "ホーム",
  },
  {
    label: "会社",
  },
  {
    label: "会社作成",
  },
  {
    label: "確認",
  },
];

type CompanyNewConfirmPageProps = {
  isLoading: boolean;
  createCompanyModify: CreateCompanyModify;
  onCreate: MouseEventHandler<HTMLButtonElement>;
};

const CompanyNewConfirmPage: React.VFC<CompanyNewConfirmPageProps> = ({
  isLoading,
  createCompanyModify,
  onCreate,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);
  return isLoading ? (
    <div className="w-full flex justify-center pt-20">
      <LoadingUI type="Grid" />
    </div>
  ) : (
    <div className="container mx-auto px-4">
      <PageTitle>会社作成確認</PageTitle>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>会社名</FormGroupLabelUI>
        {createCompanyModify.name}
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>担当者</FormGroupLabelUI>
        {createCompanyModify.connecterName}
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>説明</FormGroupLabelUI>
        <p className="whitespace-pre border border-primary w-48 p-4 rounded">
          {createCompanyModify.description}
        </p>
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>設立年</FormGroupLabelUI>
        {createCompanyModify.yearOfEstablishment}
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>資本金</FormGroupLabelUI>
        {createCompanyModify.capital}
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>従業員の数</FormGroupLabelUI>
        {createCompanyModify.numberOfEmployees}
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>エンジニアの数</FormGroupLabelUI>
        {createCompanyModify.numberOfEngineers}
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>フリーランスエンジニアの数</FormGroupLabelUI>
        {createCompanyModify.numberOfFreelancers}
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>ビジネスパートナーの数</FormGroupLabelUI>
        {createCompanyModify.numberOfBusinessPartners}
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>ビジネスパートナーのエンジニアの数</FormGroupLabelUI>
        {createCompanyModify.numberOfBPEngineers}
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>営業の数</FormGroupLabelUI>
        {createCompanyModify.numberOfSales}
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>配信用email</FormGroupLabelUI>
        {createCompanyModify.deliveryEmail}
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>郵便番号</FormGroupLabelUI>
        {createCompanyModify.zip}
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>都道府県</FormGroupLabelUI>
        {createCompanyModify.prefecture}
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>住所1</FormGroupLabelUI>
        {createCompanyModify.address1}
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>住所2</FormGroupLabelUI>
        {createCompanyModify.address2}
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>有効 / 無効</FormGroupLabelUI>
        {createCompanyModify.disabled ? "無効" : "有効"}
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>無効の理由</FormGroupLabelUI>
        {createCompanyModify.disabledReason
          ? createCompanyModify.disabledReason
          : "理由なし"}
      </FormGroupUI>
      <div className="flex justify-center w-96 mb-20 space-x-5">
        <ButtonUI size="xs" to="/company/new">
          戻る
        </ButtonUI>
        <ButtonUI size="xs" onClick={onCreate}>
          登録
        </ButtonUI>
      </div>
    </div>
  );
};

export default CompanyNewConfirmPage;
