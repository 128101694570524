import React, { useCallback, FC } from "react";
import { useHistory } from "react-router";

export type DropdownMenuItemUIProps = {
  to?: string;
} & React.LiHTMLAttributes<HTMLLIElement>;

export const DropdownMenuItemUI: FC<DropdownMenuItemUIProps> = ({
  to,
  onClick,
  children,
}) => {
  const history = useHistory();

  const onClickLinkHandler: React.MouseEventHandler<HTMLLIElement> =
    useCallback(
      event => {
        to && history.push(to);
        onClick && onClick(event);
      },
      [to, history, onClick]
    );

  return (
    <li
      className="hover:bg-light-blue pl-2 py-3 cursor-pointer"
      onClick={onClickLinkHandler}
    >
      {children}
    </li>
  );
};
