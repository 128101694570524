import { HYPHEN } from "../constants/searchTable";

export const toInteger = (value?: number) => {
  if (!value) {
    return null;
  }
  if (typeof value === "string") {
    return +value;
  }
  return value;
};

export const omitText = (text?: string | null, length = 10) => {
  if (!text) {
    return HYPHEN;
  }
  return text.length > length ? `${text.slice(0, length)}...` : text;
};

export const formatToManYen = (value?: string | number | null): string => {
  if (value == null) {
    return HYPHEN;
  }
  // 数値に変換できるかチェック
  const numValue = typeof value === "string" ? parseFloat(value) : value;

  // NaN（数値に変換できない）かどうかをチェック
  if (isNaN(numValue)) {
    return HYPHEN;
  }

  // 万円単位に変換して小数第1位まで表示
  return `${(numValue / 10000).toFixed(1)}万円`;
};
