import {
  MatterFilter,
  HumanResourceFilter,
  OrderType,
  EmailSearchInput,
  RecruitmentType,
} from "../generated/graphql";
import { ContextMenuStatus } from "../routes/emails/SearchEmailsPage";
import { OptionItem } from "../types/common";
import { range } from "../utils/arrayUtils";

export const INITIAL_MATTER_FILTER: MatterFilter = {
  rewardRange: {
    from: 0,
    to: 200,
  },
  oneOpportunity: false,
  otherCountriesEnabled: false,
  onlyEnableFreelance: false,
  enableRemote: false,
};
export const INITIAL_HR_FILTER: HumanResourceFilter = {
  rewardRange: {
    from: 0,
    to: 200,
  },
  ageRange: {
    from: 20,
    to: 100,
  },
  immediately: false,
  needRemote: false,
  hrTypes: {
    fullTimeEmployee: false,
    freerance: false,
    contractEmployee: false,
  },
  genderType: null,
  onlyJapanese: false,
};

export const SEARCH_LIMIT = 200;
export const SEARCH_OFFSET = 200;
export const INITIAL_LIMITED_DAY = 1;

export const DEFAULT_SEARCH_FILTER: EmailSearchInput = {
  recruitmentType: RecruitmentType.Matter,
  matterFilter: INITIAL_MATTER_FILTER,
  hrFilter: INITIAL_HR_FILTER,
  limitedByDay: INITIAL_LIMITED_DAY,
  orderType: OrderType.DateDesc,
};

export const DEFAULT_CONTEXT_MENU_STATUS: ContextMenuStatus = {
  id: "",
  visible: false,
  clientX: 0,
  clientY: 0,
};

export const limitedDaysOptions: OptionItem[] = range(1, 6).map(
  (index: number) =>
    index === 1
      ? {
          value: `${index}`,
          label: "本日まで",
        }
      : {
          value: `${index}`,
          label: `${index - 1}前日まで`,
        }
);

export const matterOrderTypeOptions: OptionItem[] = [
  { label: "日付昇順", value: OrderType.DateAsc },
  { label: "日付降順", value: OrderType.DateDesc },
  { label: "単価昇順", value: OrderType.RewardAsc },
  { label: "単価降順", value: OrderType.RewardDesc },
];

export const hrOrderTypeOptions: OptionItem[] = [
  { label: "日付昇順", value: OrderType.DateAsc },
  { label: "日付降順", value: OrderType.DateDesc },
  { label: "年齢昇順", value: OrderType.AgeAsc },
  { label: "年齢降順", value: OrderType.AgeDesc },
  { label: "単価昇順", value: OrderType.RewardAsc },
  { label: "単価降順", value: OrderType.RewardDesc },
];
