import React from "react";
import { SignUpInputUI } from "./components/SignUpInput";
import { SignUpConfirmUI } from "./components/SignUpConfirm";
import {
  ConfirmSignUpInput,
  ResendSignUpHandler,
  SignUpInput,
} from "../../hooks/authProvider/authProvider";
import { SubmitHandler } from "react-hook-form";
import { SignUpStatus } from "../../hooks/authProvider/useSignUpStatus";

type SignUpPageProps = {
  signUpInput: SignUpInput;
  signUpStatus: SignUpStatus;
  onSignUp: SubmitHandler<SignUpInput>;
  onConfirmSignUp: SubmitHandler<Omit<ConfirmSignUpInput, "email">>;
  onResendSignUp: ResendSignUpHandler;
};

export const SignUpPage = ({
  signUpStatus,
  onSignUp,
  onConfirmSignUp,
  onResendSignUp,
}: SignUpPageProps) => {
  return (
    <div className="w-1/3 m-auto py-20">
      <h1 className="text-center text-6xl mb-14 font-bold">Strix Search</h1>
      {signUpStatus.confirming ? (
        <SignUpConfirmUI
          onConfirmSignUp={onConfirmSignUp}
          onResendSignUp={onResendSignUp}
        />
      ) : (
        <SignUpInputUI onSignUp={onSignUp} />
      )}
    </div>
  );
};
