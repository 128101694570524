import { SignUpStatus } from "../hooks/authProvider/useSignUpStatus";
/**
 * ローカルストレージは基本的に利用したくないが、
 * 個々のユーザーが特定のメールを気軽に非表示、表示またはお気に入り、お気に入り解除できるようにするために、DB等工数を抑えるために使用する。
 */

const STORAGE_KEY = "SignUpStatusKey";

/**
 * サインアップステータスを取得する
 * @return {SignUpStatus} サインアップステータス
 */
export const getSignUpStatus = (): SignUpStatus => {
  const data = localStorage.getItem(STORAGE_KEY);
  return data ? JSON.parse(data) : { email: "", confirming: false };
};

/**
 * サインアップステータスをローカルストレージに追加
 * @param {SignUpStatus} input
 */
export const setSignUpStatusToLocalStorage = (input: SignUpStatus): void => {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(input));
};

/**
 * ローカルストレージにセットされたサインアップステータスをクリアする
 */
export const resetSignUpStatus = (): void => {
  localStorage.removeItem(STORAGE_KEY);
};
