import React, { HTMLAttributes, FC } from "react";

export type FormGroupLabelUIProps = HTMLAttributes<HTMLElement>;

export const FormGroupLabelUI: FC<FormGroupLabelUIProps> = ({
  children,
  className,
}) => {
  return (
    <span className={`text-sm font-semibold w-24 mr-3 ${className}`}>
      {children}
    </span>
  );
};
