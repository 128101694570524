import React from "react";
import { useForm } from "react-hook-form";
import {
  CompaniesItemFragment,
  CreateCompanyInput,
} from "../../generated/graphql";
import { ValidatableInputUI } from "../../components/ValidatableInput";
import { FormGroupUI } from "../../components/FormGroup";
import { FormGroupLabelUI } from "../../components/FormGroupLabel";
import { ButtonUI } from "../../components/Button";
import { ValidatableCustomSelectUI } from "../../components/ValidatableCustomSelect";
import { ValidatableTextAreaUI } from "../../components/ValidatableTextArea";
import { OptionItem } from "../../types/common";
import { LoadingUI } from "../Loading";
import { ValidatableSwitchUI } from "../ValidatableSwitch";

type CompanyEditUIProps = {
  isNew: boolean;
  isLoading?: boolean;
  selectedCompany?: CompaniesItemFragment;
  selectItemUsers: OptionItem[];
  onSubmit: (companyInput: CreateCompanyInput) => void;
  onDelete?: (id: string) => void;
};

export const CompanyEditUI: React.VFC<CompanyEditUIProps> = ({
  isNew,
  isLoading = false,
  selectedCompany,
  selectItemUsers,
  onSubmit,
  onDelete,
}) => {
  const formOptions = {};
  if (!isNew) {
    const { connecter, __typename, ...company } = selectedCompany!;
    Object.assign(formOptions, { defaultValues: company });
  } else {
    Object.assign(formOptions, { defaultValues: { disabled: false } });
  }
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<CreateCompanyInput>(formOptions);
  const watchDisabled = watch("disabled", false);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>会社名</FormGroupLabelUI>
        <ValidatableInputUI
          register={register("name", {
            required: "会社名は入力必須項目です。",
          })}
          placeholder="会社名を入力してください"
          error={errors.name}
          className="w-96"
        />
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>担当者</FormGroupLabelUI>
        {isLoading && <LoadingUI type="Grid" width={20} height={20} />}
        <div className="w-full mb-10">
          <ValidatableCustomSelectUI
            registerName="connecterId"
            disabled={isLoading}
            className="w-6/12"
            rules={{
              validate: {
                myRequired: (v: string | undefined) => {
                  return v !== undefined || "必須項目です";
                },
              },
            }}
            defaultValue={
              selectItemUsers.find(
                (user: OptionItem) =>
                  user.value === selectedCompany?.connecterId
              )?.label
            }
            error={errors.connecterId}
            options={selectItemUsers}
            id="hr-ageRange-from"
            control={control}
          />
        </div>
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>説明</FormGroupLabelUI>
        <ValidatableTextAreaUI
          register={register("description", {})}
          placeholder="説明を入力してください"
          error={errors.description}
          rows={8}
          className="w-96 h-48"
        />
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>設立年</FormGroupLabelUI>
        <ValidatableInputUI
          type="number"
          register={register("yearOfEstablishment", {
            min: 0,
          })}
          placeholder="設立年を入力してください"
          error={errors.yearOfEstablishment}
          className="w-96"
        />
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>資本金</FormGroupLabelUI>
        <ValidatableInputUI
          type="number"
          register={register("capital", {
            min: 0,
          })}
          placeholder="資本金を入力してください"
          error={errors.capital}
          className="w-96"
        />
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>従業員の数</FormGroupLabelUI>
        <ValidatableInputUI
          type="number"
          register={register("numberOfEmployees", {
            min: 0,
          })}
          placeholder="従業員の数を入力してください"
          error={errors.numberOfEmployees}
          className="w-96"
        />
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>エンジニアの数</FormGroupLabelUI>
        <ValidatableInputUI
          type="number"
          register={register("numberOfEngineers", {
            min: 0,
          })}
          placeholder="エンジニアの数を入力してください"
          error={errors.numberOfEngineers}
          className="w-96"
        />
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>フリーランスエンジニアの数</FormGroupLabelUI>
        <ValidatableInputUI
          type="number"
          register={register("numberOfFreelancers", {
            min: 0,
          })}
          placeholder="フリーランスエンジニアの数を入力してください"
          error={errors.numberOfFreelancers}
          className="w-96"
        />
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>ビジネスパートナーの数</FormGroupLabelUI>
        <ValidatableInputUI
          type="number"
          register={register("numberOfBusinessPartners", {
            min: 0,
          })}
          placeholder="ビジネスパートナーの数を入力してください"
          error={errors.numberOfBusinessPartners}
          className="w-96"
        />
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>ビジネスパートナーのエンジニアの数</FormGroupLabelUI>
        <ValidatableInputUI
          type="number"
          register={register("numberOfBPEngineers", {
            min: 0,
          })}
          placeholder="ビジネスパートナーのエンジニアの数を入力してください"
          error={errors.numberOfBPEngineers}
          className="w-96"
        />
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>営業の数</FormGroupLabelUI>
        <ValidatableInputUI
          type="number"
          register={register("numberOfSales", {
            min: 0,
          })}
          placeholder="営業の数を入力してください"
          error={errors.numberOfSales}
          className="w-96"
        />
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>配信用email</FormGroupLabelUI>
        <ValidatableInputUI
          register={register("deliveryEmail", {
            pattern: {
              value:
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i,
              message: "emailの形式が不正です",
            },
          })}
          placeholder="配信用emailを入力してください"
          error={errors.deliveryEmail}
          className="w-96"
        />
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>郵便番号</FormGroupLabelUI>
        <ValidatableInputUI
          type="number"
          register={register("zip", {
            min: 0,
          })}
          placeholder="郵便番号を入力してください"
          error={errors.zip}
          className="w-96"
        />
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>都道府県</FormGroupLabelUI>
        <ValidatableInputUI
          register={register("prefecture", {})}
          placeholder="都道府県を入力してください"
          error={errors.prefecture}
          className="w-96"
        />
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>住所1</FormGroupLabelUI>
        <ValidatableInputUI
          register={register("address1", {})}
          placeholder="住所1を入力してください"
          error={errors.address1}
          className="w-96"
        />
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>住所2</FormGroupLabelUI>
        <ValidatableInputUI
          register={register("address2", {})}
          placeholder="住所2を入力してください"
          error={errors.address2}
          className="w-96"
        />
      </FormGroupUI>

      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>有効、無効</FormGroupLabelUI>
        <ValidatableSwitchUI
          title="有効 / 無効"
          registerName="disabled"
          control={control}
          rules={{}}
        />
      </FormGroupUI>

      {watchDisabled && (
        <FormGroupUI className="mb-5">
          <FormGroupLabelUI>非活性理由</FormGroupLabelUI>
          <ValidatableTextAreaUI
            register={register("disabledReason", {})}
            placeholder="説明を入力してください"
            error={errors.description}
            rows={8}
            className="w-96 h-48"
          />
        </FormGroupUI>
      )}

      <div className="flex justify-center w-96 mb-20 space-x-5">
        {isNew && (
          <ButtonUI size="xs" to="/companies">
            戻る
          </ButtonUI>
        )}
        <ButtonUI size="xs" type="submit">
          {isNew ? "登録" : "更新"}
        </ButtonUI>
        {!isNew && (
          <ButtonUI
            size="xs"
            type="button"
            onClick={() => {
              onDelete && onDelete(selectedCompany!.id);
            }}
          >
            削除
          </ButtonUI>
        )}
      </div>
    </form>
  );
};
