import React, { useState, FC } from "react";
import styles from "./ValidatableCustomSelect.module.scss";
import Select, { components, SingleValue, ActionMeta } from "react-select";
import { ReactComponent as ArrowDownIcon } from "./arrowDown.svg";
import { ReactComponent as ArrowUpIcon } from "./arrowUp.svg";
import { Controller, Control, FieldError } from "react-hook-form";
import { OptionItem } from "../../types/common";

export type ChangeValidatableCustomSelectHandler = (
  newValue: SingleValue<{ value: string; label: string }>,
  actionMeta: ActionMeta<{ value: string; label: string }>
) => void;

export type ValidatableCustomSelectUIProps = {
  registerName: string;
  options: OptionItem[];
  rules: object;
  error?: FieldError | undefined;
  placeholder?: string | number;
  id: string;
  className?: string;
  blackBorder?: boolean;
  errorBorder?: boolean;
  // TODO: 直す
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any;
  disabled?: boolean;
  // TODO: 直す
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, object>;
};
// TODO: 直す
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
    </components.DropdownIndicator>
  );
};

const customStylesOpen = {
  // TODO: 直す
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  option: (provided: any, state: any) => {
    let backgroundColor = "white";
    const color = "black";
    if (state.isSelected || state.isFocused) {
      backgroundColor = "#E9F7FF";
    }
    return {
      ...provided,
      padding: 10,
      backgroundColor,
      color,
      ":active": {
        backgroundColor: "#E9F7FF",
      },
      fontWeight: "normal",
    };
  },
  control: () => ({
    width: "100%",
    display: "flex",
    border: "1px solid #32295e",
    borderRadius: "4px",
    borderBottomLeftRadius: "0",
    borderBottomRightRadius: "0",
    borderBottom: "none",
    height: "2.5rem",
    background: "white",
    fontSize: "13px",
    fontFamily: "initial",
  }),
  container: () => ({
    position: "relative",
    width: "100%",
  }),
  menu: () => ({
    border: "1px solid #32295e",
    borderTop: "none",
    width: "100%",
    background: "white",
    zIndex: "999",
    fontSize: "13px",
    fontFamily: "initial",
  }),
  // TODO: 直す
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  singleValue: (provided: any, state: { isDisabled: any }) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

export const ValidatableCustomSelectUI: FC<ValidatableCustomSelectUIProps> = ({
  options,
  id,
  registerName,
  rules,
  error,
  placeholder = "選択項目",
  defaultValue,
  className,
  control,
  blackBorder = false,
  errorBorder = false,
  disabled = false,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const customStyles = {
    // TODO: 直す
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    option: (provided: any, state: any) => {
      let backgroundColor = "white";
      const color = "black";
      if (state.isSelected || state.isFocused || state.isActive) {
        backgroundColor = "#E9F7FF";
      }
      return {
        ...provided,
        padding: 10,
        backgroundColor,
        color,
        ":active": {
          backgroundColor: "#E9F7FF",
        },
      };
    },
    control: () => ({
      width: "100%",
      display: "flex",
      border: `1px solid ${
        blackBorder ? "#000" : errorBorder ? "#F0371C" : "#e5e7eb"
      }`,
      borderRadius: "4px",
      height: "2.5rem",
      background: "white",
      fontSize: "13px",
      fontFamily: "initial",
    }),
    menu: () => ({
      border: "1px solid #32295e",
      borderTop: "none",
      background: "white",
      fontSize: "13px",
      fontFamily: "initial",
    }),
    singleValue: (
      // TODO: 直す
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      provided: any,
      // TODO: 直す
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      state: { isDisabled: any; selectProps: any }
    ) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      return { ...provided, opacity, transition };
    },
  };

  return (
    <div className={`${styles.wrap} ${className}`}>
      <div
        className={`${className} ${
          isOpen ? styles.wrapBodyActive : styles.wrapBody
        }`}
      >
        <Controller
          name={registerName}
          control={control}
          rules={rules}
          render={({ field }) => (
            <Select
              {...field}
              value={field.value ? field.value.value : null}
              options={options}
              styles={isOpen ? customStylesOpen : customStyles}
              isDisabled={disabled}
              isSearchable={true}
              onMenuOpen={() => {
                setIsOpen(true);
              }}
              onMenuClose={() => {
                setIsOpen(false);
              }}
              components={{
                DropdownIndicator,
                IndicatorSeparator: () => null,
              }}
              defaultInputValue={defaultValue}
              placeholder={placeholder}
              onChange={(item: OptionItem) => {
                return field.onChange(item.value);
              }}
            />
          )}
        />
      </div>
      {error && (
        <>
          <div className="h-9"></div>
          <span className="text-error text-xs">{error.message}</span>
        </>
      )}
    </div>
  );
};
