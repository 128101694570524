import React from "react";
import { CompaniesItemFragment } from "../../../../generated/graphql";

export type CompaniesItemUIProps = {
  company: CompaniesItemFragment;
  selected: boolean;
  onSelectCompany: (id: string) => void;
};

export const CompaniesItemUI: React.VFC<CompaniesItemUIProps> = ({
  company,
  selected = false,
  onSelectCompany,
}) => {
  return (
    <tr
      className={`py-2.5 pl-3 whitespace-nowrap hover:bg-blue-100 ${
        selected ? "bg-yellow-200" : ""
      }`}
      onClick={() => {
        onSelectCompany(company.id);
      }}
    >
      <td className="px-3 text-dark-gray">{company.name}</td>
      <td className="px-3 text-dark-gray">{company.yearOfEstablishment}年</td>
      <td className="px-3 text-dark-gray">{`〒${company.zip} ${company.prefecture}${company.address1}${company.address2}`}</td>
      <td className="px-3 text-dark-gray">{`${company.connecter.familyName} ${company.connecter.givenName}`}</td>
    </tr>
  );
};
