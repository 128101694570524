import React, { MouseEvent, useCallback, useEffect, useState } from "react";
import DeliveryGroupNewPage from "./DeliveryGroupNewPage";
import {
  CreateDeliveryGroupInput,
  useDeliveryGroupNewPageQuery,
  SelectItemUserFragment,
  SelectItemEmployeeFragment,
  CreateDeliveryEmployeeInput,
} from "../../../generated/graphql";
import { useHistory } from "react-router-dom";
import { OptionItem } from "../../../types/common";

export type CreateDeliveryGroupModify = {
  fromUserName: string;
  deliveryEmployeesModify: CreateDeliveryEmployeeModify[];
} & Omit<CreateDeliveryGroupInput, "toEmployees">;

/** 配信グループの宛先作成 */
export type CreateDeliveryEmployeeModify = {
  id: string;
  name: string;
  email: string;
} & CreateDeliveryEmployeeInput;

const GroupNewPageContainer: React.VFC = () => {
  const history = useHistory();
  const [createDeliveryGroupModify, setCreateDeliveryGroupModify] =
    useState<CreateDeliveryGroupModify>({
      name: "",
      description: "",
      fromUserId: "",
      fromUserName: "",
      deliveryEmployeesModify: [
        {
          id: "",
          name: "",
          email: "",
          employeeId: "",
          disableReason: "",
          delivered: true,
          disabled: false,
        },
      ],
    });
  const [selectItemUsers, setSelectItemUsers] = useState<OptionItem[]>([]);
  const [selectItemEmployees, setSelectItemEmployees] = useState<OptionItem[]>(
    []
  );

  const { error, data, loading } = useDeliveryGroupNewPageQuery();
  useEffect(() => {
    if (data?.users) {
      setSelectItemUsers(
        data.users.nodes.map((user: SelectItemUserFragment) => ({
          value: user.id,
          label: `${user.familyName} ${user.givenName}(${user.userId})`,
        }))
      );
    }
    if (data?.employees) {
      setSelectItemEmployees(
        data.employees.nodes.map((employee: SelectItemEmployeeFragment) => ({
          value: employee.id,
          label: `${employee.name}(${employee.email})`,
        }))
      );
    }
  }, [data]);
  const onCreateDeliveryGroup = useCallback(
    (validatedInput: CreateDeliveryGroupModify) => {
      const selectedUser = selectItemUsers.find(
        (user: OptionItem) => user.value === validatedInput.fromUserId
      );
      const modifiedInput: CreateDeliveryGroupModify = {
        ...validatedInput,
        fromUserName: selectedUser?.label || "",
        deliveryEmployeesModify: validatedInput.deliveryEmployeesModify.map(
          (employeeModify: CreateDeliveryEmployeeModify) => ({
            ...employeeModify,
            id: employeeModify.id,
            name: selectItemEmployees.find(
              (item: OptionItem) =>
                item.value === `${employeeModify.employeeId}`
            )!.label,
          })
        ),
      };
      history.push("/group/new/confirm", {
        modifiedInput,
      });
    },
    [history, selectItemUsers, selectItemEmployees]
  );

  const onAddedToEmployee = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      setCreateDeliveryGroupModify({
        ...createDeliveryGroupModify,
      });
    },
    [createDeliveryGroupModify]
  );
  if (error) {
    return <div>{JSON.stringify(error, null, 2)}</div>;
  }
  return (
    <DeliveryGroupNewPage
      isLoading={loading}
      createDeliveryGroupModify={createDeliveryGroupModify}
      selectItemUsers={selectItemUsers}
      selectItemEmployees={selectItemEmployees}
      onCreateDeliveryGroup={onCreateDeliveryGroup}
      onAddedToEmployee={onAddedToEmployee}
    />
  );
};

export default GroupNewPageContainer;
