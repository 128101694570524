import React, { useEffect } from "react";
import { CreateEmployeeInput } from "../../../generated/graphql";
import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { PageTitle } from "../../../components/PageTitle";
import { EmployeeEditUI } from "../../../components/EmployeeEdit";
import { OptionItem } from "../../../types/common";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "ホーム",
  },
  {
    label: "会社員",
  },
  {
    label: "会社員作成",
  },
];

type EmployeeNewPageProps = {
  isLoading: boolean;
  selectItemCompanies: OptionItem[];
  onCreateEmployee: (employeeInput: CreateEmployeeInput) => void;
};

const EmployeeNewPage: React.VFC<EmployeeNewPageProps> = ({
  isLoading = false,
  selectItemCompanies,
  onCreateEmployee,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  return (
    <div className="container mx-auto px-4">
      <PageTitle>会社員作成</PageTitle>
      <EmployeeEditUI
        isNew={true}
        isLoading={isLoading}
        selectItemCompanies={selectItemCompanies}
        onSubmit={onCreateEmployee}
      />
    </div>
  );
};

export default EmployeeNewPage;
