import React from "react";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import {
  ConfirmSignUpInput,
  ResendSignUpHandler,
} from "../../../../hooks/authProvider/authProvider";
import { InputUI } from "../../../../components/rhfs/Input";
import { ButtonUI } from "../../../../components/Button";

type SignUpConfirmUIProps = {
  onConfirmSignUp: SubmitHandler<Omit<ConfirmSignUpInput, "email">>;
  onResendSignUp: ResendSignUpHandler;
};

export const SignUpConfirmUI: React.VFC<SignUpConfirmUIProps> = ({
  onConfirmSignUp,
  onResendSignUp,
}) => {
  const schema = Joi.object({
    authCode: Joi.number().integer().required().messages({
      "number.base": "6桁の整数を入力してください。",
      "number.unsafe": "適切な数値を入力してください。",
    }),
  });
  const methods = useForm<Omit<ConfirmSignUpInput, "email">>({
    resolver: joiResolver(schema),
  });
  return (
    <div className="mt-28 mx-auto">
      <h2 className="text-sm leading-9 text-center mb-6">
        認証コードを登録されたメールアドレスへ送信しました。
        <br />
        メールを確認し認証コードを入力してください。
      </h2>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onConfirmSignUp)}>
          <InputUI
            type="number"
            registerName="authCode"
            placeholder="認証コードを入力してください"
          />
          <div className="flex flex-col items-center space-y-12 mt-12">
            <ButtonUI size="lg" type="submit">
              送信
            </ButtonUI>
            <ButtonUI
              size="lg"
              type="button"
              color="secondary"
              className=""
              onClick={e => {
                e.preventDefault();
                onResendSignUp();
              }}
            >
              認証コードを再送する
            </ButtonUI>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
