import React from "react";
import { Redirect } from "react-router-dom";
// import { useQueryParams } from "../hooks/query";

const RedirectPage = () => {
  // const params = useQueryParams();
  return <Redirect to="/" />;
};

export default RedirectPage;
