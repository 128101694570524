import type { FC, MouseEventHandler } from "react";
import React, {
  FormEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import {
  DeliveryHistoriesFragment,
  DeliveryHistoriesItemFragment,
  DeliveryHistorySearchInput,
  UpdateDeliveryEmployeeInput,
  useDeliveryHistoriesPageQuery,
} from "../../../generated/graphql";
import DeliveryHistoriesPage from "./DeliveryHistoriesPage";

export type UpdateDeliveryEmployeeModify = {
  id: string;
  name: string;
  email: string;
} & UpdateDeliveryEmployeeInput;

const DeliveryHistoriesPageContainer: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [searchInput, setSearchInput] = useState<DeliveryHistorySearchInput>();
  const { error, data, refetch } = useDeliveryHistoriesPageQuery({
    onCompleted: async () => {
      setIsLoading(false);
    },
  });

  const [deliveryHistories, setDeliveryHistories] =
    useState<DeliveryHistoriesFragment>({
      totalCount: 0,
      nodes: [],
    });

  useEffect(() => {
    let isMounted = true;
    if (isMounted && data) {
      setDeliveryHistories(data.deliveryHistories);
    }
    return () => {
      isMounted = false;
    };
  }, [data]);

  const onResend = useCallback(
    (selectedHistory: DeliveryHistoriesItemFragment) => {
      const {
        from,
        subject,
        content,
        deliveryType: category,
        hrHash,
      } = selectedHistory;
      history.push("/emails/bulk/confirm", {
        state: {
          subject,
          content,
          from,
          category,
          hrHash,
        },
      });
    },
    [history]
  );

  const onSelectHistory = useCallback(
    (id: string) => {
      const deliveryHistory: DeliveryHistoriesItemFragment =
        deliveryHistories.nodes.find(
          (deliveryHistory: DeliveryHistoriesItemFragment) =>
            deliveryHistory.id === id
        )!;
      history.push("/email/new", {
        deliveryHistory,
      });
    },
    [history, deliveryHistories]
  );

  const onChangeSearchInput = useCallback(
    (newSearchInput: DeliveryHistorySearchInput) => {
      setSearchInput(newSearchInput);
    },
    []
  );

  const onSearch: FormEventHandler = useCallback(
    async event => {
      event.preventDefault();
      setIsLoading(true);
      await refetch({
        filter: searchInput,
        offset: 0,
        limit: 50,
      });
    },
    [searchInput, refetch]
  );

  const onClearFilter: MouseEventHandler = useCallback(
    async event => {
      event.preventDefault();
      setSearchInput({
        ...searchInput,
      });
    },
    [searchInput]
  );

  if (error) {
    return <div>{JSON.stringify(error, null, 2)}</div>;
  }
  return (
    <DeliveryHistoriesPage
      isLoading={isLoading}
      searchInput={searchInput!}
      deliveryHistories={deliveryHistories}
      onSelectHistory={onSelectHistory}
      onChangeSearchInput={onChangeSearchInput}
      onSearch={onSearch}
      onClearFilter={onClearFilter}
      onResend={onResend}
    />
  );
};

export default DeliveryHistoriesPageContainer;
