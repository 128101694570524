import type { FC, MouseEventHandler } from "react";
import React, { useEffect } from "react";
import { SubmitHandler } from "react-hook-form";
import {
  EmailWithFavorite,
  EmailsWithFavorite,
  SelectContextMenuHandler,
} from ".";
import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { LoadingUI } from "../../../components/Loading";
import { SEARCH_LIMIT } from "../../../constants/searchFiilter";
import { EmailSearchInput } from "../../../generated/graphql";
import { HREmailsTableUI } from "./components/HREmailsTable";
import { MatterEmailsTableUI } from "./components/MatterEmailsTable";
import { SearchEmailStats } from "./components/SearchEmailStats";
import { PageTitle } from "../../../components/PageTitle";
import {
  ChangeIsMatterHandler,
  SearchEmailsFormUI,
} from "./components/SearchEmailsForm";

type SearchEmailsPageProps = {
  isLoading: boolean;
  isMatter: boolean;
  currentSearchInput: EmailSearchInput;
  invisibleEmailNums: number;
  matterEmails: EmailsWithFavorite;
  hrEmails: EmailsWithFavorite;
  selectedEmail: EmailWithFavorite;
  onSelectEmail: (id: string) => void;
  onToggleFavoriteEmail: (emailId: string, favorite: boolean) => void;
  onSelectContextMenu: SelectContextMenuHandler;
  onSearch: SubmitHandler<EmailSearchInput>;
  onClearFilter: () => void;
  onChangeIsMatter: ChangeIsMatterHandler;
  onShowMore: MouseEventHandler;
};

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "ホーム",
  },
  {
    label: "メール",
  },
  {
    label: "検索",
  },
];

const SearchEmailsPage: FC<SearchEmailsPageProps> = ({
  isLoading,
  isMatter,
  currentSearchInput,
  matterEmails,
  hrEmails,
  invisibleEmailNums,
  onToggleFavoriteEmail,
  onSelectEmail,
  onSelectContextMenu,
  onSearch,
  onClearFilter,
  onChangeIsMatter,
  onShowMore,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  return (
    <div>
      <PageTitle>メール検索</PageTitle>
      <SearchEmailsFormUI
        isLoading={isLoading}
        currentSearchInput={currentSearchInput}
        isMatter={isMatter}
        onSearch={onSearch}
        onClearFilter={onClearFilter}
        onChangeIsMatter={onChangeIsMatter}
      ></SearchEmailsFormUI>

      {isLoading ? (
        <div className="w-full flex justify-center pt-20">
          <LoadingUI type="Grid" />
        </div>
      ) : (
        <div className="flex flex-col">
          {isMatter && matterEmails.nodes.length > 0 && (
            <div className="text-base-content glass xl:rounded-box p-1 my-5 max-w-2xl">
              <SearchEmailStats
                totalCount={matterEmails.totalCount}
                displayCount={matterEmails.nodes.length}
                invisibleCount={invisibleEmailNums}
                favoriteCount={
                  matterEmails.nodes.filter(
                    (node: EmailWithFavorite) => node.favorite
                  ).length
                }
              />
            </div>
          )}
          {!isMatter && hrEmails.nodes.length > 0 && (
            <div className="text-base-content glass xl:rounded-box p-1 my-5 max-w-2xl">
              <SearchEmailStats
                totalCount={hrEmails.totalCount}
                displayCount={hrEmails.nodes.length}
                invisibleCount={invisibleEmailNums}
                favoriteCount={
                  hrEmails.nodes.filter(
                    (node: EmailWithFavorite) => node.favorite
                  ).length
                }
              />
            </div>
          )}
          <div className="flex flex-row space-x-2">
            <div className="w-full">
              <div className="text-base-content glass xl:rounded-box p-2">
                <div className="shadow border-b border-gray-200 rounded-md overflow-scroll h-screen mb-5">
                  {isMatter ? (
                    <MatterEmailsTableUI
                      emails={matterEmails.nodes}
                      onSelectEmail={onSelectEmail}
                      onSelectContextMenu={onSelectContextMenu}
                      onToggleFavoriteEmail={onToggleFavoriteEmail}
                      freeWords={currentSearchInput.freeWords || []}
                    />
                  ) : (
                    <HREmailsTableUI
                      emails={hrEmails.nodes}
                      onSelectEmail={onSelectEmail}
                      onSelectContextMenu={onSelectContextMenu}
                      onToggleFavoriteEmail={onToggleFavoriteEmail}
                      freeWords={currentSearchInput.freeWords || []}
                    />
                  )}
                </div>
              </div>

              {(matterEmails.totalCount > SEARCH_LIMIT ||
                hrEmails.totalCount > SEARCH_LIMIT) && (
                <div className="flex justify-center space-x-5">
                  <ButtonUI className="w-40 mb-20" onClick={onShowMore}>
                    もっと見る
                  </ButtonUI>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default SearchEmailsPage;
