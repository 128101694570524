import dayjs from "dayjs";
import "dayjs/locale/ja";
import React, { FC, MouseEventHandler } from "react";
import ReactModal from "react-modal";
import { emailToNameMap } from "../../../../constants/emailSenders";
import { DeliverySchedulesItemFragment } from "../../../../generated/graphql";
import { ReactComponent as ClosedIcon } from "./closed.svg";
import { modalStyle } from "./DeliverySchedulesModalModalStyle";

type DeliverySchedulesModalProps = {
  visibility: boolean;
  selectedSchedule: DeliverySchedulesItemFragment;
  onClosed: MouseEventHandler;
};

dayjs.locale("ja");

ReactModal.setAppElement("#root");

export const DeliverySchedulesModal: FC<DeliverySchedulesModalProps> = ({
  visibility,
  selectedSchedule,
  onClosed,
}) => {
  const { from } = selectedSchedule;
  const senderName = emailToNameMap[from] || from;
  return (
    <ReactModal
      style={modalStyle}
      isOpen={visibility}
      onRequestClose={onClosed}
    >
      <div className="flex flex-col">
        <div className="flex justify-between bg-primary text-white py-2 px-2.5">
          <div className="flex flex-col">
            <h3 className="text-sm font-bold font-hiragino-sans-w7">
              {selectedSchedule.subject}
            </h3>
          </div>
          <ClosedIcon onClick={onClosed} className="w-7 h-7" />
        </div>
        <div className="p-4 overflow-y-scroll h-email-detail-content">
          <div className="flex flex-row justify-between mb-4">
            <div className="whitespace-pre-line">
              <table className="w-full text-xs leading-loose">
                <tbody>
                  <tr>
                    <td className="font-bold pr-6">人材HASH :</td>
                    <td>{selectedSchedule.hrHash}</td>
                  </tr>
                  <tr>
                    <td className="font-bold pr-6">配信予定日時 :</td>
                    <td>
                      {dayjs(selectedSchedule.scheduledAt).format(
                        "YYYY/MM/DD (ddd) HH:mm"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold pr-6">配信種別 :</td>
                    <td>{selectedSchedule.deliveryType}</td>
                  </tr>
                  <tr>
                    <td className="font-bold pr-6">配信予約者 :</td>
                    <td>{`${senderName} <${from}>`}</td>
                  </tr>
                  <tr className="border-b border-gray-300">
                    <td className="font-bold pr-6 pb-5">予約登録日時 :</td>
                    <td className="pb-5">
                      {dayjs(selectedSchedule.createdAt).format(
                        "YYYY/MM/DD (ddd) HH:mm"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold align-top pr-6 pt-5">本文 :</td>
                    <td className="whitespace-pre-line pt-5">
                      {selectedSchedule.content}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};
