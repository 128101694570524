import React, { InputHTMLAttributes, useCallback, FC } from "react";
import { useFormContext } from "react-hook-form";

type InputSize = "xs" | "sm" | "md" | "lg" | "xl";

export type InputUIProps = {
  registerName: `${string}` | `${string}.${string}` | `${string}.${number}`;
  validatable?: boolean;
  label?: string;
  inputSize?: InputSize;
  color?:
    | "primary"
    | "secondary"
    | "info"
    | "accent"
    | "success"
    | "warning"
    | "error"
    | "default";
} & InputHTMLAttributes<HTMLInputElement>;

export const InputUI: FC<InputUIProps> = ({
  className = "",
  registerName,
  label = "",
  inputSize = "md",
  color = "default",
  children,
  ...rest
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = errors[registerName];

  const getColor = useCallback(
    () =>
      ({
        primary: "input-primary",
        secondary: "input-secondary",
        info: "input-info",
        accent: "input-accent",
        success: "input-success",
        warning: "input-warning",
        error: "input-error",
        default: "",
      }[color]),
    [color]
  );

  const getSize = useCallback(
    () =>
      ({
        xs: "input-xs",
        sm: "input-sm",
        md: "input-md",
        lg: "input-lg",
        xl: "input-xl",
      }[inputSize]),
    [inputSize]
  );

  return (
    <div className="form-control w-full max-w-xl">
      {label && (
        <label className="label">
          <span className="label-text">{label}</span>
        </label>
      )}
      <input
        type="text"
        placeholder="Type here"
        className={`input input-bordered w-full max-w-xl ${className}  ${getSize()} ${
          error ? "input-error" : getColor()
        }`}
        {...register(registerName)}
        {...rest}
      />
      {error && (
        <label className="label">
          <span className="label-text-alt text-error">
            {`${error.message || ""}`}
          </span>
        </label>
      )}
    </div>
  );
};
