import React from "react";
import { EmployeesItemFragment } from "../../../../generated/graphql";

export type EmployeesItemUIProps = {
  employee: EmployeesItemFragment;
  selected: boolean;
  onSelectEmployee: (id: string) => void;
};

export const EmployeesItemUI: React.VFC<EmployeesItemUIProps> = ({
  employee,
  selected = false,
  onSelectEmployee,
}) => {
  return (
    <tr
      className={`py-2.5 pl-3 whitespace-nowrap hover:bg-blue-100 ${
        selected ? "bg-yellow-200" : ""
      }`}
      onClick={() => {
        onSelectEmployee(employee.id);
      }}
    >
      <td className="px-3 text-dark-gray">
        {employee.name}({employee.kana})
      </td>
      <td className="px-3 text-dark-gray">{employee.email}</td>
      <td className="px-3 text-dark-gray">{employee.companyId}</td>
      <td className="px-3 text-dark-gray">{employee.description}</td>
    </tr>
  );
};
