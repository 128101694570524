import React from "react";
import { useCallback } from "react";
import { useState } from "react";
import CreateMatterModal from "./components/CreateMatterModal";

export type MatterInput = {
  title: string;
  description?: string;
};

const MatterPage = () => {
  const [createMatterModalVisibility, setCreateMatterModalVisibility] =
    useState(false);

  const registerMatter = useCallback((matterInput: MatterInput) => {
    // TODO: MatterをDBに登録するAPI処理を書く
    setCreateMatterModalVisibility(false);
  }, []);

  return (
    <>
      <div className="flex h-matter-wrapper mt-44">
        <div className="h-full w-1/4 ml-72 border-4 border-primary">
          <div className="h-matter-sub bg-primary text-white text-center leading-matter-header text-3xl">
            案 件 一 覧
          </div>
          <div className="h-matter-main text-center">
            <button
              onClick={() => {
                setCreateMatterModalVisibility(true);
              }}
              className="h-8 border-2 w-36 mt-2 rounded-3xl border-primary text-lg focus:outline-none hover:bg-primary hover:text-white"
            >
              案件を追加
            </button>
          </div>
        </div>
        <div className="h-3/5 w-1/3 ml-40 mt-auto">
          <div className="w-full h-matter-sub border-2 border-primary"></div>
          <div className="w-full h-matter-main border-2 border-t-0 border-primary"></div>
        </div>
      </div>
      <CreateMatterModal
        visibility={createMatterModalVisibility}
        onClosed={() => {
          setCreateMatterModalVisibility(false);
        }}
        onRegister={registerMatter}
      ></CreateMatterModal>
    </>
  );
};

export default MatterPage;
