import React, { InputHTMLAttributes, FC } from "react";
import { useFormContext } from "react-hook-form";
import { GenderType } from "../../../generated/graphql";
import { OptionItem } from "../../../types/common";

export type RadioUIProps = {
  registerName: `${string}` | `${string}.${string}` | `${string}.${number}`;
  options: OptionItem[];
  radioType?: "gender";
} & InputHTMLAttributes<HTMLInputElement>;

type OptionItemWithStype = {
  radioStyle: string;
} & OptionItem;

export const RadioUI: FC<RadioUIProps> = ({
  registerName,
  radioType = "",
  className,
  options,
  children,
  id,
  ...rest
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = errors[registerName];
  const optionsWithStyle = options.map((option: OptionItem) => {
    if (radioType === "gender") {
      return {
        ...option,
        radioStyle: `radio ${
          option.value === GenderType.Male
            ? "checked:bg-blue-500"
            : "checked:bg-red-500"
        }`,
      };
    } else {
      return { ...option, radioStyle: "radio" };
    }
  });
  return (
    <div className={`flex items-center ${className} space-x-3`} {...rest}>
      {optionsWithStyle.map((option: OptionItemWithStype) => (
        <label className="label cursor-pointer" htmlFor={option.value}>
          <span className="label-text mr-1.5">{option.label}</span>
          <input
            id={option.value}
            type="radio"
            className={option.radioStyle}
            {...register(registerName)}
            value={option.value}
          />
        </label>
      ))}
      {error && (
        <label className="label">
          <span className="label-text-alt text-error">{`${
            error.message || ""
          }`}</span>
        </label>
      )}
    </div>
  );
};
