import React, { HTMLAttributes, useCallback, FC } from "react";

export type BadgeUIProps = {
  isOutline?: boolean;
  color:
    | "primary"
    | "secondary"
    | "accent"
    | "ghost"
    | "info"
    | "success"
    | "warning"
    | "error";
} & HTMLAttributes<HTMLDivElement>;

export const BadgeUI: FC<BadgeUIProps> = ({
  color,
  className,
  isOutline = false,
  children,
}) => {
  const getColor = useCallback(
    () =>
      ({
        primary: "badge-primary",
        secondary: "badge-secondary",
        accent: "badge-accent",
        ghost: "badge-ghost",
        link: "badge-link",
        info: "badge-info",
        success: "badge-success",
        warning: "badge-warning",
        error: "badge-error",
      }[color]),
    [color]
  );

  return (
    <div
      className={`badge ${getColor()} ${
        isOutline ? "badge-outline" : ""
      } ${className}`}
    >
      {children}
    </div>
  );
};
