import React, { useCallback } from "react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  SortDirection,
  Row,
  Cell,
} from "@tanstack/react-table";
import {
  ContextMenuStatus,
  SelectContextMenuHandler,
} from "../../routes/emails/SearchEmailsPage";

type EmailReactTableProps<T> = {
  data: T[];
  columns: ColumnDef<T, any>[];
  onSelectEmail: (id: string) => void;
  onSelectContextMenu: SelectContextMenuHandler;
};

export const EmailReactTable = <T extends Record<string, unknown>>({
  data,
  columns,
  onSelectEmail,
  onSelectContextMenu,
}: EmailReactTableProps<T>) => {
  const table = useReactTable<T>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  const getSortLabel = useCallback((status: false | SortDirection): string => {
    if (status === false) {
      return "";
    }
    if (status === "asc") {
      return " ▲";
    }
    return " ▼";
  }, []);
  return (
    <div>
      <table className="table table-zebra table-compact w-full">
        <thead className="sticky top-0">
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers
                .filter(header => header.id !== "emailId")
                .map(header => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? "cursor-pointer select-none"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {getSortLabel(header.column.getIsSorted())}
                      </div>
                    )}
                  </th>
                ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row: Row<T>) => (
            <tr
              key={row.id}
              className="hover:bg-blue-100"
              onClick={() => {
                onSelectEmail(row.getValue("emailId"));
              }}
              onContextMenu={event => {
                event.preventDefault();
                const status: ContextMenuStatus = {
                  id: row.getValue("emailId"),
                  visible: true,
                  clientX: event.clientX,
                  clientY: event.clientY,
                };
                onSelectContextMenu(status);
              }}
            >
              {row
                .getVisibleCells()
                .filter((cell: Cell<T, any>) => cell.column.id !== "emailId")
                .map((cell: Cell<T, any>) => (
                  <td key={cell.id} className="max-w-md">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
