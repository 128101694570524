import React, { FC } from "react";
import Highlighter, { HighlighterProps } from "react-highlight-words";

type FreeWordHighlighterProps = {
  type: "Modal" | "Table";
  freeWords: string[];
  textToHighlight: string;
};

export const FreeWordHighlighter: FC<FreeWordHighlighterProps> = ({
  type,
  freeWords,
  textToHighlight,
}) => {
  let highlightStyle: HighlighterProps["highlightStyle"];
  switch (type) {
    case "Modal":
      highlightStyle = {
        fontSize: "1.1rem",
        fontWeight: "bold",
      };
      break;
    case "Table":
      highlightStyle = {
        backgroundColor: "transparent",
        color: "#008bd9",
      };
      break;
    default:
      highlightStyle = {};
    // react-highlight-wordsではbackgroundColorはデフォルトでyellowになる
  }
  return (
    <Highlighter
      highlightStyle={highlightStyle}
      searchWords={freeWords}
      autoEscape={true}
      textToHighlight={textToHighlight}
    />
  );
};
