import React, { useState, useCallback, FC } from "react";
import ReactModal from "react-modal";
import { MatterInput } from "../MatterPage";
import { modalStyle } from "./CreateMatterModalStyle";

type CreateMatterModalProps = {
  visibility: boolean;
  onClosed: () => void;
  onRegister: (matterInput: MatterInput) => void;
};

const CreateMatterModal: FC<CreateMatterModalProps> = ({
  visibility,
  onClosed,
  onRegister,
}) => {
  const [matterInput, setMatterInput] = useState<MatterInput>({
    title: "",
    description: "",
  });

  const close = useCallback(() => {
    setMatterInput({
      title: "",
      description: "",
    });
    onClosed();
  }, [setMatterInput, onClosed]);

  const register = useCallback(() => {
    onRegister(matterInput);
    setMatterInput({
      title: "",
      description: "",
    });
  }, [matterInput, onRegister, setMatterInput]);

  const onChangeEvent: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = useCallback(
    event => {
      event.target.id === "title"
        ? setMatterInput({
            title: event.target.value,
            description: matterInput.description,
          })
        : setMatterInput({
            title: matterInput.title,
            description: event.target.value,
          });
    },
    [matterInput]
  );
  return (
    <ReactModal style={modalStyle} isOpen={visibility} onRequestClose={close}>
      <div className="h-96 w-5/6 border mt-4 mx-auto">
        <input
          id="title"
          value={matterInput.title}
          onChange={onChangeEvent}
          type="text"
          className="h-matter-sub w-full p-2 text-xl border-2 border-primary outline-none"
          placeholder="案件名を入力してください"
        />
        <textarea
          id="description"
          value={matterInput.description}
          onChange={onChangeEvent}
          className="border-2 h-matter-main w-full p-4 border-primary border-t-0 outline-none"
          placeholder="案件の詳細を入力してください"
        ></textarea>
      </div>
      <div className="flex justify-evenly mt-6">
        <button
          className="h-10 w-32 border-2 rounded-3xl text-lg border-primary focus:outline-none hover:bg-primary hover:text-white"
          onClick={close}
        >
          戻る
        </button>
        <button
          className="h-10 w-32 border-2 rounded-3xl text-lg border-primary focus:outline-none hover:bg-primary hover:text-white"
          onClick={register}
        >
          追加
        </button>
      </div>
    </ReactModal>
  );
};

export default CreateMatterModal;
