export const modalStyle = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    top: "50px",
    left: "250px",
    right: "250px",
    bottom: "50px",
    border: "none",
    background: "#fff",
    overflow: "auto",
    borderRadius: "10px",
    outline: "none",
    padding: "0px",
  },
};
