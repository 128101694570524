import type { FC } from "react";
import React, { useEffect } from "react";
import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { LoadingUI } from "../../../components/Loading";
import { PageTitle } from "../../../components/PageTitle";
import { DeliverySchedulesFragment } from "../../../generated/graphql";
import { DeliverySchedulesTableUI } from "./components/DeliverySchedulesTable";

type DeliverySchedulesPageProps = {
  isLoading: boolean;
  deliverySchedules: DeliverySchedulesFragment;
};

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "ホーム",
  },
  {
    label: "配信予約一覧",
  },
];

const DeliverySchedulesPage: FC<DeliverySchedulesPageProps> = ({
  isLoading,
  deliverySchedules,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  return (
    <div>
      <PageTitle>配信予約一覧</PageTitle>
      {isLoading ? (
        <div className="w-full flex justify-center pt-20">
          <LoadingUI type="Grid" />
        </div>
      ) : (
        <div className="flex flex-col">
          <p className="text-sm tracking-widest mb-2.5">
            全
            <span className="font-bold text-lg">
              {deliverySchedules.totalCount}
            </span>
            件中
            <span className="font-bold text-lg">
              {deliverySchedules.nodes.length}
            </span>
            件表示
          </p>
          <div className="flex flex-row space-x-2">
            <div className="w-full">
              <div className="shadow overflow-hidden border-b border-gray-200 rounded-md overflow-y-scroll h-screen">
                <DeliverySchedulesTableUI
                  deliverySchedules={deliverySchedules.nodes}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default DeliverySchedulesPage;
