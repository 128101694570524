import React from "react";
import { DeliveryGroupsItemFragment } from "../../../../generated/graphql";

export type DeliveryGroupsItemUIProps = {
  deliveryGroup: DeliveryGroupsItemFragment;
  selected: boolean;
  onSelectGroup: (id: string) => void;
};

export const DeliveryGroupsItemUI: React.VFC<DeliveryGroupsItemUIProps> = ({
  deliveryGroup,
  selected = false,
  onSelectGroup,
}) => {
  return (
    <tr
      className={`py-2.5 pl-3 whitespace-nowrap hover:bg-blue-100 ${
        selected ? "bg-yellow-200" : ""
      }`}
      onClick={() => {
        onSelectGroup(deliveryGroup.id);
      }}
    >
      <td className="px-3 text-dark-gray">{deliveryGroup.name}</td>
      <td className="px-3 text-dark-gray">{deliveryGroup.description}</td>
      <td className="px-3 text-dark-gray">{`${deliveryGroup.fromUser.familyName}${deliveryGroup.fromUser.givenName}`}</td>
      <td className="px-3 text-dark-gray">
        {deliveryGroup.toEmployees.map(employee => employee.name).join(", ")}
      </td>
      <td className="px-3 text-dark-gray">
        <>{deliveryGroup.updatedAt}</>
      </td>
    </tr>
  );
};
