import React, { useState, useCallback, useEffect } from "react";
import type { FC, MouseEventHandler, FormEventHandler } from "react";
import DeliveryGroupsPage from "./DeliveryGroupsPage";
import {
  DeliveryGroupSearchInput,
  useDeliveryGroupsPageQuery,
  DeliveryGroupsFragment,
  DeliveryGroupsItemFragment,
  useUpdateDeliveryGroupMutation,
  UpdateDeliveryGroupInput,
  useDeleteDeliveryGroupMutation,
  SelectItemEmployeeFragment,
  SelectItemUserFragment,
  DeliveryEmployeeFragment,
  UpdateDeliveryEmployeeInput,
} from "../../../generated/graphql";
import { ChangeDeliveryGroupSearchInput } from "./components/SearchDeliveryGroupsForm";
import { DeliveryGroupDetailModal } from "./components/DeliveryGroupDetailModal";
import { OptionItem } from "../../../types/common";

export type UpdateDeliveryGroupModify = {
  fromUserName: string;
  deliveryEmployeesModify: UpdateDeliveryEmployeeModify[];
} & Omit<UpdateDeliveryGroupInput, "toEmployees">;

export type UpdateDeliveryEmployeeModify = {
  id: string;
  name: string;
  email: string;
} & UpdateDeliveryEmployeeInput;

const DeliveryGroupsPageContainer: FC = () => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectItemUsers, setSelectItemUsers] = useState<OptionItem[]>([]);
  const [selectItemEmployees, setSelectItemEmployees] = useState<OptionItem[]>(
    []
  );
  const [searchInput, setSearchInput] = useState<DeliveryGroupSearchInput>();
  const { error, data, refetch } = useDeliveryGroupsPageQuery({
    onCompleted: async () => {
      setIsLoading(false);
    },
    // notifyOnNetworkStatusChange: true,
  });
  const [selectedDeliveryGroup, setSelectedGroup] =
    useState<UpdateDeliveryGroupModify>();

  const [deliveryGroups, setDeliveryGroups] = useState<DeliveryGroupsFragment>({
    totalCount: 0,
    nodes: [],
  });
  const [updateDeliveryGroup, { error: errorMutation }] =
    useUpdateDeliveryGroupMutation({
      onCompleted: async () => {
        await refetch({
          filter: searchInput,
          offset: 0,
          limit: 50,
        });
        setIsLoading(false);
      },
    });

  const [deleteDeliveryGroup] = useDeleteDeliveryGroupMutation({
    onCompleted: async () => {
      await refetch({
        filter: searchInput,
        offset: 0,
        limit: 50,
      });
      setIsLoading(false);
    },
  });

  useEffect(() => {
    let isMounted = true;
    if (isMounted && data) {
      setDeliveryGroups(data.deliveryGroups);
      setSelectItemEmployees(
        data.employees.nodes.map((employee: SelectItemEmployeeFragment) => ({
          value: employee.id,
          label: `${employee.email} (${employee.name})`,
        }))
      );
      setSelectItemUsers(
        data.users.nodes.map((user: SelectItemUserFragment) => ({
          value: user.id,
          label: `${user.userId} (${user.familyName} ${user.givenName})`,
        }))
      );
    }
    return () => {
      isMounted = false;
    };
  }, [data]);
  const onSelectGroup = useCallback(
    (id: string) => {
      const deliveryGroup: DeliveryGroupsItemFragment =
        deliveryGroups.nodes.find(
          (deliveryGroup: DeliveryGroupsItemFragment) => deliveryGroup.id === id
        )!;
      const deliveryGroupModify: UpdateDeliveryGroupModify = {
        id: deliveryGroup.id,
        name: deliveryGroup.name,
        description: deliveryGroup.description,
        fromUserId: deliveryGroup.fromUserId,
        fromUserName: `${deliveryGroup.fromUser.familyName} ${deliveryGroup.fromUser.givenName}`,
        deliveryEmployeesModify: deliveryGroup.toEmployees.map(
          (employee: DeliveryEmployeeFragment) => {
            return {
              id: employee.id,
              name: employee.name,
              email: employee.email,
              employeeId: employee.employeeId,
              delivered: employee.delivered,
              disabled: employee.disabled,
            };
          }
        ),
      };
      setSelectedGroup(deliveryGroupModify);
      setModalVisibility(true);
    },
    [deliveryGroups.nodes]
  );
  const onChangeSearchInput: ChangeDeliveryGroupSearchInput = useCallback(
    (newSearchInput: DeliveryGroupSearchInput) => {
      setSearchInput(newSearchInput);
    },
    []
  );
  const onSearch: FormEventHandler = useCallback(
    async event => {
      event.preventDefault();
      setIsLoading(true);
      await refetch({
        filter: searchInput,
        offset: 0,
        limit: 50,
      });
    },
    [searchInput, refetch]
  );

  const onClearFilter: MouseEventHandler = useCallback(
    async event => {
      event.preventDefault();
      setSearchInput({
        ...searchInput,
      });
    },
    [searchInput]
  );

  const onClosedModal: MouseEventHandler = useCallback(async event => {
    event.preventDefault();
    setModalVisibility(false);
  }, []);

  const onUpdateDeliveryGroup = useCallback(
    async (validatedInput: UpdateDeliveryGroupModify) => {
      if (!selectedDeliveryGroup?.id) {
        return;
      }
      setModalVisibility(false);
      setIsLoading(true);
      // eslint-disable-next-line no-unused-vars
      const { deliveryEmployeesModify, fromUserName, ...subDeliveryGroup } =
        validatedInput;
      const input: UpdateDeliveryGroupInput = {
        ...subDeliveryGroup,
        id: subDeliveryGroup!.id,
        toEmployees: deliveryEmployeesModify.map(
          (employee: UpdateDeliveryEmployeeModify) => ({
            id: employee.id,
            employeeId: employee.employeeId,
            disableReason: employee.disableReason,
            delivered: employee.delivered,
            disabled: employee.disabled,
          })
        ),
      };
      await updateDeliveryGroup({
        variables: {
          input,
        },
      });
    },
    [selectedDeliveryGroup, updateDeliveryGroup]
  );

  const onDeleteDeliveryGroup = useCallback(
    async (id: string) => {
      setModalVisibility(false);
      setIsLoading(true);
      await deleteDeliveryGroup({
        variables: {
          id,
        },
      });
    },
    [deleteDeliveryGroup]
  );

  if (error) {
    return <div>{JSON.stringify(error, null, 2)}</div>;
  }
  if (errorMutation) {
    return <div>{JSON.stringify(errorMutation, null, 2)}</div>;
  }
  return (
    <>
      <DeliveryGroupsPage
        isLoading={isLoading}
        searchInput={searchInput!}
        selectedDeliveryGroup={selectedDeliveryGroup!}
        deliveryGroups={deliveryGroups}
        onSelectGroup={onSelectGroup}
        onChangeSearchInput={onChangeSearchInput}
        onSearch={onSearch}
        onClearFilter={onClearFilter}
      />
      <DeliveryGroupDetailModal
        visibility={modalVisibility}
        selectItemUsers={selectItemUsers}
        selectItemEmployees={selectItemEmployees}
        selectedDeliveryGroup={selectedDeliveryGroup}
        onClosed={onClosedModal}
        onUpdateDeliveryGroup={onUpdateDeliveryGroup}
        onDeleteDeliveryGroup={onDeleteDeliveryGroup}
      />
    </>
  );
};

export default DeliveryGroupsPageContainer;
