import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";
import { OptionItem } from "../../../types/common";

type ReactSelectUIProps = {
  registerName: `${string}` | `${string}.${string}` | `${string}.${number}`;
  className: string;
  options: OptionItem[];
  placeholder?: string;
  isSetDefault?: boolean;
};

export const ReactSelectUI: FC<ReactSelectUIProps> = ({
  registerName,
  options,
  isSetDefault = false,
  placeholder = "選択項目",
  className,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = errors[registerName];
  return (
    <div className={`${className}`}>
      <Controller
        name={registerName}
        control={control}
        render={({ field }) => (
          <Select
            options={options}
            value={options.find(x =>
              !isNaN(field.value)
                ? x.value === `${field.value}`
                : x.value === field.value
            )}
            placeholder={placeholder}
            onChange={newValue => {
              field.onChange(newValue?.value);
            }}
            defaultValue={isSetDefault ? options[0] : undefined}
          />
        )}
      />
      {error && (
        <label className="label">
          <span className="label-text-alt text-error">
            <>{error.message || ""}</>
          </span>
        </label>
      )}
    </div>
  );
};
