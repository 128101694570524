import React, { useState, useCallback, MouseEventHandler } from "react";
import {
  CreateEmployeeInput,
  useCreateEmployeeMutation,
} from "../../../generated/graphql";
import { useHistory, useLocation } from "react-router-dom";
import EmployeeNewConfirmPage from "./EmployeeNewConfirmPage";
import { CreateEmployeeModify } from "../EmployeeNewPage";
import { toastPromise } from "../../../utils/toastUtils";

const EmployeeNewConfirmPageContainer: React.VFC = () => {
  const { modifiedInput } = useLocation<{
    modifiedInput: CreateEmployeeModify;
  }>().state;
  const [createEmployeeModify] = useState<CreateEmployeeModify>(modifiedInput);
  const history = useHistory();
  const [createEmployee, { loading, error }] = useCreateEmployeeMutation({
    onCompleted: () => {
      // do nothings
    },
  });

  const onCreateEmployee: MouseEventHandler = useCallback(
    async event => {
      event.preventDefault();
      delete modifiedInput.companyName;
      const input: CreateEmployeeInput = {
        ...modifiedInput,
      };
      await toastPromise(
        createEmployee({
          variables: {
            input,
          },
        }),
        {
          pending: "処理中...",
          success: "従業員の登録に成功しました",
          error: "従業員の登録に失敗しました",
        }
      );
      history.push("/employees");
    },
    [history, modifiedInput, createEmployee]
  );

  if (error) {
    return <>{`Submission error! ${error.message}`}</>;
  }

  return (
    <EmployeeNewConfirmPage
      isLoading={loading}
      createEmployeeModify={createEmployeeModify}
      onCreate={onCreateEmployee}
    />
  );
};

export default EmployeeNewConfirmPageContainer;
