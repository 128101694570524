import React, { ReactNode } from "react";
import { Helmet } from "react-helmet-async";
import { BreadcrumbContextProvider, BreadcrumbUI } from "../Breadcrumb";
import Header from "../Header";
import { SideBarUI } from "../SideBar";

type LayoutProps = {
  children?: ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  return (
    <div className="h-full">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Helmet>
      <Header></Header>
      <div className="flex grow overflow-y-hidden h-full bg-layout-base">
        <SideBarUI className="flex-none"></SideBarUI>
        <div className="grow pl-3 pr-5 pt-5 overflow-y-scroll">
          <BreadcrumbContextProvider>
            <BreadcrumbUI className="mb-2"></BreadcrumbUI>
            {children}
          </BreadcrumbContextProvider>
        </div>
      </div>
    </div>
  );
};

export default Layout;
