import React, {
  FC,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  DEFAULT_CONTEXT_MENU_STATUS,
  DEFAULT_SEARCH_FILTER,
  SEARCH_LIMIT,
  SEARCH_OFFSET,
} from "../../../constants/searchFiilter";
import {
  EmailFragment,
  EmailSearchInput,
  EmailsFragment,
  RecruitmentType,
  useEmailsQuery,
} from "../../../generated/graphql";
import SearchEmailsPage from "./SearchEmailsPage";
import { ContextMenuUI } from "./components/ContextMenu";
import { EmailDetailModal } from "./components/EmailDetailModal";
import { ChangeIsMatterHandler } from "./components/SearchEmailsForm";

import { SubmitHandler } from "react-hook-form";
import {
  LOCAL_STORAGE_FAVORITE_EMAILS_KEY,
  LOCAL_STORAGE_INVISIBLE_EMAILS_KEY,
} from "../../../constants/localStorageKeys";
import LocalStorageEmails from "./localStorageEmails";
export type SelectContextMenuHandler = (status: ContextMenuStatus) => void;

export type ContextMenuStatus = {
  id: string;
  visible: boolean;
  clientX: number;
  clientY: number;
};

const INITIAL_SELECTED_EMAIL: EmailWithFavorite = {
  id: "",
  recruitmentType: RecruitmentType.Matter,
  subject: "",
  content: "",
  date: new Date(),
  from: {
    address: "",
    name: "",
  },
  favorite: false,
};

export type EmailsWithFavorite = {
  nodes: EmailWithFavorite[];
} & Omit<EmailsFragment, "nodes">;

export type EmailWithFavorite = {
  favorite: boolean;
} & EmailFragment;

const SearchEmailsPageContainer: FC = () => {
  const [currentSearchInput, setCurrentSearchInput] =
    useState<EmailSearchInput>(DEFAULT_SEARCH_FILTER);
  const localStorageInvisibledEmailIds = useMemo(
    (): LocalStorageEmails =>
      new LocalStorageEmails(LOCAL_STORAGE_INVISIBLE_EMAILS_KEY),
    []
  );

  const localStorageFavoriteEmailIds = useMemo(
    (): LocalStorageEmails =>
      new LocalStorageEmails(LOCAL_STORAGE_FAVORITE_EMAILS_KEY),
    []
  );

  const [contextmenuStatus, setContextMenuStatus] = useState<ContextMenuStatus>(
    DEFAULT_CONTEXT_MENU_STATUS
  );

  const [invisibledEmailIds, setInvisibleEmailIds] = useState<string[]>(
    localStorageInvisibledEmailIds.getEmailIds()
  );
  const [favoEmailIds, setFavoEmailIds] = useState<string[]>(
    localStorageFavoriteEmailIds.getEmailIds()
  );

  const [isLoading, setIsLoading] = useState(false);
  const [searchLimit, setSearchLimit] = useState(SEARCH_LIMIT);
  const { error, data, refetch } = useEmailsQuery({
    variables: {
      filter: DEFAULT_SEARCH_FILTER,
      offset: 0,
      limit: SEARCH_LIMIT,
    },
    onCompleted: () => {
      setIsLoading(false);
    },
    notifyOnNetworkStatusChange: true,
  });
  const [selectedEmail, setSelectedEmail] = useState<EmailWithFavorite>(
    INITIAL_SELECTED_EMAIL
  );

  // 案件メールを保持する配列
  const [matterEmails, setMatterEmails] = useState<EmailsWithFavorite>({
    totalCount: 0,
    nodes: [],
  });
  // 人材メールを保持する配列
  const [hrEmails, setHREmails] = useState<EmailsWithFavorite>({
    totalCount: 0,
    nodes: [],
  });

  const [modalVisibility, setModalVisibility] = useState(false);

  const [isMatter, setIsMatter] = useState(true);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && data) {
      const filteredEmails = {
        totalCount: data.emails.totalCount,
        nodes: data.emails.nodes
          .map(
            (email: EmailFragment): EmailWithFavorite => ({
              ...email,
              favorite: favoEmailIds.includes(email.id),
            })
          )
          .filter(
            (email: EmailWithFavorite) => !invisibledEmailIds.includes(email.id)
          ),
      };
      if (isMatter) {
        setMatterEmails(filteredEmails);
      } else {
        setHREmails(filteredEmails);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [data, invisibledEmailIds, favoEmailIds, isMatter]);
  const onSelectEmail = useCallback(
    (id: string) => {
      const email = isMatter
        ? matterEmails.nodes.find((email: EmailWithFavorite) => email.id === id)
        : hrEmails.nodes.find((email: EmailWithFavorite) => email.id === id);
      setSelectedEmail(email!);
      setModalVisibility(true);
    },
    [isMatter, matterEmails.nodes, hrEmails.nodes]
  );
  const onSelectContextMenu: SelectContextMenuHandler = useCallback(
    (status: ContextMenuStatus) => {
      setContextMenuStatus(status);
    },
    []
  );
  const generateModifiedSearchInput = useCallback(
    (searchInput: EmailSearchInput): EmailSearchInput => {
      const copy = JSON.parse(JSON.stringify(searchInput));
      // タイプを切り替えるときはhrFilterまたはmatterFilterのオブジェクト自体を削除する
      if (copy.recruitmentType === RecruitmentType.Matter) {
        delete copy.hrFilter;
      } else if (copy.recruitmentType === RecruitmentType.HumanResource) {
        delete copy.matterFilter;
      }
      // NOTE: freeWords配列に空文字が入っていると正しくレスポンスを返さないことがある
      if (copy.freeWords) {
        copy.freeWords = copy.freeWords.filter((v: string) => v);
        if (copy.freeWords.length === 0) {
          delete copy.freeWords;
        }
      }
      return copy;
    },
    []
  );
  const onSearch: SubmitHandler<EmailSearchInput> = useCallback(
    async (data: EmailSearchInput) => {
      try {
        setIsLoading(true);
        await refetch({
          filter: generateModifiedSearchInput(data),
          offset: 0,
          limit: SEARCH_LIMIT,
        });
        setSearchLimit(SEARCH_LIMIT);
        if (selectedEmail.subject !== "") {
          setSelectedEmail(INITIAL_SELECTED_EMAIL);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setCurrentSearchInput(generateModifiedSearchInput(data));
        setIsLoading(false);
      }
    },
    [selectedEmail, refetch, generateModifiedSearchInput]
  );

  const resetFilter = useCallback((recruitmentType: RecruitmentType) => {
    setCurrentSearchInput({
      ...DEFAULT_SEARCH_FILTER,
      recruitmentType: recruitmentType,
      freeWords: [],
    });
    setSearchLimit(SEARCH_LIMIT);
  }, []);

  const onClearFilter = useCallback(async () => {
    resetFilter(currentSearchInput.recruitmentType!);
    localStorageInvisibledEmailIds.resetEmailIds();
    localStorageFavoriteEmailIds.resetEmailIds();
    setInvisibleEmailIds([]);
    setFavoEmailIds([]);
  }, [
    currentSearchInput,
    resetFilter,
    localStorageInvisibledEmailIds,
    localStorageFavoriteEmailIds,
  ]);

  const onClosedModal: MouseEventHandler = useCallback(async event => {
    event.preventDefault();
    setModalVisibility(false);
  }, []);

  const onClosedMenu: MouseEventHandler = useCallback(async event => {
    event.preventDefault();
    setContextMenuStatus(DEFAULT_CONTEXT_MENU_STATUS);
  }, []);

  const addInvisibleEmailId = useCallback(
    (emailId: string) => {
      localStorageInvisibledEmailIds.appendEmailId(emailId);
      setInvisibleEmailIds(localStorageInvisibledEmailIds.getEmailIds());
    },
    [localStorageInvisibledEmailIds]
  );

  const onToggleFavoriteEmail = useCallback(
    (emailId: string, favorite: boolean) => {
      favorite
        ? localStorageFavoriteEmailIds.appendEmailId(emailId)
        : localStorageFavoriteEmailIds.removeEmailId(emailId);
      setFavoEmailIds(localStorageFavoriteEmailIds.getEmailIds());
    },
    [localStorageFavoriteEmailIds]
  );

  const onSelectedMenu: MouseEventHandler = useCallback(
    async event => {
      event.preventDefault();
      addInvisibleEmailId(contextmenuStatus.id);
      setContextMenuStatus(DEFAULT_CONTEXT_MENU_STATUS);
    },
    [contextmenuStatus, addInvisibleEmailId]
  );

  const onChangeIsMatter: ChangeIsMatterHandler = useCallback(
    async (isMatter: boolean) => {
      setIsMatter(isMatter);
      resetFilter(
        isMatter ? RecruitmentType.Matter : RecruitmentType.HumanResource
      );
    },
    [setIsMatter, resetFilter]
  );

  const onShowMore: MouseEventHandler = useCallback(
    async event => {
      event.preventDefault();
      setIsLoading(true);
      const newLimit = searchLimit + SEARCH_OFFSET;
      setSearchLimit(newLimit);

      await refetch({
        filter: generateModifiedSearchInput(currentSearchInput),
        offset: 0,
        limit: newLimit,
      });
    },
    [currentSearchInput, searchLimit, refetch, generateModifiedSearchInput]
  );

  if (error) {
    return <div>{JSON.stringify(error, null, 2)}</div>;
  }

  return (
    <>
      <SearchEmailsPage
        currentSearchInput={currentSearchInput}
        isMatter={isMatter}
        isLoading={isLoading}
        selectedEmail={selectedEmail}
        invisibleEmailNums={invisibledEmailIds.length}
        onToggleFavoriteEmail={onToggleFavoriteEmail}
        matterEmails={matterEmails}
        hrEmails={hrEmails}
        onShowMore={onShowMore}
        onSelectEmail={onSelectEmail}
        onSelectContextMenu={onSelectContextMenu}
        onSearch={onSearch}
        onClearFilter={onClearFilter}
        onChangeIsMatter={onChangeIsMatter}
      />
      <EmailDetailModal
        visibility={modalVisibility}
        selectedEmail={selectedEmail}
        onClosed={onClosedModal}
        freeWords={currentSearchInput.freeWords || []}
      />
      <ContextMenuUI
        status={contextmenuStatus}
        onClosed={onClosedMenu}
        onSelectedMenu={onSelectedMenu}
      />
    </>
  );
};

export default SearchEmailsPageContainer;
