import React, { FC, useCallback, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { ButtonUI } from "../../../components/Button";
import { FormGroupUI } from "../../../components/FormGroup";
import { FormGroupLabelUI } from "../../../components/FormGroupLabel";
import { InputUI } from "../../../components/rhfs/Input";
import { RadioUI } from "../../../components/rhfs/Radio";
import { ReactSelectUI } from "../../../components/rhfs/ReactSelect";
import { TextareaUI } from "../../../components/rhfs/Textarea";
import { ToggleUI } from "../../../components/rhfs/Toggle";
import { ValidatableDateTimeUI } from "../../../components/rhfs/ValidatableDateTime";
import { fromOptions, FromOptionValue } from "../../../constants/emailSenders";
import { OptionItem } from "../../../types/common";
import { ReactComponent as ArrowPath } from "./arrow-path.svg";
import { ReactComponent as QuestionMarkCircle } from "./question-mark-circle.svg";

const COMPANY_PLACEHOLDER = "{{COMPANY}}";
const NAME_PLACEHOLDER = "{{NAME}}";

export enum RadioOptionValue {
  HR = "HR",
  Matter = "MATTER",
  HRAndMatter = "ALL",
}

export type FormValues = {
  category: RadioOptionValue;
  from: FromOptionValue | "";
  subject: string;
  content: string;
  hrHash: string;
  scheduled: boolean;
  scheduledAt: string;
};

export const radioOptions: OptionItem[] = [
  { value: RadioOptionValue.HR, label: "人材" },
  { value: RadioOptionValue.Matter, label: "案件" },
  { value: RadioOptionValue.HRAndMatter, label: "人材と案件" },
];

export const SendBulkEmailsForm: FC<{
  hrHash: string;
  scheduled: boolean;
  onGenerateHash: () => void;
}> = ({ hrHash, scheduled, onGenerateHash }) => {
  const { register, setValue } = useFormContext<FormValues>();
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const handleInsertText = useCallback(
    (text: string) => {
      const textarea = textareaRef.current;
      if (textarea) {
        const {
          selectionStart: startPos,
          selectionEnd: endPos,
          value: currentContent,
        } = textarea;
        const newText = `${currentContent.substring(
          0,
          startPos
        )}${text}${currentContent.substring(endPos)}`;
        setValue("content", newText, { shouldValidate: true });
        setTimeout(() => {
          const newPos = startPos + text.length;
          textarea.setSelectionRange(newPos, newPos);
          textarea.focus();
        }, 0);
      }
    },
    [setValue]
  );

  return (
    <form className="relative mb-8">
      <FormGroupUI className="mb-3">
        <FormGroupLabelUI className="w-[3.9rem]">配信種別</FormGroupLabelUI>
        <RadioUI
          registerName="category"
          options={radioOptions}
          className="space-x-6"
        />
      </FormGroupUI>
      <FormGroupUI className="mb-3">
        <FormGroupLabelUI className="w-[3.9rem]">配信者</FormGroupLabelUI>
        <div className="flex-grow">
          <ReactSelectUI
            registerName="from"
            options={fromOptions}
            placeholder="配信者を選択してください"
            className="w-72 h-auto py-2"
          />
        </div>
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI className="w-[3.9rem]">件名</FormGroupLabelUI>
        <InputUI
          registerName="subject"
          className="w-full"
          placeholder="件名を入力してください"
        />
      </FormGroupUI>
      <FormGroupUI className="mb-3 md:items-start relative w-full">
        <FormGroupLabelUI className="w-[3.9rem]"></FormGroupLabelUI>
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center space-x-2">
            <ButtonUI
              className="w-40"
              type="button"
              color="success"
              size="sm"
              isOutline={true}
              onClick={() => handleInsertText(COMPANY_PLACEHOLDER)}
            >
              会社変数
            </ButtonUI>
            <ButtonUI
              className="w-40"
              type="button"
              color="success"
              size="sm"
              isOutline={true}
              onClick={() => handleInsertText(NAME_PLACEHOLDER)}
            >
              担当者変数
            </ButtonUI>
            <div
              className="tooltip tooltip-top before:max-w-xs before:content-[attr(data-tip)] before:text-left before:whitespace-pre-wrap"
              data-tip={`変数とは「一時的なラベル」です。
  たとえば{{NAME}}というラベルを文章に含めておくと
  一括配信時に、ラベルが宛先の各担当者名に置き換わります。`}
            >
              <div className="flex items-center cursor-pointer">
                <QuestionMarkCircle className="w-5 h-5 text-gray-600" />
                <span className="text-xs font-semibold">変数とは</span>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <span className="text-sm text-gray-600 mr-2 font-bold">
              人材Hash:
            </span>
            <span className="bg-gray-100 px-3 py-2 rounded-md text-gray-700 font-mono text-sm">
              {hrHash}
            </span>
            <ArrowPath
              className="w-5 h-5 ml-2 text-success hover:text-green-400"
              onClick={onGenerateHash}
            />
            <div
              className="tooltip tooltip-top before:max-w-xs before:content-[attr(data-tip)] before:text-left before:whitespace-pre-wrap"
              data-tip={`人材Hashとは、配信メールを追跡するための識別子です。これにより、各メールの開封率やクリック率を分析し、効果測定や改善に役立てます。`}
            >
              <div className="flex items-center cursor-pointer ml-2 mr-2">
                <QuestionMarkCircle className="w-5 h-5 text-gray-600" />
                <span className="text-xs font-semibold">人材Hashとは</span>
              </div>
            </div>
          </div>
        </div>
      </FormGroupUI>
      <FormGroupUI className="mb-3 md:items-start relative w-full">
        <FormGroupLabelUI className="w-[3.9rem]">本文</FormGroupLabelUI>
        <div className="flex flex-col w-full">
          <TextareaUI
            registerName="content"
            ref={e => {
              textareaRef.current = e;
              if (e) {
                register("content").ref(e);
              }
            }}
            placeholder="本文を入力してください"
            rows={20}
            className="w-full"
          />
        </div>
      </FormGroupUI>
      <FormGroupUI className="my-5">
        <FormGroupLabelUI>配信日時</FormGroupLabelUI>
        <div className="flex flex-col">
          <ToggleUI color="primary" label="予約配信" registerName="scheduled" />
          {scheduled && (
            <ValidatableDateTimeUI rules={{}} registerName="scheduledAt" />
          )}
        </div>
      </FormGroupUI>
    </form>
  );
};
