/**
 * 引数のorganizationIdが開発環境用の表示を許可されたアカウントかどうか
 * @param {string}  organizationId - 組織名
 * @return {boolean} 開発用かどうか
 */
export const isAllowDevelopUse = (organizationId: string) =>
  [
    "5cbf2941-be8b-4866-a6a9-9cac0b2ac723", // Tech Reis Dev
    "323d67dc-1fb7-4931-97fc-263bdb48cf4b", // Tech Reis Prd
  ].includes(organizationId);
