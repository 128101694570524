import React, { ReactNode } from "react";
import { Helmet } from "react-helmet-async";

type NoHeadLayoutProps = {
  children?: ReactNode;
};

const NoHeadLayout = ({ children }: NoHeadLayoutProps) => {
  return (
    <div className="h-full bg-layout-base">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Helmet>
      <div className="flex grow overflow-y-hidden h-full">{children}</div>
    </div>
  );
};

export default NoHeadLayout;
