import React, { useCallback, useContext } from "react";
import { SignInPage } from "./SignInPage";
import { useHistory } from "react-router-dom";
import { SubmitHandler } from "react-hook-form";
import { AuthContext } from "../../hooks/authProvider/authProvider";
import { toastError } from "../../utils/toastUtils";

export type SignInInput = {
  email: string;
  password: string;
};

const SignInPageContainer: React.VFC = () => {
  const { signIn } = useContext(AuthContext);
  const history = useHistory();
  const onSignIn: SubmitHandler<SignInInput> = useCallback(
    async (input: SignInInput) => {
      try {
        await signIn(input);
        history.push("/");
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        toastError(error.message);
      }
    },
    [history, signIn]
  );

  return <SignInPage onSignIn={onSignIn} />;
};

export default SignInPageContainer;
