import React from "react";
import { CompaniesItemFragment } from "../../../../generated/graphql";
import { CompaniesItemUI } from "./CompaniesItem";

export type CompaniesTableUIProps = {
  companies: CompaniesItemFragment[];
  selectedCompany: CompaniesItemFragment;
  onSelectCompany: (id: string) => void;
};

export const CompaniesTableUI: React.VFC<CompaniesTableUIProps> = ({
  companies,
  selectedCompany,
  onSelectCompany,
}) => {
  return (
    <table className={`divide-y divide-gray-200 text-xs  w-full`}>
      <thead className="bg-primary">
        <tr className="py-3 text-left font-medium text-white uppercase tracking-wider">
          <th scope="col" className="px-3">
            会社名
          </th>
          <th scope="col" className="px-3">
            設立年
          </th>
          <th scope="col" className="px-3">
            会社住所
          </th>
          <th scope="col" className="px-3">
            担当者
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {companies.map((company: CompaniesItemFragment) => (
          <CompaniesItemUI
            key={company.id}
            selected={selectedCompany && selectedCompany.id === company.id}
            company={company}
            onSelectCompany={onSelectCompany}
          />
        ))}
      </tbody>
    </table>
  );
};
