import React, { useState, useCallback, useContext } from "react";
import { SignUpPage } from "./SignUpPage";
import { useHistory } from "react-router-dom";
import {
  AuthContext,
  ConfirmSignUpInput,
  ResendSignUpHandler,
  SignUpInput,
} from "../../hooks/authProvider/authProvider";
import { SubmitHandler } from "react-hook-form";
import {
  toastClearAll,
  toastError,
  toastPromise,
} from "../../utils/toastUtils";

const SignUpPageContainer: React.VFC = () => {
  const [signUpInput] = useState<SignUpInput>({
    familyName: "",
    givenName: "",
    familyNameKana: "",
    givenNameKana: "",
    email: "",
    password: "",
  });
  const { signUpStatus, signUp, confirmSignUp, resendSignUp } =
    useContext(AuthContext);

  const history = useHistory();

  const onSignUp: SubmitHandler<SignUpInput> = useCallback(
    async (input: SignUpInput) => {
      try {
        await signUp(input);
        toastClearAll();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        toastError(error.message);
      }
    },
    [signUp]
  );

  const onConfirmSignUp: SubmitHandler<Omit<ConfirmSignUpInput, "email">> =
    useCallback(
      async (input: Omit<ConfirmSignUpInput, "email">) => {
        try {
          await confirmSignUp({
            email: signUpStatus.email,
            authCode: input.authCode,
          });
          toastClearAll();
          history.push("/");
          // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        } catch (error: any) {
          toastError(error.message);
        }
      },
      [signUpStatus.email, history, confirmSignUp]
    );

  const onResendSignUp: ResendSignUpHandler = useCallback(async () => {
    try {
      await toastPromise(resendSignUp(), {
        pending: "認証コード再送中...",
        success: "認証コードを再送しました",
        error: "認証コードの再送に失敗しました",
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toastError(error.message);
    }
  }, [resendSignUp]);

  return (
    <SignUpPage
      signUpInput={signUpInput}
      signUpStatus={signUpStatus}
      onSignUp={onSignUp}
      onConfirmSignUp={onConfirmSignUp}
      onResendSignUp={onResendSignUp}
    />
  );
};

export default SignUpPageContainer;
