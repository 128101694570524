import React from "react";
// import { DeliveryGroupsItemFragment } from "../../../../generated/graphql";
import { DeliveryGroupsItemUI } from "./DeliveryGroupsItem";

export type DeliveryGroupsTableUIProps = {
  deliveryGroups: any[];
  selectedDeliveryGroup: any;
  onSelectGroup: (id: string) => void;
};

export const DeliveryGroupsTableUI: React.VFC<DeliveryGroupsTableUIProps> = ({
  deliveryGroups,
  selectedDeliveryGroup,
  onSelectGroup,
}) => {
  return (
    <table className={`divide-y divide-gray-200 text-xs  w-full`}>
      <thead className="bg-primary">
        <tr className="py-3 text-left font-medium text-white uppercase tracking-wider">
          <th scope="col" className="px-3">
            配信グループ名
          </th>
          <th scope="col" className="px-3">
            説明
          </th>
          <th scope="col" className="px-3">
            差出人
          </th>
          <th scope="col" className="px-3">
            宛先
          </th>
          <th scope="col" className="px-3">
            最終更新日
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {deliveryGroups.map((deliveryGroup: any) => (
          <DeliveryGroupsItemUI
            key={deliveryGroup.id}
            selected={
              selectedDeliveryGroup &&
              selectedDeliveryGroup.id === deliveryGroup.id
            }
            deliveryGroup={deliveryGroup}
            onSelectGroup={onSelectGroup}
          />
        ))}
      </tbody>
    </table>
  );
};
