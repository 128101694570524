import React, { useCallback, FC } from "react";
import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { InputGroupUI } from "../../../components/rhfs/InputGroup";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { ToggleUI } from "../../../components/rhfs/Toggle";
import { ContentBoxUI } from "../../../components/ContentBox";
import { RangeUI } from "../../../components/rhfs/Range";
import { BasicTable } from "./SampleTable";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "Samples",
  },
];

type SampleInput = {
  name: string;
  isOK: boolean;
};

export const SamplesPage: FC = () => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);
  const schema = Joi.object({
    name: Joi.string().required().messages({
      "string.empty": "メールアドレスは必須項目です",
    }),
    isOK: Joi.boolean().truthy("true").falsy("false").required(),
    rewardMin: Joi.number().required(),
    rewardMax: Joi.number().required(),
  });
  const onSubmit = useCallback<SubmitHandler<SampleInput>>(data => {
    console.log(data);
  }, []);
  const methods = useForm<SampleInput>({
    resolver: joiResolver(schema),
  });
  const watchedIsOK = methods.watch("isOK");
  const watchedName = methods.watch("name");
  return (
    <div className="flex justify-center">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="mb-8">
          <div className="text-base-content glass xl:rounded-box p-10">
            <div className="grid grid-cols-3 gap-4">
              <ContentBoxUI title="01">
                <RangeUI
                  title="rewardMin"
                  registerName="rewardMin"
                  min={0}
                  max={120}
                  step={1}
                ></RangeUI>
                <RangeUI
                  title="rewardMax"
                  registerName="rewardMax"
                  min={40}
                  max={120}
                  step={5}
                ></RangeUI>
              </ContentBoxUI>
              <ContentBoxUI title="02"></ContentBoxUI>
              <ContentBoxUI title="03"></ContentBoxUI>
              <ContentBoxUI title="04"></ContentBoxUI>
              <ContentBoxUI title="05"></ContentBoxUI>
              <ContentBoxUI title="06"></ContentBoxUI>
              <ContentBoxUI title="07"></ContentBoxUI>
              <ContentBoxUI title="08"></ContentBoxUI>
            </div>
          </div>
          <InputGroupUI label="name" registerName="name" />
          <br />
          <span>watchedName: {watchedName}</span>
          <br />
          <ToggleUI label="isOK" registerName="isOK" />
          <br />
          <span>watchedIsOK: {watchedIsOK ? "true" : "false"}</span>
          <br />
          <BasicTable />

          <button type="submit">Submit</button>
        </form>
      </FormProvider>
    </div>
  );
};
