import React, { FC, useState } from "react";
import { CurrentUserFragment } from "../../generated/graphql";
import { isAllowDevelopUse } from "../../utils/authorityUtils";
import SideBarMenuItemUI from "./SideBarMenuItem";

interface SideBarMenuProps {
  currentUser?: CurrentUserFragment | null;
}

interface Menu {
  id: string;
  title: string;
  to: string;
  display: boolean;
}

export const SideBarMenuUI: FC<SideBarMenuProps> = ({ currentUser }) => {
  const organizationId = currentUser?.organizationId || "";
  const menus: Menu[] = [
    {
      id: "emailsSearch",
      title: "メール検索",
      to: "/emails/search",
      display: true,
    },
    // {
    //   id: "groups",
    //   title: "グループ一覧",
    //   to: "/groups",
    //   display: isAllowDevelopUse(organizationId),
    // },
    {
      id: "bulkEmail",
      title: "一斉配信",
      to: "/emails/bulk",
      display: isAllowDevelopUse(organizationId),
    },
    {
      id: "emailsSchedules",
      title: "予約一覧",
      to: "/emails/schedules",
      display: isAllowDevelopUse(organizationId),
    },
    {
      id: "emailsHistories",
      title: "配信履歴",
      to: "/emails/histories",
      display: isAllowDevelopUse(organizationId),
    },
    // {
    //   id: "companies",
    //   title: "会社一覧",
    //   to: "/companies",
    //   display: isAllowDevelopUse(organizationId),
    // },
    // {
    //   id: "employees",
    //   title: "社員一覧",
    //   to: "/employees",
    //   display: isAllowDevelopUse(organizationId),
    // },
    // {
    //   id: "engineers",
    //   title: "エンジニア管理",
    //   to: "/",
    //   display: isAllowDevelopUse(organizationId),
    // },
    // {
    //   id: "bulk",
    //   title: "一括登録",
    //   to: "/settings/bulk",
    //   display: isAllowDevelopUse(organizationId),
    // },
  ];

  const [openedIndex, setOpenedIndex] = useState<number | undefined>();

  const handleOnClickMenuItem = (open: boolean, index: number) => {
    setOpenedIndex(open ? index : undefined);
  };

  return (
    <div className="flex flex-col grow px-4 mt-5">
      <nav className="flex-1 space-y-1 bg-gradient-to-r from-sidebar-left to-sidebar-right">
        <ul>
          {menus
            .filter(menu => menu.display)
            .map((menu, index) => (
              <>
                {menu.id === "emailsSearch" && (
                  <p
                    key={`emailsSearch_${index}`}
                    className="px-4 pt-4 font-medium text-white"
                  >
                    メール検索
                  </p>
                )}
                {menu.id === "bulkEmail" && (
                  <p
                    key={`groups_${index}`}
                    className="px-4 pt-4 font-medium text-white"
                  >
                    メール配信
                  </p>
                )}
                {/* {menu.id === "companies" && (
                  <p
                    key={`companies_${index}`}
                    className="px-4 pt-4 font-medium text-white"
                  >
                    設定
                  </p>
                )} */}
                <SideBarMenuItemUI
                  key={index}
                  id={menu.id}
                  title={menu.title}
                  to={menu.to}
                  selected={openedIndex === index}
                  onClickMenuItem={open => {
                    handleOnClickMenuItem(open, index);
                  }}
                />
              </>
            ))}
        </ul>
      </nav>
    </div>
  );
};
