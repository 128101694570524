import { LocationDescriptor } from "history";
import React, { ButtonHTMLAttributes, FC, useCallback } from "react";
import { useHistory } from "react-router-dom";

export type ButtonUIProps = {
  size?: "xs" | "sm" | "n" | "lg";
  color?:
    | "primary"
    | "secondary"
    | "accent"
    | "ghost"
    | "link"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "normal";
  isOutline?: boolean;
  to?: LocationDescriptor;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const ButtonUI: FC<ButtonUIProps> = ({
  size = "n",
  color = "primary",
  isOutline = false,
  children,
  onClick,
  to,
  className,
  ...rest
}) => {
  const history = useHistory();
  const getColor = useCallback(
    () =>
      ({
        primary: "btn-primary hover:bg-primary-hover",
        secondary: "btn-secondary",
        accent: "btn-accent",
        ghost: "btn-ghost",
        link: "btn-link",
        info: "btn-info",
        success: "btn-success",
        warning: "btn-warning",
        error: "btn-error",
        normal: "btn-normal",
      }[color]),
    [color]
  );

  const getSize = useCallback(
    () =>
      ({
        xs: "btn-xs",
        sm: "btn-sm",
        n: "",
        lg: "btn-lg",
      }[size]),
    [size]
  );

  return (
    <button
      {...rest}
      onClick={e => {
        if (onClick) {
          return onClick(e);
        }
        if (to) {
          history.push(to);
        }
      }}
      className={`text-white btn ${getColor()} ${getSize()} ${
        isOutline ? "btn-outline" : ""
      } ${className}`}
    >
      {children}
    </button>
  );
};
