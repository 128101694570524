import React, { FC, useState } from "react";
import { CurrentUserFragment } from "../../generated/graphql";
// import { ReactComponent as Avator } from "./avator.svg";
import { useHistory } from "react-router-dom";
import { DropdownMenuUI } from "../DropdownMenu";

export type HeaderUIProps = {
  currentUser: CurrentUserFragment | null;
};

export const HeaderUI: FC<HeaderUIProps> = ({ currentUser }) => {
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);
  const history = useHistory();

  const onClickAvator = () => {
    setShowDropdownMenu(true);
  };

  const onHiddenDropdownMenu = () => {
    setShowDropdownMenu(false);
  };
  return (
    <header className="flex justify-between items-center h-16 px-5 bg-gradient-to-r from-sidebar-left to-sidebar-right">
      <div
        className="text-white flex flex-row items-end"
        onClick={() => {
          history.push("/");
        }}
      >
        <span className="mr-3 font-black text-xl">
          <span className="text-3xl">S</span> t r i x
        </span>
        <span className="mr-3 font-black text-xl">
          <span className="text-3xl">S</span> e a r c h
        </span>
        <img
          className="pb-1"
          src={`${process.env.PUBLIC_URL}/images/header_strix_icon.png`}
          width="40"
          height="40"
          alt="strix top image"
        />
      </div>
      {currentUser && (
        <div
          className="flex flex-row items-center space-x-4 h-full relative cursor-pointer"
          onClick={onClickAvator}
        >
          {/* <Avator></Avator> */}
          <span className="text-lg text-white" data-testid="header-username">
            {currentUser.familyName} {currentUser.givenName}
          </span>
          <DropdownMenuUI
            visibility={showDropdownMenu}
            onHidden={onHiddenDropdownMenu}
          />
        </div>
      )}
    </header>
  );
};
