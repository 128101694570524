import React, { HTMLAttributes, useCallback, FC } from "react";
import { useFormContext } from "react-hook-form";

export type ToggleUIProps = {
  registerName: `${string}` | `${string}.${string}` | `${string}.${number}`;
  label: string;
  toggleSize?: "xs" | "sm" | "md" | "lg";
  color?:
    | "primary"
    | "secondary"
    | "accent"
    | "ghost"
    | "link"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "default";
} & HTMLAttributes<HTMLDivElement>;

export const ToggleUI: FC<ToggleUIProps> = ({
  registerName,
  color = "default",
  toggleSize = "sm",
  label,
  className,
  ...rest
}) => {
  const { register } = useFormContext();
  const getColor = useCallback(
    () =>
      ({
        primary: "toggle-primary",
        secondary: "toggle-secondary",
        accent: "toggle-accent",
        ghost: "toggle-ghost",
        link: "toggle-link",
        info: "toggle-info",
        success: "toggle-success",
        warning: "toggle-warning",
        error: "toggle-error",
        default: "",
      }[color]),
    [color]
  );

  const getSize = useCallback(
    () =>
      ({
        xs: "toggle-xs",
        sm: "toggle-sm",
        md: "toggle-md",
        lg: "toggle-lg",
      }[toggleSize]),
    [toggleSize]
  );
  return (
    <div className={`form-control w-52 ${className}`} {...rest}>
      <label className="cursor-pointer label">
        <span className="label-text">{label}</span>
        <input
          type="checkbox"
          className={`toggle ${getColor()} ${getSize()}`}
          {...register(registerName)}
        />
      </label>
    </div>
  );
};
