import dayjs from "dayjs";
import "dayjs/locale/ja";
import React, { FC, MouseEventHandler } from "react";
import ReactModal from "react-modal";
import { ButtonUI } from "../../../../components/Button";
import { emailToNameMap } from "../../../../constants/emailSenders";
import { DeliveryHistoriesItemFragment } from "../../../../generated/graphql";
import { ReactComponent as ClosedIcon } from "./closed.svg";
import { modalStyle } from "./DeliveryHistoriesModalModalStyle";

type DeliveryHistoriesModalProps = {
  visibility: boolean;
  selectedHistory: DeliveryHistoriesItemFragment;
  onClosed: MouseEventHandler;
  onResend: (history: DeliveryHistoriesItemFragment) => void;
};

dayjs.locale("ja");

ReactModal.setAppElement("#root");

export const DeliveryHistoriesModal: FC<DeliveryHistoriesModalProps> = ({
  visibility,
  selectedHistory,
  onClosed,
  onResend,
}) => {
  const { from } = selectedHistory;
  const senderName = emailToNameMap[from] || from;

  return (
    <ReactModal
      style={modalStyle}
      isOpen={visibility}
      onRequestClose={onClosed}
    >
      <div className="flex flex-col">
        <div className="flex justify-between bg-primary text-white py-2 px-2.5">
          <div className="flex flex-col">
            <h3 className="text-sm font-bold font-hiragino-sans-w7">
              {selectedHistory.subject}
            </h3>
          </div>

          <ClosedIcon onClick={onClosed} className="w-7 h-7" />
        </div>
        <div className="p-4 overflow-y-scroll h-email-detail-content">
          <div className="flex flex-row justify-between mb-4">
            <div className="whitespace-pre-line">
              <table className="w-full text-xs leading-loose">
                <tbody>
                  <tr>
                    <td className="font-bold pr-6">人材HASH :</td>
                    <td>{selectedHistory.hrHash}</td>
                  </tr>
                  <tr>
                    <td className="font-bold pr-6">
                      成功 / 失敗（成功数 / 配信数）:
                    </td>
                    <td>
                      {selectedHistory.bulkEmailsTotal ===
                      selectedHistory.bulkEmailsSuccessTotal
                        ? "成功"
                        : "失敗"}
                      （{selectedHistory.bulkEmailsSuccessTotal} /{" "}
                      {selectedHistory.bulkEmailsTotal}）
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold pr-6">配信種別 :</td>
                    <td>{selectedHistory.deliveryType}</td>
                  </tr>
                  <tr>
                    <td className="font-bold pr-6">配信者 :</td>
                    <td>{`${senderName} <${from}>`}</td>
                  </tr>
                  <tr className="border-b border-gray-300">
                    <td className="font-bold pr-6 pb-5">配信日時 :</td>
                    <td className="pb-5">
                      {dayjs(selectedHistory.createdAt).format(
                        "YYYY/MM/DD (ddd) HH:mm"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold align-top pr-6 pt-5">本文 :</td>
                    <td className="whitespace-pre-line pt-5">
                      {selectedHistory.content}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="flex items-start">
              <ButtonUI
                size="sm"
                type="button"
                color="success"
                onClick={e => {
                  e.stopPropagation();
                  onResend(selectedHistory);
                }}
                className=""
              >
                再配信
              </ButtonUI>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};
