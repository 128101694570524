import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import CreatableSelect from "react-select/creatable";

type ReactCreatableMultiSelectUIProps = {
  registerName: `${string}` | `${string}.${string}` | `${string}.${number}`;
  className: string;
  placeholder?: string;
};

export const ReactCreatableMultiSelectUI: FC<
  ReactCreatableMultiSelectUIProps
> = ({ registerName, className }) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const error = errors[registerName];
  return (
    <div className={`${className}`}>
      <Controller
        name={registerName}
        control={control}
        render={({ field }) => (
          <>
            <CreatableSelect
              options={[]}
              placeholder=""
              onChange={newValue => {
                field.onChange(newValue);
              }}
              isMulti
              defaultValue={[]}
              onCreateOption={(inputValue: string) => {
                const existOptions: string[] = field.value || [];
                setValue(registerName, [...existOptions, inputValue]);
              }}
              onKeyDown={(event: React.KeyboardEvent<HTMLElement>) => {
                if (event.key === "Backspace" && field.value?.length > 0) {
                  // 直近で追加したアイテムを削除する
                  setValue(registerName, field.value?.slice(0, -1) || []);
                }
              }}
              components={{
                MultiValueLabel: ({ innerProps, children }: any) => (
                  <div className="text-white text-xs">{children}</div>
                ),
                MultiValueContainer: ({ innerProps, children }: any) => (
                  <div
                    {...innerProps}
                    className="bg-primary rounded h-6 mx-1 flex items-center text-white px-1"
                  >
                    {children}
                  </div>
                ),
                MultiValueRemove: ({ innerProps, data: { value } }: any) => (
                  <div
                    {...innerProps}
                    onClick={() => {
                      setValue(
                        registerName,
                        field.value?.filter((v: string) => v !== value) || []
                      );
                    }}
                    className="px-1"
                  >
                    &#x2715;
                  </div>
                ),
              }}
              value={
                field.value?.map((value: string) => ({
                  value,
                  label: value,
                })) || []
              }
            />
          </>
        )}
      />
      {error && (
        <label className="label">
          <span className="label-text-alt text-error">
            {`${error.message || ""}`}
          </span>
        </label>
      )}
    </div>
  );
};
