import React, { FC } from "react";
import { SignInInput } from "./index";
import { ButtonUI } from "../../components/Button";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { Link } from "react-router-dom";
import { InputUI } from "../../components/rhfs/Input";

type SignInPageProps = {
  onSignIn: SubmitHandler<SignInInput>;
};

export const SignInPage: FC<SignInPageProps> = ({ onSignIn }) => {
  const schema = Joi.object({
    email: Joi.string().required().messages({
      "string.empty": "メールアドレスは必須項目です",
    }),
    password: Joi.string().required().messages({
      "string.empty": "パスワードは必須項目です",
    }),
  });
  const methods = useForm<SignInInput>({
    resolver: joiResolver(schema),
  });
  return (
    <div className="m-auto py-20">
      <h1 className="text-center text-6xl mb-20 font-bold">Strix Search</h1>

      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSignIn)}
          className="mb-8 flex justify-center"
        >
          <div className="card w-full max-w-sm shadow-2xl bg-base-100">
            <div className="card-body">
              <div className="form-control">
                <InputUI
                  label="メールアドレス"
                  registerName="email"
                  name="email"
                  placeholder="メールアドレスを入力してください"
                />
              </div>
              <div className="form-control">
                <InputUI
                  label="パスワード"
                  name="password"
                  registerName="password"
                  type="password"
                  placeholder="パスワードを入力してください"
                />
                {/* <label className="label mt-1.5">
                  <p className="text-right text-xs label-text-alt">
                    パスワードをお忘れの方はこちら
                    <Link
                      to="/signup"
                      data-testid="signin-page-to-signup"
                      className="link link-primary"
                    >
                      こちら
                    </Link>
                  </p>
                </label> */}
              </div>
              <div className="form-control mt-6 mb-1.5">
                <ButtonUI
                  type="submit"
                  data-testid="signin-page-signin"
                  color="primary"
                  size="n"
                  className="w-full"
                >
                  ログイン
                </ButtonUI>
              </div>
              <p className="text-right text-xs label-text-alt">
                新規登録は
                <Link
                  to="/signup"
                  data-testid="signin-page-to-signup"
                  className="link link-primary"
                >
                  こちら
                </Link>
              </p>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
