import React, { useContext } from "react";
import { AuthContext } from "../../hooks/authProvider/authProvider";
import { HeaderUI } from "./Header";

const HeaderContaier = () => {
  const { currentUser } = useContext(AuthContext);
  return <HeaderUI currentUser={currentUser} />;
};

export default HeaderContaier;
