import React, { FC } from "react";

type PageTitleProps = {
  children: React.ReactNode;
  className?: string;
};

export const PageTitle: FC<PageTitleProps> = ({
  children,
  className = "text-3xl font-bold mb-4",
}) => <h1 className={`${className}`}>{children}</h1>;
