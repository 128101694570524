import React, { MouseEventHandler, useEffect, ChangeEventHandler } from "react";
import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { PageTitle } from "../../../components/PageTitle";
import { ButtonUI } from "../../../components/Button";
import { CreateCompanyWithEmployeeInput } from "../../../generated/graphql";
import dayjs from "dayjs";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "ホーム",
  },
  {
    label: "設定",
  },
  {
    label: "一括登録",
  },
];

type EmployeeNewConfirmPageProps = {
  createCompanyWithEmployeeInput: CreateCompanyWithEmployeeInput[];
  onUploadFile: MouseEventHandler<HTMLButtonElement>;
  onChangeFileInput: ChangeEventHandler<HTMLInputElement>;
};

const EmployeeNewConfirmPage: React.VFC<EmployeeNewConfirmPageProps> = ({
  createCompanyWithEmployeeInput,
  onUploadFile,
  onChangeFileInput,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  return (
    <div className="container mx-auto px-4">
      <PageTitle>一括登録</PageTitle>
      <input type="file" onChange={onChangeFileInput} />
      <br></br>
      <div className="flex justify-center w-96 mb-20 space-x-5">
        <ButtonUI size="xs" to="/employee/new">
          戻る
        </ButtonUI>
        <ButtonUI size="xs" onClick={onUploadFile}>
          登録
        </ButtonUI>
      </div>

      <br></br>

      <table className="table-auto mb-5">
        <thead>
          <tr>
            <th className="px-4 py-2">No.</th>
            <th className="px-4 py-2">会社名</th>
            <th className="px-4 py-2">説明</th>
            <th className="px-4 py-2">繋いだ人のID</th>
            <th className="px-4 py-2">従業員の名前</th>
            <th className="px-4 py-2">従業員のメールアドレス</th>
            <th className="px-4 py-2">登録日</th>
            <th className="px-4 py-2">有効/無効</th>
          </tr>
        </thead>
        <tbody>
          {createCompanyWithEmployeeInput.map(
            (input: CreateCompanyWithEmployeeInput, index: number) => (
              <tr key={index}>
                <td className="border px-4 py-2">{index + 1}</td>
                <td className="border px-4 py-2">{input.name}</td>
                <td className="border px-4 py-2">{input.description}</td>
                <td className="border px-4 py-2">{input.connecterId}</td>
                <td className="border px-4 py-2">{input.employeeName}</td>
                <td className="border px-4 py-2">{input.employeeEmail}</td>
                <td className="border px-4 py-2">
                  {dayjs(input.createdAt).toISOString()}
                </td>
                <td className="border px-4 py-2">
                  {input.disabled ? "無効" : "有効"}
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeNewConfirmPage;
