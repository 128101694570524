import dayjs from "dayjs";
import React, { useCallback, useState } from "react";
import { DeliverySchedulesItemFragment } from "../../../../generated/graphql";
import { DeliverySchedulesModal } from "../DeliverySchedulesModal";
import { DeliverySchedulesItemUI } from "./DeliverySchedulesItem";

export type DeliverySchedulesTableUIProps = {
  deliverySchedules: DeliverySchedulesItemFragment[];
};

export const DeliverySchedulesTableUI: React.VFC<
  DeliverySchedulesTableUIProps
> = ({ deliverySchedules }) => {
  const [selectedSchedule, setSelectedSchedule] =
    useState<DeliverySchedulesItemFragment | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleSelectSchedule = useCallback(
    (history: DeliverySchedulesItemFragment) => {
      setSelectedSchedule(history);
      setIsModalVisible(true);
    },
    []
  );

  const closeModal = useCallback(() => {
    setIsModalVisible(false);
    setSelectedSchedule(null);
  }, []);

  const sortedSchedules = [...deliverySchedules].sort(
    (a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf()
  );

  return (
    <div className="overflow-x-auto">
      <table className="divide-y divide-gray-200 text-xs w-full">
        <thead className="bg-primary">
          <tr className="text-left font-medium text-white uppercase tracking-wider">
            <th className="px-3 py-2" style={{ width: "12.92%" }}>
              配信予定日時
            </th>
            <th className="px-3 py-2" style={{ width: "12.5%" }}>
              人材Hash
            </th>
            <th className="px-3 py-2" style={{ width: "18.75%" }}>
              件名
            </th>
            <th className="px-3 py-2" style={{ width: "6.67%" }}>
              配信種別
            </th>
            <th className="px-3 py-2" style={{ width: "6.67%" }}>
              配信予約者
            </th>
            <th className="px-3 py-2" style={{ width: "12.92%" }}>
              予約登録日時
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {sortedSchedules.map(deliverySchedule => (
            <DeliverySchedulesItemUI
              key={deliverySchedule.id}
              deliverySchedule={deliverySchedule}
              onSelectSchedule={() => handleSelectSchedule(deliverySchedule)}
            />
          ))}
        </tbody>
      </table>

      {selectedSchedule && (
        <DeliverySchedulesModal
          visibility={isModalVisible}
          selectedSchedule={selectedSchedule}
          onClosed={closeModal}
        />
      )}
    </div>
  );
};
