import React, { MouseEventHandler, useEffect } from "react";
import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { FormGroupUI } from "../../../components/FormGroup";
import { FormGroupLabelUI } from "../../../components/FormGroupLabel";
import { ButtonUI } from "../../../components/Button";
import { PageTitle } from "../../../components/PageTitle";
import { LoadingUI } from "../../../components/Loading";
import { CreateEmployeeModify } from "../EmployeeNewPage";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "ホーム",
  },
  {
    label: "会社員",
  },
  {
    label: "会社員作成",
  },
  {
    label: "確認",
  },
];

type EmployeeNewConfirmPageProps = {
  isLoading: boolean;
  createEmployeeModify: CreateEmployeeModify;
  onCreate: MouseEventHandler<HTMLButtonElement>;
};

const EmployeeNewConfirmPage: React.VFC<EmployeeNewConfirmPageProps> = ({
  isLoading,
  createEmployeeModify,
  onCreate,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);
  return isLoading ? (
    <div className="w-full flex justify-center pt-20">
      <LoadingUI type="Grid" />
    </div>
  ) : (
    <div className="container mx-auto px-4">
      <PageTitle>会社員作成確認</PageTitle>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>会社員の名前</FormGroupLabelUI>
        {createEmployeeModify.name}
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>よみがな</FormGroupLabelUI>
        {createEmployeeModify.kana}
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>メールアドレス</FormGroupLabelUI>
        {createEmployeeModify.email}
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>所属会社</FormGroupLabelUI>
        {createEmployeeModify.companyName}
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>説明</FormGroupLabelUI>
        {createEmployeeModify.description}
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>有効 / 無効</FormGroupLabelUI>
        {createEmployeeModify.disabled ? "無効" : "有効"}
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>無効の理由</FormGroupLabelUI>
        {createEmployeeModify.disabledReason
          ? createEmployeeModify.disabledReason
          : "理由なし"}
      </FormGroupUI>
      <div className="flex justify-center w-96 mb-20 space-x-5">
        <ButtonUI size="xs" to="/employee/new">
          戻る
        </ButtonUI>
        <ButtonUI size="xs" onClick={onCreate}>
          登録
        </ButtonUI>
      </div>
    </div>
  );
};

export default EmployeeNewConfirmPage;
