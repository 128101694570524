import { useCallback, useState } from "react";
import {
  getSignUpStatus,
  resetSignUpStatus,
  setSignUpStatusToLocalStorage,
} from "../../localStorages/signUpStatus";

export type SignUpStatus = {
  email: string;
  confirming: boolean;
};

export const useSignUpStatus = (): [
  SignUpStatus,
  (input: SignUpStatus) => void,
  () => void
] => {
  const [signUpStatus, setSignUpStatus] = useState<SignUpStatus>(
    getSignUpStatus()
  );

  const setCustomSignUpStatus = useCallback((input: SignUpStatus) => {
    setSignUpStatusToLocalStorage(input);
    setSignUpStatus(input);
  }, []);

  const clearSignUpStatus = useCallback(() => {
    resetSignUpStatus();
    setSignUpStatus({ email: "", confirming: false });
  }, []);

  return [signUpStatus, setCustomSignUpStatus, clearSignUpStatus];
};
