import React from "react";
import { Link } from "react-router-dom";
import { SignUpInput } from "../../../../hooks/authProvider/authProvider";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { InputUI } from "../../../../components/rhfs/Input";
import { FormGroupLabelUI } from "../../../../components/FormGroupLabel";
import { ButtonUI } from "../../../../components/Button";

type SignUpInputUIProps = {
  onSignUp: SubmitHandler<SignUpInput>;
};

export const SignUpInputUI: React.VFC<SignUpInputUIProps> = ({ onSignUp }) => {
  const schema = Joi.object({
    familyName: Joi.string().required().messages({
      "string.empty": "姓は必須項目です",
    }),
    givenName: Joi.string().required().messages({
      "string.empty": "名は必須項目です",
    }),
    familyNameKana: Joi.string().allow(""),
    givenNameKana: Joi.string().allow(""),
    email: Joi.string().required().messages({
      "string.empty": "メールアドレスは必須項目です",
    }),
    password: Joi.string().required().messages({
      "string.empty": "パスワードは必須項目です",
    }),
  });
  const methods = useForm<SignUpInput>({
    resolver: joiResolver(schema),
  });
  return (
    <div className="w-5/6 mx-auto">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSignUp)}>
          <FormGroupLabelUI>氏名</FormGroupLabelUI>
          <div className="flex space-x-8 mb-6">
            <InputUI registerName="familyName" placeholder="氏" />
            <InputUI registerName="givenName" placeholder="名" />
          </div>
          <FormGroupLabelUI>ふりがな</FormGroupLabelUI>
          <div className="flex space-x-8 mb-6">
            <InputUI registerName="familyNameKana" placeholder="せい" />
            <InputUI registerName="givenNameKana" placeholder="めい" />
          </div>
          <FormGroupLabelUI>メールアドレス</FormGroupLabelUI>
          <InputUI
            registerName="email"
            placeholder="メールアドレス"
            className="mb-6 w-full"
          />
          <FormGroupLabelUI>パスワード</FormGroupLabelUI>
          <InputUI
            registerName="password"
            type="password"
            placeholder="パスワード"
          />
          <span className="text-nano">
            大文字、小文字、数字、記号を含めた8文字以上の英数字
          </span>
          <div className="flex justify-center mt-12">
            <ButtonUI
              size="lg"
              type="submit"
              color="primary"
              className="w-full"
              data-testid="sign-up-input-signup"
              value="新規登録"
            >
              新規登録
            </ButtonUI>
          </div>
        </form>
      </FormProvider>
      <p className="text-right mt-5">
        ログインは
        <Link
          to="/login"
          data-testid="sign-up-input-to-signin"
          className="text-blue-400 cursor-pointer hover:opacity-80"
        >
          こちら
        </Link>
      </p>
    </div>
  );
};
