import React, { useState, useEffect } from "react";
import { HomePage } from "./HomePage";
import { useHomePageQuery } from "../../generated/graphql";
import { LoadingUI } from "../../components/Loading";
import { isAllowDevelopUse } from "../../utils/authorityUtils";

const HomePageContainer: React.VFC = () => {
  const [allowDevelopUse, setAllowDevelopUse] = useState(false);
  const { loading, error, data } = useHomePageQuery();
  useEffect(() => {
    if (data?.me) {
      setAllowDevelopUse(isAllowDevelopUse(data.me.organizationId));
    }
  }, [data]);

  if (error) {
    return <div>{JSON.stringify(error, null, 2)}</div>;
  }
  if (loading || !data) {
    return (
      <div className="flex justify-center mt-52">
        <LoadingUI type="Grid" />
      </div>
    );
  }
  return <HomePage allowDevelopUse={allowDevelopUse} />;
};

export default HomePageContainer;
