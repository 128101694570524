import React, { InputHTMLAttributes, useCallback, FC } from "react";
import { useFormContext } from "react-hook-form";

export type InputGroupUIProps = {
  registerName: `${string}` | `${string}.${string}` | `${string}.${number}`;
  label: string;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  color?:
    | "primary"
    | "secondary"
    | "info"
    | "accent"
    | "success"
    | "warning"
    | "error";
  placeholder?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const InputGroupUI: FC<InputGroupUIProps> = ({
  className = "",
  registerName,
  children,
  label,
  size = "md",
  color = "primary",
  placeholder = "",
  ...rest
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = errors[registerName];
  const getColor = useCallback(
    () => (error ? "input-error" : `input-${color}`),
    [error, color]
  );
  const getSize = useCallback(() => `input-${size}`, [size]);
  return (
    <div className="flex flex-col">
      <div className="form-control">
        <label className="label">
          <span className="label-text">{label}</span>
        </label>
        <label className="input-group">
          <span>{label}</span>
          <input
            type="text"
            placeholder={placeholder}
            className={`${className} input input-bordered ${getSize()} ${getColor()}`}
            {...register(registerName)}
            {...rest}
          />
        </label>
      </div>
      {error && (
        <label className="label">
          <span className="label-text-alt text-error">{`${
            error.message || ""
          }`}</span>
        </label>
      )}
    </div>
  );
};
