import React from "react";
import { EmployeesItemUI } from "./EmployeesItem";

export type EmployeesTableUIProps = {
  employees: any[];
  selectedEmployee: any;
  onSelectEmployee: (id: string) => void;
};

export const EmployeesTableUI: React.VFC<EmployeesTableUIProps> = ({
  employees,
  selectedEmployee,
  onSelectEmployee,
}) => {
  return (
    <table className={`divide-y divide-gray-200 text-xs  w-full`}>
      <thead className="bg-primary">
        <tr className="py-3 text-left font-medium text-white uppercase tracking-wider">
          <th scope="col" className="px-3">
            名前
          </th>
          <th scope="col" className="px-3">
            メールアドレス
          </th>
          <th scope="col" className="px-3">
            会社ID
          </th>
          <th scope="col" className="px-3">
            説明
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {employees.map((employee: any) => (
          <EmployeesItemUI
            key={employee.id}
            selected={selectedEmployee && selectedEmployee.id === employee.id}
            employee={employee}
            onSelectEmployee={onSelectEmployee}
          />
        ))}
      </tbody>
    </table>
  );
};
