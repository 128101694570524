import Amplify from "aws-amplify";
import awsconfig from "../aws-exports";

/**
 * amplify不具合解消のpatch
 * @param {*} awsmobile
 */
function patch2792(awsmobile: typeof awsconfig) {
  // See: https://github.com/aws-amplify/amplify-cli/issues/2792
  const host = window.location.host;
  if (awsmobile.oauth.redirectSignIn.includes(",")) {
    const filterHost = (url: string) => new URL(url).host === host;
    awsmobile.oauth.redirectSignIn = awsmobile.oauth.redirectSignIn
      .split(",")
      .filter(filterHost)
      .shift()!;
    awsmobile.oauth.redirectSignOut = awsmobile.oauth.redirectSignOut
      .split(",")
      .filter(filterHost)
      .shift()!;
  }
}

patch2792(awsconfig);

Amplify.configure(awsconfig);
