import React from "react";
import type { HTMLAttributes, FC } from "react";
import { LocationDescriptor } from "history";

export type ContentBoxUIProps = {
  title?: string;
  to?: LocationDescriptor;
} & HTMLAttributes<HTMLDivElement>;

export const ContentBoxUI: FC<ContentBoxUIProps> = ({
  title = "",
  children,
  className,
  ...rest
}) => {
  return (
    <div className={`card w-96 bg-base-100 shadow-xl ${className}`} {...rest}>
      <div className="card-body">
        {title && <h2 className="card-title">{title}</h2>}
        {children}
      </div>
    </div>
  );
};
