import React, { TextareaHTMLAttributes, FC } from "react";
import { FieldError, UseFormRegisterReturn } from "react-hook-form";

export type ValidatableTextAreaUIProps = {
  register: UseFormRegisterReturn;
  error?: FieldError;
  rows?: number;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

export const ValidatableTextAreaUI: FC<ValidatableTextAreaUIProps> = ({
  className = "",
  register,
  error,
  rows = 4,
  children,
  ...rest
}) => {
  return (
    <div className="flex flex-col">
      <textarea
        className={`${className} h-10 leading-5 pl-4 tracking-wider border rounded`}
        {...register}
        {...rest}
        rows={rows}
      />
      {error && <span className="text-error text-xs">{error.message}</span>}
    </div>
  );
};
