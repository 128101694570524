import { yupResolver } from "@hookform/resolvers/yup";
import React, { FC, useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { PageTitle } from "../../../components/PageTitle";
import { genMessageTagHash } from "../../../utils/hashUtils";
import {
  FormValues,
  RadioOptionValue,
  SendBulkEmailsForm,
} from "./SendBulkEmailsForm";
import dayjs from "dayjs";

const sendBulkEmailsSchema = yup.object().shape({
  category: yup.string().required("配信種別は必須です"),
  from: yup.string().required("配信者は必須です"),
  subject: yup.string().required("件名は必須です"),
  content: yup.string().required("本文は必須です"),
  scheduledAt: yup
    .string()
    .test("noWeekend", "土日を指定することはできません。", value => {
      if (!value || !dayjs(value).isValid()) return true; // 無効な日付はスキップ
      const day = dayjs(value).day(); // 0: 日曜日, 6: 土曜日
      return day !== 0 && day !== 6;
    })
    .test("withinBusinessHours", "予約可能時間は8:00〜20:00です。", value => {
      if (!value || !dayjs(value).isValid()) return true; // 無効な日付はスキップ
      const hour = dayjs(value).hour();
      return hour >= 8 && hour <= 20;
    }),
});

const BREAD_CRUMBS: Breadcrumb[] = [
  { label: "ホーム" },
  { label: "メール一斉配信" },
];

export const SendBulkEmailsPage: FC = () => {
  const { setBreadcrumbItems } = useBreadcrumb();
  const history = useHistory();
  const location = useLocation<{ state: FormValues }>();
  const initialFormValues = location.state?.state;

  const methods = useForm<FormValues>({
    defaultValues: initialFormValues || {
      category: RadioOptionValue.HR,
      from: "",
      subject: "",
      content: "",
      hrHash: "",
      scheduled: false,
      scheduledAt: "",
    },
    resolver: yupResolver(sendBulkEmailsSchema),
  });

  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  const handleFormSubmit = useCallback(
    (emailsInput: FormValues) => {
      history.push("/emails/bulk/confirm", {
        state: { ...emailsInput },
      });
    },
    [history]
  );

  const setGeneratedHash = useCallback(async () => {
    try {
      const hash = await genMessageTagHash();
      methods.setValue("hrHash", hash);
    } catch (error) {
      console.error("Error generating hash:", error);
    }
  }, [methods]);

  useEffect(() => {
    if (!methods.getValues().hrHash) {
      setGeneratedHash();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!methods.watch("scheduled")) {
      methods.setValue("scheduledAt", "");
    }
  }, [methods.watch("scheduled"), methods.setValue]);

  return (
    <div className="container mx-auto px-4">
      <PageTitle>メール一斉配信</PageTitle>
      <FormProvider {...methods}>
        <div className="w-3/4">
          <SendBulkEmailsForm
            hrHash={methods.watch("hrHash")}
            scheduled={methods.watch("scheduled")}
            onGenerateHash={setGeneratedHash}
          />
          <div className="flex justify-end mt-2">
            <ButtonUI
              className="w-40 mb-10"
              type="submit"
              onClick={methods.handleSubmit(handleFormSubmit)}
            >
              確認画面へ
            </ButtonUI>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};
