import React, { useState, useCallback, useEffect } from "react";
import type { FC, FormEventHandler, MouseEventHandler } from "react";
import CompaniesPage from "./CompaniesPage";

import {
  CompanySearchInput,
  useCompaniesPageQuery,
  CompaniesFragment,
  CompaniesItemFragment,
  CreateCompanyInput,
  SelectItemUserFragment,
  useUpdateCompanyMutation,
  UpdateCompanyInput,
  useDeleteCompanyMutation,
} from "../../../generated/graphql";
import { ChangeCompanySearchInput } from "./components/SearchCompaniesForm";
import { CompanyDetailModal } from "./components/CompanyDetailModal";
import { OptionItem } from "../../../types/common";
import { toInteger } from "../../../utils/textUtils";

const CompaniesPageContainer: FC = () => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectItemUsers, setSelectItemUsers] = useState<OptionItem[]>([]);
  const [searchInput, setSearchInput] = useState<CompanySearchInput>();
  const { error, data, refetch } = useCompaniesPageQuery({
    onCompleted: async () => {
      // do nothings
    },
    notifyOnNetworkStatusChange: true,
  });
  const [selectedCompany, setSelectedCompany] =
    useState<CompaniesItemFragment>();

  const [companies, setCompanies] = useState<CompaniesFragment>({
    totalCount: 0,
    nodes: [],
  });
  const [updateCompany, { error: errorMutation }] = useUpdateCompanyMutation({
    onCompleted: async () => {
      await refetch({
        filter: searchInput,
        offset: 0,
        limit: 50,
      });
      setIsLoading(false);
    },
  });

  const [deleteCompany] = useDeleteCompanyMutation({
    onCompleted: async () => {
      await refetch({
        filter: searchInput,
        offset: 0,
        limit: 50,
      });
      setIsLoading(false);
    },
  });

  useEffect(() => {
    let isMounted = true;
    if (isMounted && data) {
      setCompanies(data.companies);
      setSelectItemUsers(
        data.users.nodes.map((user: SelectItemUserFragment) => ({
          value: user.id,
          label: `${user.familyName} ${user.givenName}`,
        }))
      );
    }
    return () => {
      isMounted = false;
    };
  }, [data]);
  const onSelectCompany = useCallback(
    (id: string) => {
      const company: CompaniesItemFragment = companies.nodes.find(
        (company: CompaniesItemFragment) => company.id === id
      )!;
      setSelectedCompany(company);
      setModalVisibility(true);
    },
    [companies.nodes]
  );
  const onChangeSearchInput: ChangeCompanySearchInput = useCallback(
    (newSearchInput: CompanySearchInput) => {
      setSearchInput(newSearchInput);
    },
    []
  );
  const onSearch: FormEventHandler = useCallback(
    async event => {
      event.preventDefault();
      setIsLoading(true);
      await refetch({
        filter: searchInput,
        offset: 0,
        limit: 50,
      });
    },
    [searchInput, refetch]
  );

  const onClearFilter: MouseEventHandler = useCallback(
    async event => {
      event.preventDefault();
      setSearchInput({
        ...searchInput,
      });
    },
    [searchInput]
  );

  const onClosedModal: MouseEventHandler = useCallback(async event => {
    event.preventDefault();
    setModalVisibility(false);
  }, []);

  const onUpdateCompany = useCallback(
    async (validatedInput: CreateCompanyInput) => {
      if (!selectedCompany?.id) {
        return;
      }
      setModalVisibility(false);
      setIsLoading(true);
      const input: UpdateCompanyInput = {
        ...validatedInput,
        id: selectedCompany!.id,
        yearOfEstablishment: toInteger(validatedInput.yearOfEstablishment!),
        capital: toInteger(validatedInput.capital!),
        numberOfEmployees: toInteger(validatedInput.numberOfEmployees!),
        numberOfEngineers: toInteger(validatedInput.numberOfEngineers!),
        numberOfFreelancers: toInteger(validatedInput.numberOfFreelancers!),
        numberOfBusinessPartners: toInteger(
          validatedInput.numberOfBusinessPartners!
        ),
        numberOfBPEngineers: toInteger(validatedInput.numberOfBPEngineers!),
        numberOfSales: toInteger(validatedInput.numberOfSales!),
      };
      await updateCompany({
        variables: {
          input,
        },
      });
    },
    [selectedCompany, updateCompany]
  );

  const onDeleteCompany = useCallback(
    async (id: string) => {
      setModalVisibility(false);
      setIsLoading(true);
      await deleteCompany({
        variables: {
          id,
        },
      });
    },
    [deleteCompany]
  );

  if (error) {
    return <div>{JSON.stringify(error, null, 2)}</div>;
  }
  if (errorMutation) {
    return <div>{JSON.stringify(errorMutation, null, 2)}</div>;
  }
  return (
    <>
      <CompaniesPage
        isLoading={isLoading}
        searchInput={searchInput!}
        selectedCompany={selectedCompany!}
        companies={companies}
        onSelectCompany={onSelectCompany}
        onChangeSearchInput={onChangeSearchInput}
        onSearch={onSearch}
        onClearFilter={onClearFilter}
      />
      <CompanyDetailModal
        visibility={modalVisibility}
        selectedItemUsers={selectItemUsers}
        selectedCompany={selectedCompany}
        onClosed={onClosedModal}
        onUpdateCompany={onUpdateCompany}
        onDeleteCompany={onDeleteCompany}
      />
    </>
  );
};

export default CompaniesPageContainer;
