import React, { useCallback } from "react";
import type { FC, MouseEventHandler } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { ValidatableInputUI } from "../ValidatableInput";
import { FormGroupUI } from "../FormGroup";
import { FormGroupLabelUI } from "../FormGroupLabel";
import { ButtonUI } from "../Button";
import { ValidatableCustomSelectUI } from "../ValidatableCustomSelect";
import { ValidatableTextAreaUI } from "../ValidatableTextArea";
import { ValidatableCheckboxUI } from "../ValidatableCheckbox";
import { OptionItem } from "../../types/common";
import { LoadingUI } from "../Loading";
import {
  CreateDeliveryGroupModify,
  CreateDeliveryEmployeeModify,
} from "../../routes/deliveryGroups/DeliveryGroupNewPage";
import { UpdateDeliveryGroupModify } from "../../routes/deliveryGroups/DeliveryGroupsPage";

type DeliveryGroupEditUIProps = {
  isNew: boolean;
  isLoading?: boolean;
  selectedDeliveryGroup?: UpdateDeliveryGroupModify;
  selectItemUsers: OptionItem[];
  selectItemEmployees: OptionItem[];
  onCreate?: (deliveryGroupModify: CreateDeliveryGroupModify) => void;
  onUpdate?: (deliveryGroupModify: UpdateDeliveryGroupModify) => void;
  onDelete?: (id: string) => void;
  onAddedToEmployee?: MouseEventHandler;
};

export const DeliveryGroupEditUI: FC<DeliveryGroupEditUIProps> = ({
  isNew,
  isLoading = false,
  selectedDeliveryGroup,
  selectItemUsers,
  selectItemEmployees,
  onCreate,
  onUpdate,
  onDelete,
}) => {
  const formOptions = {};
  if (!isNew) {
    console.log(JSON.stringify(selectedDeliveryGroup, null, 2));
    Object.assign(formOptions, { defaultValues: selectedDeliveryGroup });
  } else {
    Object.assign(formOptions, {
      defaultValues: {
        deliveryEmployeesModify: [
          {
            name: "",
            email: "",
            employeeId: undefined,
            disableReason: "",
            delivered: true,
            disabled: false,
          },
        ],
      },
    });
  }
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<CreateDeliveryGroupModify | UpdateDeliveryGroupModify>(
    formOptions
  );
  const { fields, append, remove } = useFieldArray({
    control,
    name: "deliveryEmployeesModify",
  });

  const onClickAddBtn = () => {
    append({
      id: "",
      name: "",
      email: "",
      employeeId: "",
      disableReason: "",
      delivered: true,
      disabled: false,
    });
  };
  const onSubmit = useCallback(
    (
      deliveryGroupModify: CreateDeliveryGroupModify | UpdateDeliveryGroupModify
    ) => {
      isNew
        ? onCreate!(deliveryGroupModify as CreateDeliveryGroupModify)
        : onUpdate!(deliveryGroupModify as UpdateDeliveryGroupModify);
    },
    [isNew, onCreate, onUpdate]
  );
  const deliveryEmployeesWatch = watch("deliveryEmployeesModify");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>配信グループ名</FormGroupLabelUI>
        <ValidatableInputUI
          register={register("name", {
            required: "配信グループ名は入力必須項目です。",
          })}
          placeholder="配信グループ名を入力してください"
          error={errors.name}
          className="w-96"
        />
      </FormGroupUI>
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>差出人</FormGroupLabelUI>
        {isLoading && <LoadingUI type="Grid" width={20} height={20} />}
        <div className="w-80 mb-10">
          <ValidatableCustomSelectUI
            registerName="fromUserId"
            className="w-80"
            disabled={isLoading}
            rules={{
              validate: {
                myRequired: (v: string | undefined) => {
                  return v !== undefined || "必須項目です";
                },
              },
            }}
            defaultValue={
              selectItemUsers.find(
                (user: OptionItem) =>
                  user.value === selectedDeliveryGroup?.fromUserId
              )?.label
            }
            error={errors.fromUserId}
            options={selectItemUsers}
            id="hr-ageRange-from"
            control={control}
          />
        </div>
      </FormGroupUI>
      <hr />
      <FormGroupUI className="mb-5">
        <FormGroupLabelUI>宛先</FormGroupLabelUI>
        {/* {isLoading && <LoadingUI type="Grid" width={20} height={20} />} */}
        <div className="w-7/12 mb-10">
          <div className="flex flex-col">
            <div className="flex justify-end my-3">
              <ButtonUI size="xs" type="button" onClick={onClickAddBtn}>
                追加
              </ButtonUI>
            </div>

            <div className="flex flex-row space-x-4">
              <span className="w-2/12">配信する</span>
              <span className="w-8/12">宛先</span>
              <span className="w-2/12">配信不可</span>
            </div>
            {fields.map(
              (field: CreateDeliveryEmployeeModify, index: number) => (
                <div
                  key={field.id}
                  className="flex flex-row justify-items-center justify-around mt-5"
                >
                  <ValidatableCheckboxUI
                    key={`deliveryEmployeesModify.${index}.delivered`}
                    control={control}
                    registerName={`deliveryEmployeesModify.${index}.delivered`}
                    className={"mt-3"}
                    rules={{}}
                    defaultChecked={
                      selectedDeliveryGroup?.deliveryEmployeesModify.find(
                        (employee: CreateDeliveryEmployeeModify) =>
                          employee.employeeId === field.employeeId
                      )?.delivered || false
                    }
                  />
                  <ValidatableCustomSelectUI
                    key={field.id}
                    registerName={`deliveryEmployeesModify.${index}.employeeId`}
                    className="w-8/12"
                    disabled={isLoading}
                    error={errors.deliveryEmployeesModify?.[index]?.employeeId}
                    rules={{
                      validate: {
                        myRequired: (v: string | undefined) =>
                          v !== undefined || "必須項目です",
                      },
                    }}
                    defaultValue={
                      selectItemEmployees.find(
                        (employee: OptionItem) =>
                          employee.value === field.employeeId
                      )?.label
                    }
                    options={selectItemEmployees.filter((item: OptionItem) =>
                      deliveryEmployeesWatch.every(
                        (employee: CreateDeliveryEmployeeModify) =>
                          employee.employeeId !== item.value
                      )
                    )}
                    id="hr-ageRange-from2"
                    control={control}
                  />
                  <ValidatableCheckboxUI
                    key={`deliveryEmployeesModify.${index}.disabled`}
                    control={control}
                    registerName={`deliveryEmployeesModify.${index}.disabled`}
                    className={"mt-3"}
                    rules={{}}
                    defaultChecked={
                      selectedDeliveryGroup?.deliveryEmployeesModify.find(
                        (employee: CreateDeliveryEmployeeModify) =>
                          employee.employeeId === field.employeeId
                      )?.disabled || false
                    }
                  />
                  <button
                    onClick={event => {
                      event.preventDefault();
                      remove(index);
                    }}
                  >
                    削除
                  </button>
                </div>
              )
            )}
          </div>
        </div>
      </FormGroupUI>
      <hr />
      <FormGroupUI className="my-5">
        <FormGroupLabelUI>説明</FormGroupLabelUI>
        <ValidatableTextAreaUI
          register={register("description", {})}
          placeholder="説明を入力してください"
          error={errors.description}
          rows={8}
          className="w-96 h-48"
        />
      </FormGroupUI>

      <div className="flex justify-center w-96 mb-20 space-x-5">
        {isNew && (
          <ButtonUI size="xs" to="/deliveryGroups">
            戻る
          </ButtonUI>
        )}
        <ButtonUI size="xs" type="submit">
          {isNew ? "登録" : "更新"}
        </ButtonUI>
        {!isNew && (
          <ButtonUI
            size="xs"
            color="secondary"
            type="button"
            onClick={() => {
              onDelete && onDelete(selectedDeliveryGroup!.id);
            }}
          >
            削除
          </ButtonUI>
        )}
      </div>
    </form>
  );
};
