import { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";
import React, { FC, useCallback } from "react";
import { EmailWithFavorite, SelectContextMenuHandler } from "../..";
import { BadgeUI } from "../../../../../components/Badge";
import { EmailReactTable } from "../../../../../components/EmailReactTable";
import { HYPHEN } from "../../../../../constants/searchTable";
import { formatToManYen, omitText } from "../../../../../utils/textUtils";
import { FreeWordHighlighter } from "../FreeWordHighlighter";
import { ReactComponent as StarIcon } from "../star.svg";

export type HREmailsTableUIProps = {
  emails: EmailWithFavorite[];
  onSelectEmail: (id: string) => void;
  onToggleFavoriteEmail: (emailId: string, favorite: boolean) => void;
  onSelectContextMenu: SelectContextMenuHandler;
  freeWords: string[];
};

export type HRRow = {
  emailId: string;
  star: JSX.Element;
  from: JSX.Element;
  dateTime: string;
  age: string;
  gender: string;
  station: JSX.Element;
  hrTypes: string;
  startedDate: JSX.Element;
  reward: string;
  isRemoteNeeded: JSX.Element;
};

export const HREmailsTableUI: FC<HREmailsTableUIProps> = ({
  emails,
  onSelectEmail,
  onSelectContextMenu,
  onToggleFavoriteEmail,
  freeWords,
}) => {
  const columns: ColumnDef<HRRow, any>[] = [
    {
      accessorKey: "emailId",
      header: "emailId",
    },
    {
      accessorKey: "star",
      header: "☆",
      cell: props => props.getValue(),
    },
    {
      accessorKey: "from",
      header: "From",
      cell: props => props.getValue(),
    },
    {
      accessorKey: "dateTime",
      header: "日付",
    },
    {
      accessorKey: "age",
      header: "年齢",
    },
    {
      accessorKey: "gender",
      header: "性別",
    },
    {
      accessorKey: "station",
      header: "最寄駅",
      cell: props => props.getValue(),
    },
    {
      accessorKey: "hrTypes",
      header: "人材種別",
    },
    {
      accessorKey: "startedDate",
      header: "稼働時期",
      cell: props => props.getValue(),
    },
    {
      accessorKey: "reward",
      header: "希望金額",
    },
    {
      accessorKey: "isRemoteNeeded",
      header: "リモート希望",
      cell: props => props.getValue(),
    },
  ];

  const data: HRRow[] = emails.map((email: EmailWithFavorite) => ({
    emailId: email.id,
    // お気に入りボタン
    star: (
      <StarIcon
        className={`${
          email.favorite ? "text-yellow-300" : "text-gray-200"
        } w-5 h-5`}
        onClick={e => {
          onToggleFavoriteEmail(email.id, !email.favorite);
          e.stopPropagation();
        }}
      />
    ),
    // 差し出し元
    from: (
      <div className="flex flex-col text-nano">
        <span>{omitText(email.from.name, 12)}</span>
        <span>{omitText(email.from.address, 28)}</span>
      </div>
    ),
    // 日付
    dateTime:
      dayjs().diff(email.date, "day") === 0
        ? dayjs(email.date).format("HH:mm")
        : dayjs(email.date).format("MM/DD HH:mm"),
    // 年齢
    age: omitText(email?.hrColumn?.age),
    // 性別
    gender: omitText(email?.hrColumn?.gender),
    // 現場駅
    station: (
      <FreeWordHighlighter
        type="Table"
        freeWords={freeWords}
        textToHighlight={omitText(email?.hrColumn?.station)}
      />
    ),
    // 人材種別
    hrTypes: omitText(email?.hrColumn?.hrTypes),

    // 稼働日
    startedDate: (
      <FreeWordHighlighter
        type="Table"
        freeWords={freeWords}
        textToHighlight={omitText(email?.hrColumn?.startedDate)}
      />
    ),
    // 希望金額
    reward: omitText(email?.hrColumn?.reward),
    // リモート
    isRemoteNeeded: email?.hrColumn?.isRemoteNeeded ? (
      <BadgeUI color="success" className="text-nano" isOutline={true}>
        ◯
      </BadgeUI>
    ) : (
      <BadgeUI color="error" className="text-nano" isOutline={true}>
        ✕
      </BadgeUI>
    ),
  }));

  const genderText = useCallback((gender: string | null) => {
    if (!gender) {
      return HYPHEN;
    }
    if (gender === "male") {
      return "男性";
    } else if (gender === "female") {
      return "女性";
    }
    return HYPHEN;
  }, []);

  const ageText = useCallback((age: string | null) => {
    if (!age) {
      return HYPHEN;
    }
    if (isNaN(Number(age))) {
      return HYPHEN;
    }
    return `${age}歳`;
  }, []);

  const hrTypesText = useCallback((hrType: string | null) => {
    switch (hrType) {
      case "fullTimeEmployee":
        return "正社員";
      case "contractEmployee":
        return "契約社員";
      case "freelancer":
        return "フリーランス";
      default:
        return HYPHEN;
    }
  }, []);

  const newData: HRRow[] = emails.map((email: EmailWithFavorite) => ({
    emailId: email.id,
    // お気に入りボタン
    star: (
      <StarIcon
        className={`${
          email.favorite ? "text-yellow-300" : "text-gray-200"
        } w-5 h-5`}
        onClick={e => {
          onToggleFavoriteEmail(email.id, !email.favorite);
          e.stopPropagation();
        }}
      />
    ),
    // 差し出し元
    from: (
      <div className="flex flex-col text-nano">
        <span>{omitText(email.from.name, 12)}</span>
        <span>{omitText(email.from.address, 28)}</span>
      </div>
    ),
    // 日付
    dateTime:
      dayjs().diff(email.date, "day") === 0
        ? dayjs(email.date).format("HH:mm")
        : dayjs(email.date).format("MM/DD HH:mm"),
    // 年齢
    age: ageText(email?.hrColumn?.age || null),
    // 性別
    gender: genderText(email?.hrColumn?.gender || null),
    // 現場駅
    station: (
      <FreeWordHighlighter
        type="Table"
        freeWords={freeWords}
        textToHighlight={omitText(email?.hrColumn?.station)}
      />
    ),
    // 人材種別
    hrTypes: hrTypesText(email?.hrColumn?.hrTypes || null),
    // 稼働日
    startedDate: (
      <FreeWordHighlighter
        type="Table"
        freeWords={freeWords}
        textToHighlight={omitText(email?.hrColumn?.startedDate)}
      />
    ),
    // 希望金額
    reward: formatToManYen(email?.hrColumn?.reward),
    // リモート
    isRemoteNeeded: email?.hrColumn?.isRemoteNeeded ? (
      <BadgeUI color="success" className="text-nano" isOutline={true}>
        ◯
      </BadgeUI>
    ) : (
      <BadgeUI color="error" className="text-nano" isOutline={true}>
        ✕
      </BadgeUI>
    ),
  }));
  return (
    <EmailReactTable
      data={process.env.REACT_APP_ENV === "prd" ? data : newData}
      columns={columns}
      onSelectEmail={onSelectEmail}
      onSelectContextMenu={onSelectContextMenu}
    />
  );
};
