export type EmailSender = {
  email: string;
  name: string;
};

export const EMAIL_SENDERS: EmailSender[] = [
  { email: "mitsuhiro.osawa@techreis.co.jp", name: "大澤 光広" },
  { email: "maria.iwasaki@techreis.co.jp", name: "岩崎 まりあ" },
  { email: "yuta.shimogishi@techreis.co.jp", name: "下岸 祐太" },
  { email: "takuya.tanaka.2323@gmail.com", name: "田中 拓哉" },
];

export const emailToNameMap = EMAIL_SENDERS.reduce((acc, sender) => {
  acc[sender.email] = sender.name;
  return acc;
}, {} as Record<string, string>);

export enum FromOptionValue {
  Osawa = "mitsuhiro.osawa@techreis.co.jp",
  Iwasaki = "maria.iwasaki@techreis.co.jp",
  Shimogishi = "yuta.shimogishi@techreis.co.jp",
  Tanaka = "takuya.tanaka.2323@gmail.com",
}

export const fromOptions = EMAIL_SENDERS.map(sender => ({
  label: sender.name,
  value: sender.email,
}));
