import dayjs from "dayjs";
import React, { FC, InputHTMLAttributes, useCallback } from "react";
import { useFormContext } from "react-hook-form";

export type ValidatableDateTimeUIProps = {
  registerName: string;
  rules: object;
} & InputHTMLAttributes<HTMLInputElement>;

// TODO:予約配信のための暫定実装
// 汎用関数になりきてれいないので、適宜パラメータは切り出す
export const ValidatableDateTimeUI: FC<ValidatableDateTimeUIProps> = ({
  registerName,
  rules,
}) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const error = errors[registerName];
  // 現在時刻を取得
  const now = dayjs();
  // 2週間後を取得
  const twoWeeksLater = now.add(2, "week");
  const roundToNearest30Minutes = useCallback((dateTimeString: string) => {
    const dateTime = dayjs(dateTimeString);
    const minutes = dateTime.minute();
    // 30分単位に丸める
    const roundedMinutes = Math.round(minutes / 30) * 30;
    return dateTime.minute(roundedMinutes).second(0).format("YYYY-MM-DDTHH:mm");
  }, []);

  return (
    <div className="flex flex-col">
      <input
        type="datetime-local"
        min={now.format("YYYY-MM-DDTHH:mm")}
        max={twoWeeksLater.format("YYYY-MM-DDTHH:mm")}
        {...register(registerName, rules)}
        onChange={event => {
          const roundedValue = roundToNearest30Minutes(event.target.value);
          setValue(registerName, roundedValue);
        }}
      />
      {error && (
        <span className="text-error text-xs">
          <>{error.message || ""}</>
        </span>
      )}
    </div>
  );
};
