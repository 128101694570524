import React, { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Breadcrumb, useBreadcrumb } from "../../components/Breadcrumb";
import { ButtonUI } from "../../components/Button";

type HomePageProps = {
  allowDevelopUse: boolean;
};

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "ホーム",
  },
];

type TopPageButton = {
  to: string;
  label: string;
};

export const HomePage: FC<HomePageProps> = ({ allowDevelopUse }) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  const history = useHistory();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  const topPageButtons: TopPageButton[] = [
    ...(allowDevelopUse
      ? [
          {
            to: "/emails/bulk",
            label: "一斉配信",
          },
          {
            to: "/companies",
            label: "パートナー管理",
          },
          {
            to: "/engineers",
            label: "エンジニア管理",
          },
        ]
      : []),
    {
      to: "/emails/search",
      label: "メール検索",
    },
  ];
  return (
    <div className="w-4/12 container mx-auto flex flex-col">
      <img
        className="mt-16 w-[200rem] h-auto"
        src={`${process.env.PUBLIC_URL}/images/top_image3.png`}
        width="1036"
        height="440"
        alt="strix top image"
      />
      <div className="flex flex-row space-x-3 justify-around mt-28">
        {topPageButtons.map((button, index) => (
          <ButtonUI
            key={index}
            type="button"
            color="primary"
            className="max-w-36 w-1/4"
            onClick={() => {
              history.push(button.to);
            }}
          >
            {button.label}
          </ButtonUI>
        ))}
      </div>
    </div>
  );
};
